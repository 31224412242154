import { colors } from "./colors";
import { createGlobalStyle } from "styled-components";
import humanist777Woff from "../assets/fonts/humanist_777-webfont.woff";

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'humanist-777';
    src: url(${humanist777Woff}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  &::-webkit-scrollbar {
        width: 7px;
        position: absolute;
        border-radius: 80px;
        height: 7px;
      }

  &::-webkit-scrollbar-thumb {
    background: ${colors.primary};
    border-radius: 50px;
  }

  .debug {
    border: 2px solid green;
  }

  *{
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 62.5%;
    box-sizing: border-box;
  }

  body {
    background-color: ${colors.gray300};
    color: ${colors.darker};
    font-family: 'humanist-777';
    font-weight: 400;
    width: 100%;
    height: 100vh;
    font-size: 1.6rem;
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }
  
  .p_selectmenu {
    font-size: 16px;
    margin: 0px 0px 6px 0px;
    color: #8b8b8b;
  }

  .titulo-cadastro {
    margin-top: 20px;
  }
  
  strong {
    font-size: larger;
    font-weight: 900;
  }

  ol, ul {
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  input {
      outline: none;
      margin: 0;
      padding: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  button {
      margin: 0;
      padding: 0;
  }
  .rs-btn-toggle {
    background: ${colors.gray200};
  }
  .rs-btn-toggle-checked, .rs-btn-toggle:hover {
    background-color: ${colors.primary};
  }
  .rs-radio-checked .rs-radio-wrapper .rs-radio-inner::before {
    border-color: ${colors.primary};
    background-color: ${colors.primary};
  }

  .rs-radio-wrapper .rs-radio-inner::before{
    background: ${colors.gray200};
  }
  .rs-radio-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus,
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover {
    border-color: ${colors.primary} !important;
    outline: 0 !important;
  }

  .MuiModal-root {
    overflow: auto;
  }

  .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
  .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
  .rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
  .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
    border-color: ${colors.primary} !important;
  }
  
  .rs-picker-default .rs-btn, .rs-picker-input .rs-btn, .rs-picker-default .rs-picker-toggle, .rs-picker-input .rs-picker-toggle {
    background-color: #ececec !important;
    height: 40px;

    span {
      font-size: 16px;
      color: rgb(117, 117, 117) !important;
    }
  }

  .rs-picker-menu .rs-picker-search-bar::after {
    content: ''
  }

  textarea {
    padding: 30px 0;
    border: none;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none; /*remove the resize handle on the bottom right*/
  }

  .rs-picker-select-menu-item:not(.rs-picker-select-menu-item-disabled):hover,
  .rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
    color: #575757;
    background-color: #55038f23;
    text-decoration: none;
  }

  ::selection {
    color: white;
    background: #60159a;
  }

  .rs-picker-menu {
    z-index: 1300;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  .link-primary {
    text-decoration: none;
    border: none;
    background: ${colors.primary};
    color: ${colors.white};
    border-radius: 5px;
    padding: 1rem;
    transition: 0.3s;
    height: 4rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: ${(props) => (props.invisible ? "none" : "flex")};

    &:hover {
      background-color: ${colors.secondary};
      cursor: ${(props) => (props.blocked ? "not-allowed" : "pointer")};
    }

    &:active {
      background: ${colors.secondary};
      color: ${colors.white};
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .link-secondary {
    text-decoration: none;
    border: 1px solid ${colors.primary};
    background: none;
    color: ${colors.primary};
    border-radius: 5px;
    padding: 1rem;
    transition: 0.3s;
    height: 4rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: ${(props) => (props.invisible ? "none" : "flex")};

    &:hover {
      text-decoration: none;
      border: 1px solid ${colors.secondary};
      color: ${colors.secondary};
      cursor: ${(props) => (props.blocked ? "not-allowed" : "pointer")};
    }

    &:active {
      border: 1px solid ${colors.secondary};
      color: ${colors.secondary};
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;



export default GlobalStyles;
