import styled from "styled-components";
import { colors } from "../../styles/colors";

const getColor = (props) => {
  if (props.isDragReject) {
    return `${colors.red}`;
  }
  if (props.isDragActive) {
    return `${colors.primary}`;
  }
  if (props.isDropped) {
    return `${colors.primary}`;
  }
  return `${colors.gray300}`;
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
`;

export const ListArea = styled.div`
  width: 70%;
  margin: 15px;
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;
  transition: 0.3s;
  border-radius: 8px;
  background-color: ${colors.gray300};

  & > ul {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border: 6px solid ${colors.primary};

    & > h4 {
      color: ${colors.primary};
      width: 100%;
      border-bottom: 2px solid ${colors.primary};
      text-align: center;
      margin-bottom: 0.5rem;
    }

    & > li {
      background: ${colors.secondary};
      color: #f2f2f2;
      padding: 0.5rem;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      & > input {
        background: transparent;
        border: 1px solid transparent;
        text-align: center;
        font-size: 2rem;
        transition: 0.5s;
      }

      & > input:hover {
        border: 1px solid ${colors.white};
      }

      & > input:focus {
        border: 1px solid ${colors.white};
        background: ${colors.white};
        color: #333333;
      }

      & > svg {
        font-size: 3rem;
      }

      #edit-button:hover {
        cursor: pointer;
      }
    }
  }
  .selectSig:focus {
    border-color: ${colors.primary} !important;
  }
  .selectSig:hover {
    border-color: ${colors.primary} !important;
  }
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus {
    border-color: ${colors.primary} !important;
  }
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover {
    border-color: ${colors.primary} !important;
  }
  & > div#second > div {
    flex-wrap: wrap;
    height: auto;
    & > div.selectSig {
      width: 50%;
      .rs-picker-search-bar-input:focus {
        border-color: ${colors.primary} !important;
      }
      & > .rs-picker-search-bar-input:focus {
        border-color: ${colors.primary} !important;
      }
      & > a {
        text-align: center;
      }
    }

    & > div.selectSig:focus {
      border-color: ${colors.primary} !important;
    }

    & > div.selectSig:hover {
      border-color: ${colors.primary} !important;
    }

    .rs-picker-search-bar-input:focus {
      border-color: ${colors.primary} !important;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    margin: 0;

    & > div {
      flex-wrap: wrap;
    }

    & > div#first {
      flex-wrap: wrap;
      p {
        margin: auto;
        text-align: center;
      }
      & > input {
        width: 100%;
      }
    }

    & > ul > li {
      flex-wrap: wrap;
      height: auto;
      & > input {
        overflow: hidden;
        text-overflow: ellipsis;
        direction: ltr;
      }
    }

    & > div#second > div {
      flex-wrap: wrap;
      height: auto;
      & > div.selectSig {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
`;

export const DropArea = styled.div`
  width: 100%;
  min-height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.isDropped ? "" : "center")};
  align-items: ${(props) => (props.isDropped ? "" : "center")};
  border-radius: 8px;
  border-color: ${(props) => getColor(props)};
  border: ${(props) =>
    props.isDropped ? "none" : `4px dashed ${colors.gray300}`};
  color: ${(props) => getColor(props)};
  transition: 0.3s;
  margin: ${(props) => (props.isDropped ? "0" : "15px")};
  background-color: ${(props) => (props.isDropped ? "" : "")};

  & > p {
    margin-top: 2rem;
    font-size: 1.5rem;
    transition: 0.3s;
    text-align: center;
  }

  & > svg {
    color: ${(props) => getColor(props)};
    font-size: 5rem;
    transition: 0.3s;
  }

  .docs {
    display: flex;
    width: 98%;
    height: 70px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${colors.gray300};
    border-radius: 8px;
    background-color: ${colors.gray200};
    padding: 0 5px;
    border-left: 5px solid ${colors.primary};
    gap: 5px;
    margin-top: 20px;

    input {
      border: none;
      padding: 5px;
      background-color: ${colors.gray300};
      border-radius: 8px;
      width: 55%;
      text-align: center;
    }
  }

  .newDoc {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    margin-top: 17px;
  }
`;

export const SignatariosArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 8px;
  gap: 10px;

  & > div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    gap: 20px;

    & > div {
      flex: 1;
    }

    & > strong {
      font-size: 2rem;
      color: ${colors.primary};
    }
  }
  .label {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: -25px;
  }
`;

export const SignatariosList = styled.ul`
  width: 100%;
  margin: 0.5rem;
`;

export const SignatarioPlaceholder = styled.li`
  display: ${(props) => (props.visible ? "flex" : "none")};
  background: ${colors.white};
  border: 2px dashed ${colors.gray200};
  padding: 1rem;
  border-radius: 16px;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
  color: ${colors.darker};
`;

export const Options = styled.div`
  width: 100%;
  color: ${colors.darker};
  display: flex;
  align-items: center;
  padding: 2rem;
  margin-top: 10px;
  justify-content: flex-start;
  background: ${colors.gray300};
  flex-wrap: wrap;
`;

export const ProcessoArea = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 8px;
  gap: 20px;

  & > input {
    flex: 1;
    background: ${colors.gray200};
    border-radius: 8px;
    height: 42px;
    padding: 0 10px;
    border: none;
  }

  & > strong {
    color: ${colors.primary};
    font-size: 2rem;
  }
`;

export const NoDropArea = styled.div`
  width: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 6px;
  border-style: dashed;
  border-color: ${colors.gray400};
  color: ${colors.gray400};

  & > p {
    margin-top: 2rem;
    font-size: 1.5rem;
    transition: 0.3s;
    text-align: center;
  }

  & > svg {
    color: ${colors.gray400};
    font-size: 5rem;
    transition: 0.3s;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.gray300};
  border-radius: 10px;
  padding: 16px;
  gap: 5px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: 90%;

  @media (max-width: 700px) {
    overflow-y: auto;
  }
`;
