/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from "./styles";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useAuth } from "../../hooks/useAuth";
import { useLocation } from 'react-router-dom';
import { useDash } from './../../hooks/useDash';
import React, { useState, useEffect, memo } from "react";

function StatusBar() {

  const {status} = useParams() 
  const { setIsEmpty, allProccess, getProcessCount } = useDash()
  const { pathname } = useLocation()
  const [classLink, setClassLink] = useState(0);
  const [,setAddMore] = useState(false);
  
  useEffect(() => {
    console.log(addZero(parseInt(allProccess.detail?.pending) 
    + parseInt(allProccess.detail?.finished)
    + parseInt(allProccess.detail?.canceled)
    ))
    getProcessCount()
    setIsEmpty(false)
    updateClassLink()
  }, [status, pathname])

  const { user } = useAuth();

  function addZero(numberString) {
    const number = parseInt(numberString);
    if (number < 10) {
      return `0${number}`;
    } else {
      return numberString;
    }
  }

  function updateClassLink() {
    return (
      status === 'all' 
        ? setClassLink(1) 
          : status === 'pending' 
          ? setClassLink(2) 
            : status === 'finalized' 
            ? setClassLink(3)
              : status === 'canceled'
              ? setClassLink(4)
                : pathname === '/trash'
                ? setClassLink(5)
                  : status === undefined
                  ? setClassLink(null) 
                    : ''
    )
  }

  useEffect(() => {
    getProcessCount();
  }, []);

  return (
    <Container id="container">
      <Link
        to="/process/list/all"
        // onClick={() => setClassLink(1)}
      >
        <div className={classLink === 1 ? "clicked" : ""}>
          <div>
            <p>Totais:</p>
          </div>
          <strong>&nbsp;{
          isNaN(addZero(parseInt(allProccess.detail?.pending) 
            + parseInt(allProccess.detail?.finished)
            + parseInt(allProccess.detail?.canceled)
            ))
              ? '00'
              : addZero(parseInt(allProccess.detail?.pending) 
              + parseInt(allProccess.detail?.finished)
              + parseInt(allProccess.detail?.canceled)  
                )
            }</strong>
        </div>
      </Link>
      <Link
        to="/process/list/pending"
        onClick={() => getProcessCount()}
      >
        <div className={classLink === 2 ? "clicked" : ""}>
          <div>
            <p>Pendentes:</p>
          </div>
          <strong>
            &nbsp;
            {addZero(allProccess.detail?.pending)}
          </strong>
        </div>
      </Link>
      <Link
        to="/process/list/finalized"
        onClick={() => getProcessCount()}
      >
        <div className={classLink === 3 ? "clicked" : ""}>
          <div>
            <p>Finalizados:</p>
          </div>
          <strong>
            &nbsp;
            {addZero(allProccess.detail?.finished)}
          </strong>
        </div>
      </Link>
      <Link
        to="/process/list/canceled"
        onClick={() => getProcessCount()}
      >
        <div className={classLink === 4 ? "clicked" : ""}>
          <div>
            <p>Cancelados:</p>
          </div>
          <strong>
            &nbsp;
            {addZero(allProccess.detail?.canceled)}
          </strong>
        </div>
      </Link>
      <Link
        to="/trash"
        onClick={() => getProcessCount()}
      >
        <div className={classLink === 5 ? "clicked" : ""}>
          <div>
            <p>Excluídos:</p>
          </div>
          <strong>
            &nbsp;
            {addZero(allProccess.detail?.excluded)}
          </strong>
        </div>
      </Link>
      <Link 
        to={pathname}
        onClick={() => getProcessCount()}
      >
        <div
          id="saldo-container"
          onMouseEnter={() => {
            setAddMore(true);
          }}
          onMouseLeave={() => {
            setAddMore(user.balance === 0);
          }}
        >
          <p>
            {/* {user.unlimited_process ? (
              "Saldo Ilimitado"
            ) : addMore ? (
              "Contratar Mais!"
            ) : ( */}
              <div className="containerButton">
                <span>Saldo: </span>
                <span>{addZero(allProccess.detail?.balance)}</span>
              </div>
            {/* )} */}
          </p>
        </div>
      </Link>
    </Container>
  );
}

export default memo(StatusBar);