import styled from 'styled-components'
import { colors } from '../../styles/colors'

export const PrimaryButton = styled.button`
  border: none;
  background: ${colors.primary};
  color: ${colors.white};
  border-radius: 5px;
  padding: 1rem;
  transition: 0.3s;
  height: 4rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: ${props => (props.invisible ? 'none' : 'flex')};

  &:hover {
    background-color: ${colors.secondary};
    cursor: ${props => (props.blocked ? 'not-allowed' : 'pointer')};
  }

  &:active {
    background: ${colors.secondary};
    color: ${colors.white};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const SecondaryButton = styled.button`
  border: 1px solid ${colors.primary};
  background: none;
  color: ${colors.primary};
  border-radius: 5px;
  padding: 1rem;
  transition: 0.3s;
  height: 4rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: ${props => (props.invisible ? 'none' : 'flex')};

  &:hover {
    border: 1px solid ${colors.secondary};
    color: ${colors.secondary};
    cursor: ${props => (props.blocked ? 'not-allowed' : 'pointer')};
  }

  &:active {
    border: 1px solid ${colors.secondary};
    color: ${colors.secondary};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const TertiaryButton = styled.button`
  border: none;
  background: ${colors.red};
  color: ${colors.white};
  border-radius: 5px;
  padding: 1rem;
  transition: 0.3s;
  height: 4rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: ${props => (props.invisible ? 'none' : 'flex')};

  & > svg {
    margin-right: 10px;
    margin-bottom: 3px;
  }

  &:hover {
    background-color: #c63a35e4;
    cursor: ${props => (props.blocked ? 'not-allowed' : 'pointer')};
  }

  &:active {
    background: #e3150d;
    color: ${colors.white};
    & > p {
      display: none;
    }

    & > svg {
      margin-right: 0;
    }

  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`