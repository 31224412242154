import styled from 'styled-components'
import { colors } from './../../styles/colors';

export const Container = styled.div`
  /* border: 4px dotted ${colors.primary}; */
  padding:0px;
  margin: 4px;
  overflow: hidden;

  & > div {
    width: 100% !important;
  }

  .test{
    & > canvas {
      border-radius: 15px
    }
  }
  
`
