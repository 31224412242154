import { useState } from 'react'
import { Link } from 'react-router-dom'
import * as api from '../../services/api'
import { RadioGroup, Radio } from 'rsuite'
import Input from '../../components/Input'
import SafeButton from './../../components/SafeButton/index';
import { Container, InputContainer, Title, Form, Row, RadioContainer } from './styles'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function CreatePlan() {

  const [planType, setPlanType] = useState('1')
  const [category, setCategory] = useState('1')
  const [visibility, setVisibility] = useState('1')

  const navigate = useNavigate()

  function submit(evento) {
    const formData = new FormData(evento);
    let formProps = Object.fromEntries(formData);
    let forSubmit = { ...formProps, plan_type: planType, category: category, visibility: visibility }
    
    console.log(forSubmit)

    api
      .post('/plan/create/', forSubmit, "application/json")
      .then((response) => {
        if (response?.status === 201) {
          toast.success('Plano criado com sucesso', {
            position: toast.POSITION.TOP_RIGHT
          })
          navigate('/plans', { replace: true })
        } else if (response?.response?.status === 400) {
          toast.error(response?.response?.data?.detail, {
            position: toast.POSITION.TOP_RIGHT
          })
        } else {
          toast.error('Ocorreu um erro ao cadastrar o plano, tente novamente mais tarde', {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      })
      .catch(error => {
        toast.error('Ocorreu um erro ao cadastrar o plano, tente novamente mais tarde', {
          position: toast.POSITION.TOP_RIGHT
        })
      })
  }

  return (
    <>
      <Title>
        <p>Cadastrar Novo Plano</p>
        <Link to="/plans">
          <SafeButton variant='primary'>Voltar</SafeButton>
        </Link>
      </Title>
      <Container>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            submit(e.target)
          }}
          action="reset"
        >
          <Row style={{marginBottom:'15px'}}>
            <InputContainer>
              <p>Nome:</p>
              <Input
                type="text"
                name="name"
                required
                placeholder="Nome do Plano"
                id="name"
              />
            </InputContainer>
            <InputContainer>
              <p>Valor: R$</p>
              <Input
                type="number"
                name="price"
                step=".01"
                placeholder="0.00"
                required
              />
            </InputContainer>
            <InputContainer>
              <p>Quantidade de Usuários: </p>
              <Input
                type="number"
                required
                name="users"
                placeholder="0"
              />
            </InputContainer>
            <InputContainer>
              <p>Quantidade de Documentos: </p>
              <Input
                type="number"
                name="processes"
                required
                placeholder="0"
              />
            </InputContainer>
            <InputContainer>
              <p style={{textAlign:'center'}}>Email: </p>
              <div style={{display:'flex', justifyContent:'space-between', gap:'5px'}}>
                <Input
                  min={0}
                  max={99}
                  style={{width:'100%', textAlign:"center"}}
                  type="number"
                  name="email_frequency"
                  required
                  placeholder="Dias"
                />
                <Input
                  min={0}
                  max={99}
                  style={{width:'100%', textAlign:"center"}}
                  type="number"
                  name="email_amount"
                  required
                  placeholder="Frequência"
                />
              </div>
            </InputContainer>
            <InputContainer>
              <p style={{textAlign:'center'}}>SMS: </p>
              <div style={{display:'flex', justifyContent:'space-between', gap:'5px'}}>
                <Input
                  min={0}
                  max={99}
                  style={{width:'100%', textAlign:"center"}}
                  type="number"
                  name="sms_frequency"
                  required
                  placeholder="Dias"
                />
                <Input
                  min={0}
                  max={99}
                  style={{width:'100%', textAlign:"center"}}
                  type="number"
                  name="sms_amount"
                  required
                  placeholder="Frequência"
                />
              </div>
            </InputContainer>
            <InputContainer>
              <p style={{textAlign:'center'}}>WhatsApp: </p>
              <div style={{display:'flex', justifyContent:'space-between', gap:'5px'}}>
                <Input
                  min={0} 
                  max={99}
                  style={{width:'100%', textAlign:"center"}}
                  type="number"
                  name="whatsapp_frequency"
                  required
                  placeholder="Dias"
                />
                <Input
                  min={0}
                  max={99}
                  style={{width:'100%', textAlign:"center"}}
                  type="number"
                  name="whatsapp_amount"
                  required
                  placeholder="Frequência"
                />
              </div>
            </InputContainer>
          </Row>
          <Row>
            <RadioContainer >
              <RadioGroup
                defaultValue="1"
                value={planType}
                onChange={(value) => setPlanType(value)}
              >
                <p>Tipo do Plano:</p>
                <Radio value="1">Plano Mensal</Radio>
                <Radio value="2">Plano Anual</Radio>
              </RadioGroup>
            </RadioContainer>
          </Row>
          <Row>
            <RadioContainer>
              <RadioGroup
                defaultValue="1"
                onChange={(value) => setCategory(value)}
              >  <p>Categoria:</p>
                <Radio value="1">Pessoal</Radio>
                <Radio value="2">Empresarial</Radio>
              </RadioGroup>
            </RadioContainer>
          </Row>
          <Row>
            <RadioContainer>
              <RadioGroup
                defaultValue="1"
                onChange={(value) => setVisibility(value)}
              >
                <p>Abrangência:</p>
                <Radio value="1">Público</Radio>
                <Radio value="2">Privado</Radio>
              </RadioGroup>
            </RadioContainer>
          </Row>
          <p style={{ textAlign: 'center', paddingBottom:'10px' }}>Descrição: </p>
          <textarea
            maxLength={255}
            type="text"
            id="descricao_planos"
            name="description"
            style={{ width: '100%', textAlign: 'center', height: '120px', resize: 'none', background: '#ececec', borderRadius:'15px'}}
          />
          <div className='buttonsContainer'>
            <SafeButton variant='primary' type="submit">Cadastrar</SafeButton>
          </div>
        </Form>
      </Container>
    </>
  )
}