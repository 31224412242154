/* eslint-disable array-callback-return */
import { SelectPicker } from "rsuite";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import { BsSafe2 } from "react-icons/bs";
import * as api from "../../services/api";
import { ScaleLoader } from "react-spinners";
import { useState, useEffect } from "react";
import SafeButton from "../../components/SafeButton/index";
import {
  Container,
  Cofre,
  ControlsContainer,
  ContainerNewSafy,
  ContainerNewSafyDelete,
} from "./styles";

export default function Vaults() {


  const [open, setOpen] = useState(false);
  const [vaults, setVault] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [idForDestroy, setIdForDestroy] = useState("");
  const [nameOfNewCompany, setNameOfNewCompany] = useState("");
  const [selectPickerData, setSelectPickerData] = useState([
    { label: "Carregando...", value: 0 },
  ]);


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const getVaults = () => {
    setIsLoaded(false);
    api
      .get("/vault/list/unique/")
      .then((response) => {
        let data = [];
        response.data.detail.map((item) => {
          data = [...data, { label: item.name, value: item.id }];
        });
        setSelectPickerData(data);
        let dataVaults = response.data.detail;
        setVault(dataVaults);
        setIsLoaded(true);
      })
      .catch((error) => {
        toast.error("Ocorreu um erro ao tentar resgatar os cofres", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };


  useEffect(() => {
    getVaults();
  }, []);

  function handleChange(e) {
    setNameOfNewCompany(e.target.value);
  }

  function createVault() {
    let data = { name: nameOfNewCompany };
    api
      .post("/vault/create/", data, { content_type: "application/json" })
      .then((response) => {
        if (response.status === 201) {
          toast.success("Cofre criado com sucesso", {
            position: toast.POSITION.TOP_RIGHT,
          });
          getVaults();
          handleClose();
        } else if (response.response.status === 400) {
          toast.error(response.response.data.detail.name[0], {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      })
      .catch((error) => {
        toast.error(
          "Ocorreu um erro ao tentar criar o cofre, tente novamente mais tarde",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      });
  }

  function deleteVault(id) {
    api.destroy(`/vault/destroy/${id}/`)
    .then((response) => {
      if(response.status === 204){
        toast.success('Cofre deletado com sucesso', {
          position: toast.POSITION.TOP_RIGHT
        })
        getVaults()
        handleCloseDelete()
      } else if (response.status === 400) {
        toast.error(response.data.detail, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })
    .catch((error) => {
      toast.error('Ocorreu um erro ao tentar excluir o cofre, tente novamente mais tarde', {
        position: toast.POSITION.TOP_RIGHT
      })
    })
  }

  function name(vault) {
    return vault.name.length > 30
      ? vault.name.substring(0, 25) + "..."
      : vault.name;
  }

  const body = (
    <>
      <ContainerNewSafy>
        <input placeholder="Nome do cofre" onChange={handleChange} onKeyDown={(e) => {
          if (e.key === "Enter"){
            createVault()
          }
          }}/>
        <SafeButton variant="primary" onClick={createVault}>
          Criar
        </SafeButton>
      </ContainerNewSafy>
    </>
  );

  const bodyDelete = (
    <>
      <ContainerNewSafyDelete>
        <p>Selecione o cofre que deseja excluir: </p>
        <SelectPicker
          preventOverflow
          placeholder='Selecione o cofre'
          placement="bottomStart"
          data={selectPickerData}
          onSelect={(value)=>setIdForDestroy(value)}
          defaultValue=""
        />
        <SafeButton variant="primary" onClick={() => deleteVault(idForDestroy)}>
          Confirmar
        </SafeButton>
      </ContainerNewSafyDelete>
    </>
  );

  return (
    <>
      <ControlsContainer>
        <SafeButton variant="secondary" onClick={handleOpen}>
          Novo Cofre
        </SafeButton>
        <SafeButton variant="primary" onClick={handleOpenDelete}>
          Excluir Cofre
        </SafeButton>
      </ControlsContainer>
      {!isLoaded && <ScaleLoader color="#60159a" width="10px" />}
      <Container>
        {isLoaded &&
          vaults.map((vault) => {
            return (
              <Link
                key={vault.id}
                to={`/vault/${vault.id}`}
                style={{ textDecoration: "none" }}
              >
                <Cofre>
                  <BsSafe2 size={50} color="#60159a" />
                  <p>{name(vault)}</p>
                </Cofre>
              </Link>
            );
          })}
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {body}
      </Modal>
      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {bodyDelete}
      </Modal>
    </>
  );
}