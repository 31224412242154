/* eslint-disable react-hooks/exhaustive-deps */
import { Page } from 'react-pdf'
import { saveAs } from 'file-saver'
import { Document } from 'react-pdf'
import { useLayoutEffect } from 'react'
import Modal from '@mui/material/Modal'
import { FiCheck } from 'react-icons/fi'
import { MdClose } from 'react-icons/md'
import * as api from '../../services/api'
import { TiCancel } from 'react-icons/ti'
import Input from '../../components/Input'
import { Fonts } from '../../utils/getFont'
import { useAuth } from '../../hooks/useAuth'
import { colors } from './../../styles/colors'
import { FaFileDownload } from 'react-icons/fa'
import { AiOutlineReload } from 'react-icons/ai'
import SafeButton from '../../components/SafeButton'
import EventsComponent from '../../components/Events'
import { toast, ToastContainer } from 'react-toastify'
import { BounceLoader, GridLoader } from 'react-spinners'
import useWindowSize2 from './../../hooks/useWindowSize2'
import React, { useEffect, useState, useRef } from 'react'
import addFilesSvg from '../../assets/images/add-files.svg'
import { RiZoomInFill, RiZoomOutFill } from 'react-icons/ri'
import Logo from '../../assets/images/SAFYSIGN-LOGO-PNG.png'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { SignCanvas, saveSign, cleanSign } from '../../components/SignCanvas'
import { InputMaskNormal as InputMask } from '../../components/Input/InputMask'
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io'
import { Container, Screen, ModalContainer, InfoContainer, SignModal, ExpiredContainer, KeyModalContainer, KeyModalTitle, KeyModalText, KeyModalInputContainer, KeyModalBtnContainer, SignUpModalText, SignUpModalContainer, SignUpDraw, Font, InputContainer, PDFContainer, PDFNav, LoadingContainer, LabelContainer, ModalContainerFont } from './styles'

export default function Signature() {

    const width = useWindowSize2()

    const geoLocation = useRef({})
    const { signed, user, getEventos } = useAuth()
    const { idProcess, token } = useParams()
    const [loading, setLoading] = useState(false)
    const [blocked, setBlocked] = useState(false)
    const [loadingModal, setLoadingModal] = useState(false)
    const [showModal, setShowModal] = useState({
        show: true,
        loading: false,
        expired: false,
        denied: false
    })
    const [canceled, setCanceled] = useState(false)
    const [tokenModal, setTokenModal] = useState(false)
    const [processData, setProcessData] = useState(null)
    const [tokenLoading, setTokenLoading] = useState(false)
    const [showSignModal, setShowSignModal] = useState(false)
    const PDFRef = useRef()
    const [email, setEmail] = useState()
    const [cpfUser, setCpfUser] = useState()
    const [allowed, setAllowed] = useState(false)
    const [PDFZoom, setPDFZoom] = useState(1)
    const [userData, setUserData] = useState()
    const [dateUser, setDateUser] = useState()
    const [nameUser, setNameUser] = useState()
    const [message, setMessage] = useState('')
    const [location, setLocation] = useState({
        lat: '',
        lon: ''
    })
    const [typeSign, setTypeSign] = useState({
        rubric: false,
        unabbreviated: false
    })
    const [lastToken, setLastToken] = useState()
    const [emailToken, setEmailToken] = useState()
    const [pageNumber, setPageNumber] = useState(0)
    const [dontSign, setDontSign] = useState(false)
    const [currentUser, setCurrentUser] = useState()
    const [chosenFont, setChosenFont] = useState('')
    const [isRefused, setIsRefused] = useState(false)
    const [cpfForSubmit, setCpfForSubmit] = useState()
    const [dateForSubmit, setDateForSubmit] = useState()
    const [nameForSubmit, setNameForSubmit] = useState()
    const [signUpModal, setSignUpModal] = useState(false)
    const [safariModal, setSafariModal] = useState(false)
    const [timeForReSend, setTimeForReSend] = useState(false)
    const [buttonBlocked, setButtonBlocked] = useState(false)
    const [openTokenModal, setOpenTokenModal] = useState(false)
    const [dontSignForOrd, setDontSignForOrd] = useState(false)
    const [tokenVerifyLoading, setTokenVerifyLoading] = useState(false)
    const [backgroundColorButton, setBackgroundColorButton] = useState()
    const [confirmSignFontModal, setConfirmSignFontModal] = useState(false)
    const [sendSignatureLoading, setSendSignatureLoading] = useState(false)
    const [PDF, setPDf] = useState({ data: null, pages: 0, isLoaded: false })
    const [userSignatures, setUserSignatures] = useState({ sign: '', rubric: '' })
    const [confirmSignModalUnabbreviated, setConfirmSignModalUnabbreviated] = useState(false)

    const canvasRef = useRef()
    const navigate = useNavigate()

    useEffect(() => {
        setPDFZoom(width[0] <= 374 ? 0.51 : width[0] <=425 ? 0.6 : width[0] <= 550 ? 0.68 : 1)
    }, [width])

    useEffect(() => {
        if (signed === true) {
            setCpfUser(userData?.cpf)
            setDateForSubmit(userData?.age)
            setNameUser(userData?.get_full_name)
            setDateUser(dateFormat(userData?.age))
            setNameForSubmit(userData?.get_full_name)
            setCpfForSubmit(userData?.cpf.replace(/[.]/g, '').replace(/[-]/g, ''))
        }
    }, [userData])

    useEffect(() => {
        if (signed === true) {
            api
                .get(`/user/retrieve/${user.id}/`)
                .then((response) => {
                    let data = response.data.detail
                    if (response?.status === 200) {
                        setUserSignatures({ sign: data.sign, rubric: data.rubric })
                    } else {
                        toast.error('Ocorreu um erro ao resgatar as informações do usuário', {
                            position: toast.POSITION.TOP_RIGHT
                        })
                    }
                })
                .catch((error) => {
                    toast.error('Ocorreu um erro ao resgatar as informações do usuário', {
                        position: toast.POSITION.TOP_RIGHT
                    })
                })
        }
    }, [])

    useLayoutEffect(() => {
        getDataSigned()
    }, [])

    function tokenVerify() {
        api
            .post(`/safytoken/retrieve/${idProcess}/`, { 'email': email, 'token': emailToken }, "application/json")
            .then((response) => {
                if (response?.status === 200) {
                    setTokenVerifyLoading(false)
                    toast.success('Token Verificado com sucesso', {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setLastToken(response?.data.detail.token)
                    setOpenTokenModal(false)
                    if (signed === true) {
                        if (typeSign.rubric === true && userSignatures.rubric !== null) {
                            setConfirmSignModalUnabbreviated(true)
                        } else if (typeSign.unabbreviated === true && userSignatures.sign !== null) {
                            setConfirmSignModalUnabbreviated(true)
                        } else if (typeSign.rubric === true) {
                            setShowSignModal(true)
                        } else if (typeSign.unabbreviated === true) {
                            setConfirmSignFontModal(true)
                        } else {
                            toast.error('Ocorreu um erro, favor tente novamente mais tarde!', {
                                position: toast.POSITION.TOP_RIGHT
                            })
                        }
                    } else {
                        if (typeSign.rubric === true) {
                            setShowSignModal(true)
                        } else if (typeSign.unabbreviated === true) {
                            setConfirmSignFontModal(true)
                        }
                    }
                } else {
                    setTokenVerifyLoading(false)
                    toast.error('Informe um token valido', {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            })
            .catch((error) => {
                toast.error('Informe um token valido', {
                    position: toast.POSITION.TOP_RIGHT
                })
            })
    }

    function getProcessLocation() {
        navigator.geolocation.getCurrentPosition(
            position => {
                setTimeout(() => getProcessInfo(geoLocation.current), 1000)
                geoLocation.current = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    acuracia: position.coords.accuracy
                }
                setAllowed(true)
                setLocation({
                    lat: position.coords.latitude,
                    lon: position.coords.longitude
                })
            },
            error => {
                setAllowed(true)
                setTimeout(() => getProcessInfo(geoLocation.current), 1000)
                if (error.code === 2) {
                    setSafariModal(true)
                } else if (error.code === 1) {
                    return
                }
                setShowModal({
                    show: true,
                    loading: false,
                    expired: false
                })
            },
            { enableHighAccuracy: true }
        )
        setShowModal(
            {
                show: false,
                loading: false,
                expired: false,
                denied: false
            }
        )
    }

    function formatRubricForJpg() {

        if (typeSign?.rubric === true) {
            const onImageEdit = async (imgUrl) => {
                const response = await fetch(imgUrl)
                    .then(response => response.blob())
                    .then(imageBlob => imageBlob)

                return response
            }

            onImageEdit(userSignatures?.rubric.file)
                .then((response) => {
                    formatRubricForBase64(response)
                })

        }
    }

    function formatRubricForBase64(rubric) {

        var reader = new FileReader()

        reader.readAsDataURL(rubric)

        reader.onload = function () {
            submit(reader.result.split(','))
        }
    }

    function submit(signature) {
        
        let rubricForSubmit =
            signed === true ?
                isRefused === false ?
                    typeSign.rubric === true && userSignatures.rubric !== null
                        ? signature[1]
                        : typeSign.unabbreviated === true && userSignatures.sign !== null
                            ? userSignatures.sign.text
                            : typeSign.rubric === true
                                ? signature
                                : signature.text
                : typeSign.rubric === true 
                    ? signature
                    : signature?.text
            : typeSign.rubric === true
                ? signature
                : typeSign.unabbreviated === true
                    ? signature.text
                    : ''

        let complimentForSubmit =
            signed === true
                ? typeSign.rubric === true
                    ? "rubric.png"
                    : typeSign.unabbreviated === true && userSignatures.sign !== null && isRefused === false
                        ? userSignatures.sign.font
                        : signature.font
                : typeSign.rubric === true
                    ? "rubric.png"
                    : typeSign.unabbreviated === true
                        ? signature.font
                        : ''

        let typeToSubmit =
            typeSign.rubric === true
                ? "1"
                : typeSign.unabbreviated === true
                    ? '2'
                    : ''

        let data = {
            cpf: cpfForSubmit,
            age: dateForSubmit,
            sign: {
                data: rubricForSubmit,
                compliment_data: complimentForSubmit
            },
            type: typeToSubmit,
            latitude: location.lat,
            longitude: location.lon,
            browser: navigator.userAgent,
            email_signature: currentUser?.email,
            full_name: nameForSubmit,
            verify_token: lastToken
        }

        api
            .post(`/signatures/sign/${idProcess}/${token}/`, data, "application/json")
            .then((response) => {
                if (response?.status === 201) {
                    getProcessInfo()
                    getEventos(idProcess)
                    setConfirmSignModalUnabbreviated(false)
                    setSendSignatureLoading(false)
                    setShowSignModal(false)
                    setLoading(false)
                    setButtonBlocked(false)
                    setLoadingModal(false)
                    setConfirmSignFontModal(false)
                    setBlocked(true)
                    toast.success("Processo assinado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    if (!signed) {
                        setSignUpModal(true)
                    }
                } else {
                    toast.error("Ocorreu um erro ao assinar o processo", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            })
    }

    function getDataSigned() {
        setUserData(user)
    }

    function getProcessInfo(geo) {
        const data = geo?.latitude !== null && geo?.latitude !== undefined && geo?.latitude !== 'undefined' ? `?latitude=${geo?.latitude}&longitude=${geo?.longitude}` : ''
        api.get(`/process/retrieve/sign/${idProcess}/${token}/${data}`)
            .then((response) => {
                if (response?.status === 200) {

                    let data = response?.data.detail
                    let pass = false
                    let currentSigned = false

                    setProcessData(response?.data.detail)
                    response?.data.detail.signatories.forEach((item) => {
                        item.token === token && setCurrentUser(item)
                    })

                    let myOrd = data.signatories?.findIndex((item) => item.token === token)
                    data.signatories?.forEach((item) => {
                        if (item.token === token) {
                            setEmail(item.email)
                            pass = true
                            if (item?.signed === true) {
                                currentSigned = true
                            }
                        }
                    })
                    if (pass === false) {
                        setDontSign(true)
                        setMessage('Você não assina esse processo')
                        setShowModal({...showModal, show: true})
                    } else if (data.status === '2') {
                        setBlocked(true)
                        setMessage('Processo já está finalizado')
                        setShowModal({...showModal, show: true})
                    } else if (data.status === '3') {
                        setCanceled(true)
                        setMessage('Processo já está cancelado')
                        setShowModal({...showModal, show: true})
                    } else {
                        let order = 0
                        data?.signatories?.forEach((item) => {
                            if (item.signed === true) {
                                order += 1
                            }
                        })
                        if(data.orderly === true) {
                            if (myOrd < order) {
                                setDontSignForOrd(true)
                                setMessage('Você já assinou, aguarde até que todos assinem para baixar o documento')
                                setShowModal({...showModal, show: true})
                            }
                            if (myOrd > order) {
                                setDontSignForOrd(true)
                                setMessage('Ainda não é sua vez de assinar')
                                setShowModal({...showModal, show: true})
                            }
                            if (myOrd === order) {
                                setShowModal({...showModal, show: false})
                            }
                        } else {
                            if (currentSigned === false) {
                                setShowModal({...showModal, show: false})
                            } else {
                                setBlocked(true)
                                setShowModal({...showModal, loading:false})
                                setMessage('Você já assinou este processo, aguarde até que todos assinem para a finalização')
                            }

                        }
                    }
                } else {
                    toast.error('Ocorreu um erro ao resgatar as informações do processo', {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            })
            .catch((error) => {
                toast.error('Ocorreu um erro ao resgatar as informações do processo', {
                    position: toast.POSITION.TOP_RIGHT
                })
            })
    }

    function getFirstToken(word) {
        setTokenLoading(true)

        let data = ''

        processData?.signatories.forEach((item) => {
            if (item.token === token) {
                data = item.email
            }
        })

        api
            .post(`/safytoken/new/token/${idProcess}/`, { 'email': data }, "application/json")
            .then((response) => {
                if (response?.status === 200) {
                    setTokenLoading(false)
                    setTokenModal(false)
                    toast.success(`Token de acesso ${word} ao e-mail do assinante`, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    if (openTokenModal === false) {
                        setOpenTokenModal(true)
                    }
                } else {
                    setTokenLoading(false)
                    setTokenModal(false)
                    toast.error('Ocorreu um erro ao enviar o token, tente novamente mais tarde', {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            })
            .catch((error) => {
                toast.error('Ocorreu um erro ao enviar o token, tente novamente mais tarde', {
                    position: toast.POSITION.TOP_RIGHT
                })
            })
    }

    function validateNullRubric(dataUri) {
        if (dataUri === null) {
            toast.error('Rubrica não pode ser enviada em branco', {
                position: toast.POSITION.TOP_RIGHT
            })
            return false
        } else {
            return true
        }
    }

    async function getBase64() {
        const { dataUri } = saveSign(canvasRef)

        if (validateNullRubric(dataUri)) {
            submit(dataUri)
        } else {
            return
        }
    }

    function closeSignModal() {
        setShowSignModal(false)
        setButtonBlocked(false)
        setLoading(false)
    }

    function dateFormat(date) {
        if (date != null) {
            var arrDate = date.split("-");
            return arrDate[2] + "/" + arrDate[1] + "/" + arrDate[0];
        }
    }

    async function onDocumentLoadSuccess(successEvent) {
        const data = await successEvent.getData()
        setPageNumber(1)
        setPDf({
            data,
            pages: successEvent.numPages,
            isLoaded: true
        })
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset)
    }

    function previousPage() {
        changePage(-1)
    }

    function nextPage() {
        changePage(1)
    }

    function saveFile2() {
        const blob = new Blob([PDF.data], { type: 'application/pdf' })
        saveAs(blob, 'documento.pdf')
    }

    function plusZoom() {
        // if (PDFZoom === null) {
        //     setPDFZoom(width[0] <= 374 ? 0.51 + 0.1 : width[0] <=425 ? 0.6 + 0.1 : width[0] <= 550 ? 0.68 + 0.1 : 1 + 0.1)
        // } else {
            setPDFZoom(PDFZoom + 0.1)
        // }
        
    }

    function minusZoom() {
        setPDFZoom(PDFZoom - 0.1)
    }

    async function redirectToPDF() {
        const newWindow = window.open(processData?.documents[0]?.final_file);
        if (newWindow) newWindow.opener = null;
    }

    return (
        <div style={{maxWidth:'100%'}}>
            <Screen>
                <ToastContainer />
                <header>
                    <img src={Logo} alt="Safy Sign" />
                    {signed === true && width[0] > 1023 ? <div style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', marginRight: '08%' }}>
                        <SafeButton variant='primary' onClick={() => navigate('/', { replace: true })}> Voltar </SafeButton>
                    </div> : ''}
                </header>
                <Container style={{ paddingBottom: '10px' }}>
                    <aside
                        style={width[0] > 1023 ? { backgroundColor: `${colors.gray400}`, height: '101%' } : { backgroundColor: `${colors.gray300}` }}
                    >
                        {
                            blocked ? (
                                <InfoContainer status={blocked}>
                                    <h2>
                                        <strong>{currentUser?.email}</strong>
                                        <br /> já assinou.
                                    </h2>
                                </InfoContainer>
                            ) : (
                                <InfoContainer status={blocked}>
                                    <h2>
                                        Assinando como:
                                    </h2>
                                    <strong>{currentUser?.email}</strong>
                                </InfoContainer>
                            )
                        }
                        <form
                            autoComplete="off"
                            onSubmit={(e) => {
                                e.preventDefault()
                            }}
                        >
                            <InputContainer>
                                <Input
                                    style={width[0] < 1023 ? { textAlign: 'center' } : { textAlign: 'start' }}
                                    placeholder="Nome completo"
                                    name="nome_completo"
                                    value={nameUser}
                                    onChange={(e) => {
                                        let data = e.target.value
                                        setNameUser(data)
                                        setNameForSubmit(data)
                                    }}
                                    autoComplete="off"
                                    disabled={signed}
                                />
                                <InputMask
                                    style={width[0] < 1023 ? { textAlign: 'center' } : { textAlign: 'start' }}
                                    value={cpfUser}
                                    placeholder="CPF"
                                    maskChar={false}
                                    disabled={signed}
                                    mask="999.999.999-99"
                                    onChange={(e) => {
                                        let data = e.target.value
                                        setCpfUser(data.replace(/[.]/g, '').replace(/[-]/g, ''))
                                        setCpfForSubmit(data.replace(/[.]/g, '').replace(/[-]/g, ''))
                                    }}
                                />
                                <InputMask
                                    style={width[0] < 1023 ? { textAlign: 'center' } : { textAlign: 'start' }}
                                    mask="99/99/9999"
                                    name="data_nascimento"
                                    placeholder="Data de Nascimento"
                                    maskChar={null}
                                    autoComplete="off"
                                    value={dateUser}
                                    onChange={(e) => {
                                        let data = e.target.value
                                        setDateUser(dateFormat(data))
                                        setDateForSubmit(data)
                                    }}
                                    disabled={signed}
                                />
                            </InputContainer>

                            <div className='buttonsContainer'>
                                <div>
                                    <SafeButton
                                        variant='primary'
                                        loading={loading || buttonBlocked}
                                        type="submit"
                                        blocked={blocked || canceled || dontSign || dontSignForOrd}
                                        blockedMessage={
                                            blocked
                                                ? 'Já assinou'
                                                : canceled
                                                    ? 'Processo cancelado'
                                                    : dontSign
                                                        ? 'Não assina'
                                                        : dontSignForOrd
                                                            ? currentUser?.signed === true 
                                                                ? 'Já assinou'
                                                                : 'Ainda não é sua vez'
                                                                    : ''
                                        }
                                        onClick={() => {
                                            if (nameUser === null || nameUser === undefined || nameUser === '' || dateUser === null || dateUser === undefined || dateUser === '' || cpfUser === null || cpfUser === undefined || cpfUser === '') {
                                                toast.error('Verifique se todos os campos estão preenchidos corretamente', {
                                                    position: toast.POSITION.TOP_RIGHT
                                                })
                                                return
                                            } else if (nameUser.includes(' ') !== true || cpfUser.length !== 11 || dateForSubmit.length !== 10) {
                                                toast.error('Verifique se todos os campos estão preenchidos corretamente', {
                                                    position: toast.POSITION.TOP_RIGHT
                                                })
                                                return
                                            }
                                            setTokenModal(true)
                                            setTypeSign({
                                                rubric: true,
                                                unabbreviated: false
                                            })
                                            setButtonBlocked(true)
                                        }
                                        }
                                    >
                                        Assinar com Rubrica
                                    </SafeButton>
                                </div>
                                <div style={{ paddingLeft: '0.5rem' }}>
                                    <SafeButton
                                        variant='primary'
                                        loading={loading || buttonBlocked}
                                        type="submit"
                                        blocked={blocked || canceled || dontSign || dontSignForOrd}
                                        blockedMessage={
                                            blocked
                                                ? 'Já assinou'
                                                : canceled
                                                    ? 'Processo cancelado'
                                                    : dontSign
                                                        ? 'Não assina'
                                                        : dontSignForOrd
                                                            ? 'Ainda não é sua vez'
                                                            : ''
                                        }
                                        onClick={() => {
                                            if (nameUser === null || nameUser === undefined || nameUser === '' || dateUser === null || dateUser === undefined || dateUser === '' || cpfUser === null || cpfUser === undefined || cpfUser === '') {
                                                toast.error('Verifique se todos os campos estão preenchidos corretamente', {
                                                    position: toast.POSITION.TOP_RIGHT
                                                })
                                                return
                                            } else if (nameUser.includes(' ') !== true || cpfUser.length !== 11 || dateForSubmit.length !== 10) {
                                                toast.error('Verifique se todos os campos estão preenchidos corretamente', {
                                                    position: toast.POSITION.TOP_RIGHT
                                                })
                                                return
                                            }
                                            setTokenModal(true)
                                            setTypeSign({
                                                rubric: false,
                                                unabbreviated: true
                                            })
                                            setButtonBlocked(true)
                                        }
                                        }
                                    >
                                        Assinar por extenso
                                    </SafeButton>
                                </div>
                            </div>
                            <div style={{width:'100%', marginTop:'16px', display:'flex', justifyContent:'center', marginBottom:'16px'}}>
                            <SafeButton variant='secondary' onClick={redirectToPDF}>
                                Abrir documento em nova aba
                            </SafeButton>
                            </div>
                        </form>
                            {allowed === true ? <EventsComponent
                                style={width[0] <= 1023 ? {height:'205px',overflow: 'auto', marginTop:"5%", width:'100%'} : {height:'100%',overflow: 'auto'}}
                                styleAlt={{margin:'0'}}
                                signatarios={processData?.signatories}
                                processId={idProcess}
                            /> : ''}
                    </aside>
                    {width[0] < 1023
                        ? <div
                            style={
                                {
                                    width: '100%',
                                    height: '10rem',
                                    position: 'relative',
                                    zIndex: '5'
                                }
                            }>
                            <PDFNav>
                                <button onClick={minusZoom}>
                                    <RiZoomOutFill />
                                </button>
                                <button onClick={plusZoom}>
                                    <RiZoomInFill />
                                </button>
                                <button type="button" disabled={pageNumber ? pageNumber <= 1 : true} onClick={previousPage}>
                                    <IoIosArrowDropleftCircle />
                                </button>
                                <p>
                                    {pageNumber || (PDF.pages ? 1 : '--')} de {PDF.pages || '--'}
                                </p>
                                <button type="button" disabled={pageNumber ? pageNumber >= PDF.pages : true} onClick={nextPage}>
                                    <IoIosArrowDroprightCircle />
                                </button>
                                <FaFileDownload onClick={saveFile2} />
                            </PDFNav>
                        </div>
                        : ''
                    }
                    <PDFContainer style={{ alignItems: 'center', display: 'flex', width: '95%', marginTop: '10px', overflow: width[0] > 1023 ? 'auto' : 'unset' }}>
                        <Document
                            noData={'Aceite compartilhar a localização para visualizar o pdf ou entre em contato com o suporte'}
                            className='test'
                            file={processData ? processData?.documents[0]?.final_file : ''}
                            onLoadSuccess={onDocumentLoadSuccess}
                            ref={PDFRef}
                            loading={
                                <LoadingContainer>
                                    <GridLoader color={`${colors.primary}`} size={30} />
                                </LoadingContainer>
                            }
                        >
                            <Page 
                                pageNumber={pageNumber ? pageNumber : 1} 
                                scale={PDFZoom} 
                                className='pdfPage'
                            />
                            {width[0] > 1023
                                ? <div style={{
                                    width: '100%',
                                    height: '10rem',
                                    position: 'fixed',
                                    zIndex: '5'
                                }}>
                                    <PDFNav>
                                        <button onClick={minusZoom}>
                                            <RiZoomOutFill />
                                        </button>
                                        <button onClick={plusZoom}>
                                            <RiZoomInFill />
                                        </button>
                                        <button type="button" disabled={pageNumber ? pageNumber <= 1 : true} onClick={previousPage}>
                                            <IoIosArrowDropleftCircle />
                                        </button>
                                        <p>
                                            {pageNumber || (PDF.pages ? 1 : '--')} de {PDF.pages || '--'}
                                        </p>
                                        <button type="button" disabled={pageNumber ? pageNumber >= PDF.pages : true} onClick={nextPage}>
                                            <IoIosArrowDroprightCircle />
                                        </button>
                                        <FaFileDownload onClick={saveFile2} />
                                    </PDFNav>
                                </div>
                                : ''
                            }
                        </Document>
                    </PDFContainer>
                </Container>
                <Modal open={showModal.show} size="xs">
                    <ModalContainer>
                        {
                            showModal.loading ? (
                                <div>
                                    <BounceLoader size={20} color="#60159a" />
                                    <p>Preparando documento...</p>
                                    <BounceLoader size={20} color="#60159a" />
                                </div>
                            )
                                : showModal.expired ? (
                                    <ExpiredContainer>
                                        <strong>LINK EXPIRADO!</strong>
                                        <p>Links enviados por email tem duração de 1 hora a partir do recebimento.</p>
                                        <p>Para que possa concluir o processo, enviamos um novo link para seu e-mail.</p>
                                        <SafeButton
                                            variant='primary'
                                        >
                                            Requisitar Email
                                        </SafeButton>
                                    </ExpiredContainer>
                                )
                                    : canceled || dontSign || dontSignForOrd || blocked
                                        ? (
                                            <>
                                                <p style={{ textAlign: 'center' }}>
                                                    {message}
                                                </p>
                                                {blocked === true
                                                    ? <SafeButton
                                                        onClick={() => {
                                                            setShowModal({ ...showModal, show: false })
                                                        }}
                                                    >
                                                        Ok
                                                    </SafeButton>
                                                    : ''}
                                            </>
                                        )
                                        : (
                                            <>
                                                <p>
                                                    Por questões jurídicas precisamos da sua localização, por favor permita a coleta
                                                    dessa informação.
                                                </p>
                                                <SafeButton
                                                    variant='primary'
                                                    onClick={() => {
                                                        getProcessLocation()
                                                        setShowModal({...showModal, loading:true})
                                                    }}
                                                >
                                                    Permitir
                                                </SafeButton>
                                            </>
                                        )
                        }
                    </ModalContainer>
                </Modal>
                <Modal 
                    open={tokenModal} size="xs"
                >
                    <KeyModalContainer
                        action=""
                    >
                        <KeyModalText >Permite enviarmos a chave de acesso ao seu email?</KeyModalText>
                        <KeyModalBtnContainer>
                            <SafeButton
                                variant='secondary'
                                type="button"
                                onClick={() => {
                                    setTokenModal(false)
                                    setTypeSign({
                                        rubric: false,
                                        unabbreviated: false
                                    })
                                    setButtonBlocked(false)
                                }}
                            >
                                <TiCancel color="#60159a" size={25} />
                            </SafeButton>
                            <SafeButton
                                variant='primary'
                                loading={tokenLoading}
                                onClick={() => {
                                    getFirstToken('enviado')
                                }}
                            >
                                <FiCheck color="#fff" size={20} />
                            </SafeButton>
                        </KeyModalBtnContainer>
                    </KeyModalContainer>
                </Modal>
                <Modal open={signUpModal} size="xs" onClose={() => setSignUpModal(false)}>
                    <SignUpModalContainer>
                        <SignUpDraw src={addFilesSvg} />
                        <SignUpModalText>
                            Seu documento está assinado, aproveite e cadastre-se na SafySign para facilitar seus
                            próximos processos de assinatura.
                        </SignUpModalText>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                <Link to="/new-account" style={{ width: '90%', textDecoration: 'none' }}>
                                    <SafeButton
                                        variant='primary'
                                        style={{ width: '90%' }}
                                    >
                                        Cadastrar
                                    </SafeButton>
                                </Link>
                            </div>
                            <div>
                                <Link to="/" style={{ width: '90%', textDecoration: 'none' }}>
                                    <SafeButton
                                        variant='primary'
                                        style={{ width: '90%' }}
                                    >
                                        Logar
                                    </SafeButton>
                                </Link>
                            </div>
                        </div>
                    </SignUpModalContainer>
                </Modal>
                <Modal open={safariModal} size="xs">
                    <ModalContainer>
                        <p>
                            O Navegador Safari necessita de permissões e conexão via Wi-Fi para utilizar a
                            geolocalização, por favor configure ou utilize outro navegador.
                        </p>
                    </ModalContainer>
                </Modal>
                <Modal 
                    open={confirmSignModalUnabbreviated}
                >
                    <SignModal>
                        <div style={{ fontSize: '2rem', textAlign: 'center' }} >
                            <p>Você já possui assinatura cadastrada, confirma assinatura do documento?</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }} className='buttonsContainer'>
                            <div>
                                <SafeButton
                                    variant='secondary'
                                    loading={sendSignatureLoading}
                                    onClick={() => {
                                        setConfirmSignModalUnabbreviated(false)
                                        setButtonBlocked(false)

                                        if (typeSign?.rubric === true) {
                                            setIsRefused(true)
                                            setShowSignModal(true)
                                        } else {
                                            setIsRefused(true)
                                            setConfirmSignFontModal(true)
                                        }
                                    }}
                                >
                                    <MdClose color={colors.primary} size={20} />
                                </SafeButton>
                            </div>
                            <div style={{ paddingLeft: '0.5rem' }}>
                                <SafeButton
                                    variant='primary'
                                    loading={sendSignatureLoading}
                                    onClick={() => {
                                        setSendSignatureLoading(true)
                                        if (typeSign.rubric === true) {
                                            formatRubricForJpg()
                                        } else {
                                            submit()
                                        }
                                    }}
                                >
                                    <FiCheck color="#fff" size={20} />
                                </SafeButton>
                            </div>
                        </div>
                    </SignModal>
                </Modal>
                <Modal 
                    open={openTokenModal}
                >
                    <KeyModalContainer
                        onSubmit={(e) => {
                            e.preventDefault()
                        }}
                        action=""
                    >
                        <KeyModalTitle>Atenção!</KeyModalTitle>
                        <KeyModalText>Insira a chave enviada no seu email, para assinar o processo</KeyModalText>
                        <KeyModalInputContainer>
                            <InputMask
                                value={emailToken}
                                maskChar={false}
                                mask="***-***-***"
                                onChange={(e) => {

                                    let data = e.target.value

                                    if (data === null || data === undefined || data === '') {
                                        toast.error('Preencha o campo, o token não pode estar vazio', {
                                            position: toast.POSITION.TOP_RIGHT
                                        })
                                        return
                                    }

                                    setEmailToken(emailToken => data.toUpperCase())
                                }}
                            />
                        </KeyModalInputContainer>
                        <KeyModalBtnContainer>
                            <SafeButton
                                loading={tokenVerifyLoading}
                                variant='secondary'
                                blocked={timeForReSend ? timeForReSend : false}
                                blockedColor={colors.primary}
                                onClick={(e) => {
                                    getFirstToken('reenviado')
                                    setTimeForReSend(true)
                                    setTimeout(() => { setTimeForReSend(false) }, 5000)
                                }}
                            >
                                <AiOutlineReload color="#60159a" size={20} />
                            </SafeButton>
                            <SafeButton
                                loading={tokenVerifyLoading}
                                variant='primary'
                                onClick={() => {
                                    tokenVerify()
                                    setTokenVerifyLoading(true)
                                }}
                            >
                                <FiCheck color="#fff" size={20} />
                            </SafeButton>
                        </KeyModalBtnContainer>
                    </KeyModalContainer>
                </Modal>
                <Modal open={showSignModal} onClose={closeSignModal}>
                    <SignModal>
                        <h4 style={{ textAlign: 'center' }}>Desenhe sua assinatura</h4>
                        <SignCanvas ref={canvasRef} />
                        <div
                            className={'buttonsContainer'}
                        >
                            <SafeButton
                                variant='secondary'
                                onClick={() => cleanSign(canvasRef.current)}
                            >
                                Limpar
                            </SafeButton>
                            <SafeButton
                                variant='primary'
                                loading={loadingModal}
                                onClick={() => { getBase64(); setLoadingModal(true) }}
                            >
                                Enviar assinatura
                            </SafeButton>
                        </div>
                    </SignModal>
                </Modal>
                <Modal 
                    open={confirmSignFontModal}
                    onClose={() => {
                        setConfirmSignFontModal(false)
                        setLoading(false)
                        setButtonBlocked(false)
                    }}
                >
                    <ModalContainerFont>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault()
                                if (chosenFont === '' || chosenFont === null || chosenFont === undefined) {
                                    toast.error('Selecione ao minimo uma fonte', {
                                        position: toast.POSITION.TOP_RIGHT
                                    })
                                    return
                                }
                                if (e.target[0].value === "" || e.target[0].value === null || e.target[0].value === undefined) {
                                    toast.error('Digite seu nome desejado', {
                                        position: toast.POSITION.TOP_RIGHT
                                    })
                                    return
                                }
                                setLoadingModal(true)
                                submit({
                                    text: e.target[0].value,
                                    font: chosenFont
                                })
                            }}
                        >
                            <LabelContainer >
                                <label htmlFor="nome_completo_fonte">Digite o nome desejado:</label>
                                <Input
                                    style={{ width: '100%' }}
                                    type="text"
                                    minLength={2}
                                    required
                                    id="nome_completo_fonte"
                                    name="text"
                                    className="button"
                                />
                            </LabelContainer>

                            <div className="button">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        paddingTop: '1rem',
                                        paddingBottom: '1rem'
                                    }}
                                >
                                    <h5>Escolha a font da letra:</h5>
                                </div>
                                {Fonts.map(f => (
                                    <Font key={f.value} font={f.name} style={backgroundColorButton === f.name ? { backgroundColor: `${colors.primary}` } : { backgroundColor: `${colors.gray200}` }} type="button" onClick={() => { setChosenFont(f.value); setBackgroundColorButton(f.name) }}>
                                        <p>{f.label}</p>
                                    </Font>
                                ))}
                            </div>

                            <div className="buttonsContainer">
                                <SafeButton
                                    variant='primary'
                                    loading={loadingModal}
                                    type="button"
                                    onClick={() => { setConfirmSignFontModal(false) }}
                                >
                                    Cancelar
                                </SafeButton>
                                <SafeButton
                                    variant='primary'
                                    loading={loadingModal}
                                    type="submit"
                                >
                                    Confirmar
                                </SafeButton>
                            </div>
                        </form>
                    </ModalContainerFont>
                </Modal>
            </Screen>
        </div>
    )
}