import { Auth } from '../../layouts/auth';
import { useAuth } from './../../hooks/useAuth';
import Plan from './../../components/Plan/index';
import useWindowSize2 from './../../hooks/useWindowSize2';

export default function SelectPlan() {

    const {logout} = useAuth()
    const width = useWindowSize2()

    return (
        <Auth>
          <div 
            style={{display:'flex', justifyContent:'center', width:`${width[0]/1.5737}px`}}
          >
            <Plan
              mode={true}
              test={() => {logout()}}
            />
          </div>
        </Auth>
    )
}