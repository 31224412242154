/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react'
import Mask from 'react-input-mask'
import { Input, InputNormal } from './styles'

const InputMask = React.forwardRef((props, ref) => {
  const { mask, maskChar, alwaysShowMask, defaultValue, disabled, onChange, onBlur, onFocus, value, minLength } = props

  return (
    <Mask
      mask={mask}
      maskChar={maskChar}
      alwaysShowMask={alwaysShowMask}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value}
      minLength={`${minLength}`}
    >
      {() => <Input type="text" ref={ref} {...props}/>}
    </Mask>
  )
})

export default InputMask

export const InputMaskNormal = React.forwardRef((props, ref) => {
  const { mask, maskChar, alwaysShowMask, defaultValue, disabled, onChange, onFocus, value } = props

  return (
    <Mask
      style={props.style}
      mask={mask}
      maskChar={maskChar}
      alwaysShowMask={alwaysShowMask}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={onChange}
      onFocus={onFocus}
      value={value}
    >
      {() => <InputNormal type="text" ref={ref} {...props} />}
    </Mask>
  )
})