/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from '@mui/material'
import { toast } from 'react-toastify'
import SafeButton from '../SafeButton'
import Card from '../../components/Card'
import { MdCheck } from 'react-icons/md'
import * as api from '../../services/api'
import { Pagination } from '@mui/material'
import { GridLoader } from 'react-spinners'
import { useAuth } from './../../hooks/useAuth'
import React, { useEffect, useState } from 'react'
import { currencyFormatter } from '../../utils/INTL'
import useWindowSize2 from './../../hooks/useWindowSize2'
import { Container, PlanoColuna, PlanoPlaceholder, Title, ModalContainer } from './styles'
import { useNavigate } from 'react-router-dom';
import { cookies } from './../../hooks/useCookies';

export default function Plan(props) {

    const [page, ] = useState(1)
    const [planos, setPlanos] = useState([])
    const [response, setResponse] = useState()
    const [loading, setLoading] = useState(false)
    const [placeholder, setPlaceholder] = useState(true)
    const [currentPlan ,setCurrentPlan] = useState()
    const [confirmChangeModal, setConfirmChangeModal] = useState(false)

    const { user, setUser } = useAuth()
    const navigate = useNavigate()
    const width = useWindowSize2()

    let chroma = 0

    function getCurrentPage(currentPage) {

        let date = `${user.company !== null ? "&category=2" : "&category=1" }`

        api
            .get(`/plan/list/?visibility=1&page=${currentPage}${date}`)
            .then((response) => {
                if (response?.status === 200) {
                    setPlanos(response?.data.detail.results)
                    setResponse(response.data.detail.count)
                    setPlaceholder(false)
                } else {
                    toast.error('Não foi possivel resgatar a listagem de planos, tente novamente mais tarde', {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            })
            .catch((error) => {
                toast.error('Não foi possivel resgatar a listagem de planos, tente novamente mais tarde', {
                    position: toast.POSITION.TOP_RIGHT
                })
            })
    }

    useEffect(() => {
        getCurrentPage(page)
    }, [])

    function closeConfirmChangeModal() {
        setConfirmChangeModal(false)
    }

    return (
        <Card>
            {
                props.mode === true
                    ? <SafeButton
                        style={{height:'50px', marginBottom:'20px'}}
                        type="button"
                        onClick={() => {
                            props?.test()
                        }}
                        variant="secondary"
                    >
                        Voltar
                    </SafeButton>
                    : ''
        }
            {
                props.mode !== true
                    ? <Title>
                        <p>Selecione o plano</p>
                        <SafeButton
                            onClick={() => {
                                props?.mode === true
                                    ? props?.test()
                                    : navigate('/profile', { replace: true })
                            }}
                            variant='primary'
                        >
                            Voltar
                        </SafeButton>
                    </Title>
                    : ''
            }
            <Container>
                {planos.map(plano => {
                    if (chroma > 3) {
                        chroma = 0
                    } else {
                        chroma++
                    }

                    return (
                        <PlanoColuna
                            style={
                                props.mode === true
                                    ? { margin: '0', width: '96%', marginTop: '1rem'}
                                    : { width: '96%' }
                            }
                            key={plano?.id}
                            chroma={chroma}
                        >
                            <div className='title'>
                                <h2>{plano?.name}</h2>
                            </div>
                            <ul style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <li style={{ fontSize: '1.7rem', width: 'max-content', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                    <MdCheck size={20} />{' '}
                                    {
                                        plano?.users > 9999
                                            ? '+9999 usuários simultâneos.'
                                            : plano?.users + ' usuários simultâneos.'
                                    }
                                </li>
                                {
                                    plano?.whatsapp_amount > 0 
                                        ? <li style={{ fontSize: '1.7rem' }}>
                                            <MdCheck size={20} />{' '}
                                            {
                                                plano?.whatsapp_amount + ' Envios de WhatsApp.'
                                            }
                                        </li>
                                        : ''
                                }
                                {
                                    plano?.email_amount > 0
                                        ? <li style={{ fontSize: '1.7rem' }}>
                                            <MdCheck size={20} />{' '}
                                            {
                                                plano?.email_amount + ' Envios de email.'
                                            }
                                        </li>
                                        : ''
                                }
                                {plano?.sms_amount > 0
                                    ? <li style={{ fontSize: '1.7rem' }}>
                                        <MdCheck size={20} />{' '}
                                        {
                                            plano?.sms_amount + ' Envios de SMS.'
                                        }
                                    </li>
                                    : ''
                                }
                                <li style={{ fontSize: '1.7rem' }}>
                                    <MdCheck size={20} />{' '}
                                    {
                                        plano?.processes + ' documentos.'
                                    }
                                </li>
                            </ul>
                            <p>{plano?.description}</p>
                            <h3>
                                {
                                    plano.price === '0.0' || plano.price === '00'|| plano.price === '0' || plano.price === '0.00'
                                    ? 'Grátis'
                                    : currencyFormatter(plano?.price)}{
                                        plano.price === '0.0' || plano.price === '00'|| plano.price === '0' || plano.price === '0.00'
                                        ? ''
                                        : plano?.plan_type === '1'
                                            ? '/mês' 
                                            : '/ano'
                                }
                            </h3>
                            <button
                                onClick={() => {
                                        setConfirmChangeModal(true)
                                        setCurrentPlan(plano)
                                }}
                                style={
                                    {
                                        background: user?.plan === plano?.id && '#bbb',
                                        cursor: user?.plan === plano?.id && 'no-drop'
                                    }}
                                disabled={user?.plan === plano?.id}
                            >
                                {user?.plan === plano?.id ? 'Já aplicado' : 'Selecionar'}
                            </button>
                        </PlanoColuna>
                    )
                })}
                <PlanoPlaceholder visible={placeholder}>
                    <GridLoader color="#aaa" />
                </PlanoPlaceholder>
                <PlanoPlaceholder visible={placeholder}>
                    <GridLoader color="#aaa" />
                </PlanoPlaceholder>
                {
                    width[0] > 1024
                        ? <>
                            <PlanoPlaceholder visible={placeholder}>
                                <GridLoader color="#aaa" />
                            </PlanoPlaceholder>
                            <PlanoPlaceholder visible={placeholder}>
                                <GridLoader color="#aaa" />
                            </PlanoPlaceholder>
                            <PlanoPlaceholder visible={placeholder}>
                                <GridLoader color="#aaa" />
                            </PlanoPlaceholder>
                            <PlanoPlaceholder visible={placeholder}>
                                <GridLoader color="#aaa" />
                            </PlanoPlaceholder>
                        </>
                        : ''
                }
            </Container>
            <div style={{marginTop:'20px'}} className="pageCount">
                <Pagination count={Math.ceil(response/10)} variant="outlined" shape="rounded" onChange={(e, value) => getCurrentPage(value)}/>
            </div>

            <Modal open={confirmChangeModal} onClose={() => {setConfirmChangeModal(false); setCurrentPlan()}}>
                <ModalContainer>
                    <div style={{ fontSize: '2rem' }}>
                        <p style={{textAlign:'center'}}>{user?.plan === null ? 'Confirma a adesão deste plano?' : 'Essa ação irá mudar seu plano atual, para o escolhido. Deseja realmente fazer isso?'}</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '1rem', justifyContent:'center'}}>
                        <div>
                            <SafeButton loading={loading} variant='secondary' onClick={closeConfirmChangeModal}>
                                Cancelar
                            </SafeButton>
                        </div>
                        <div style={{ paddingLeft: '0.5rem' }}>
                            <SafeButton
                                variant='primary'
                                loading={loading}
                                onClick={() => {
                                    setLoading(true)
                                    if(currentPlan?.price === '0.0') {
                                        api
                                            .put(`/user/change/plan/${user.id}/`,{plan: currentPlan.id})
                                                .then((response) => {
                                                    if (response.status === 200){
                                                        setLoading(false)
                                                        setUser({...user, plan:currentPlan.id})
                                                        navigate('/', {replace:true})
                                                        toast.success(response.data.detail,{position : toast.POSITION.TOP_RIGHT})
                                                    } else {
                                                        setLoading(false)
                                                        toast.error('Ocorreu um erro ao alterar o plano')
                                                    }
                                                })
                                                .catch((error) => {
                                                    toast.error('Ocorreu um erro ao alterar o plano')
                                                })
                                    } else {
                                        api
                                            .put(`/user/change/plan/${user.id}/`,{plan: currentPlan.id})
                                                .then((response) => {
                                                    if (response.status === 200){
                                                        setLoading(false)
                                                        let data = cookies.get("@Safysign:user")
                                                        navigate('/', {replace:true})
                                                        setUser({...user, plan:currentPlan?.id})
                                                        data = {...data, plan:currentPlan?.id}
                                                        cookies.set("@Safysign:user", JSON.stringify(data))
                                                        toast.success(response.data.detail,{position : toast.POSITION.TOP_RIGHT})
                                                        if(currentPlan.price !== '0.0' && currentPlan.price !== '00' && currentPlan.price !== '0' && currentPlan.price !== '0.00'){
                                                            cookies.set("@Safysign:bonusPlan", true);
                                                        }
                                                    } else {
                                                        setLoading(false)
                                                        toast.error('Ocorreu um erro ao alterar o plano')
                                                    }
                                                })
                                                .catch((error) => {
                                                    toast.error('Ocorreu um erro ao alterar o plano')
                                                })
                                    }
                                }}
                            >
                                Confirmar
                            </SafeButton>
                        </div>
                    </div>
                </ModalContainer>
            </Modal>
        </Card>
    )
}