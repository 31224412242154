import styled from "styled-components";
import { colors } from "../../styles/colors";

export const ContainerTerm = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 10px;
  padding: 16px;
  gap: 5px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 700px;

  @media (max-width: 1000px) {
    width: 90%;
  }
`;

export const Alinea = styled.p`
  margin: 1rem 0rem;
  font-size: 1.8rem;
  width: 100%;
`;

export const Paragrafo = styled.p`
  margin: 3rem 0;
  font-size: 1.8rem;
  width: 100%;
  word-wrap: break-word;
`;

export const TermoContainer = styled.div`
  width: 100%;
  height: 680px;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  overflow-y: auto;
  align-items: center;

  &::-webkit-scrollbar {
    width: 10px;
    position: absolute;
    border-radius: 80px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.primary};
    border-radius: 80px;
    width: 10px;
  }

  & > h2 {
    text-decoration: none;
    text-align: center;
    color: ${colors.primary};
  }

  & > h3 {
    font-size: 1.8rem;
    width: 100%;
    padding-left: 4rem;
    color: ${colors.primary};
  }

  .term {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 2rem;
    color: ${colors.primary};
    width: 100%;
    border-radius: 10px;
    gap: 10px;
    background-color: ${colors.gray400};
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
  }
`;
