import { useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as api from "../../../services/api";
import Input from "../../../components/Input";
import { Link, useNavigate } from "react-router-dom";
import { cookies } from "./../../../hooks/useCookies";
import SafeButton from "../../../components/SafeButton";
import iconLogin from "../../../assets/images/icons/login.png";

export default function Forgot() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const { handleChange, handleSubmit, values } = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: submit,
  });

  function forgotPass() {
    api
      .post("/user/password_reset/", values, "application/json")
      .then((response) => {
        toast.dismiss();
        if (response.status === 200) {
          navigate("../", { replace: true });
          cookies.set("@Safysign:verify-forgot-pass-toast", "true");
        } else if (response.response.status === 400) {
          toast.error("Favor inserir um e-mail válido", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error("Tente novamente mais tarde", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }

  async function submit() {
    setLoading(true)
    forgotPass()
    setTimeout(() => {setLoading(false)},1000)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="container-input">
        <img src={iconLogin} alt="Login icon" />
        <Input
          type="text"
          placeholder="Login"
          name="email"
          required
          onChange={handleChange}
          value={values.username}
        />
      </div>
      <div className="container-button">
        <Link to="/" className="link-secondary">
          Fazer login
        </Link>
        <SafeButton
          loading={loading}
          disabled={loading}
          type="submit"
          variant="primary"
        >
          Enviar
        </SafeButton>
      </div>
    </form>
  );
}
