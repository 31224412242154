import styled from "styled-components"
import { colors } from "../../styles/colors"

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 85vh;

  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
`;

export const MenuSignature = styled.aside`
  width: 340px;
  background-color: ${colors.gray300};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-right: 1rem;
  margin-right: 0.5rem;
  overflow-y: auto;
  border-radius: 16px;

  & > div {
    width: 100%;

    & > button {
      width: 100%;
      margin-top: 12px;
    }
  }
`;

export const InfoContainer = styled.div`
  width: 100%;
  background: ${colors.white};
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PDFContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    width: 100%;
  }
`;

export const PDFNav = styled.div`
  width: 100%;
  height: 60px;
  padding: 1rem;
  background: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 8px;

  & > button {
    background-color: ${colors.gray200};
    height: 30px;
    border-radius: 50%;
    margin: 0 20px;

    & > svg {
      font-size: 30px;
      color: ${colors.primary};
    }
  }
`;

export const Signatory = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${colors.gray300};
  border-radius: 8px;
  background-color: ${colors.gray200};
  padding: 0 5px;
  border-left: 5px solid ${colors.primary};
  gap: 5px;
  margin-top: 12px;
  word-break: break-all;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    svg {
      color: ${colors.primary};
    }
  }

  & > div + div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    & > strong {
      font-size: 1.5rem;
      font-weight: 800;
    }
  }
`;
