import React from 'react'

import { InputStyles, Container } from './styles'

const Input = React.forwardRef((props, ref) => {
  return (
    <Container isErrored={!!props?.error?.message}>
      <InputStyles pattern={props.pattern} style={props.style} ref={ref} {...props} onChange={props.onChange}/>
      <span className="error">{props?.error?.message}</span>
    </Container>
  )
})

export default Input