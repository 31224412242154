import { colors } from "../../styles/colors";
import styled, { css } from "styled-components";

export const SignatoryStyle = styled.li`
  background: ${colors.gray200};
  border: 2px solid ${colors.secondary};
  padding: 1rem;
  border-radius: 16px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98%;
  margin-left: 1%;
  margin-top: 10px !important;
  margin: auto;
  cursor: grab;

  & > div {
    color: ${colors.primary};
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
      margin-right: 0.5rem;
    }
  }

  & > svg {
    color: ${colors.primary};
    font-size: 2rem;
    transition: 0.4s;
  }

  & > svg:hover {
    color: ${colors.secondary};
    cursor: pointer;
  }

  ${(props) =>
    props.isDragging &&
    css`
      border: 2px dashed ${colors.primary};
      background: transparent;
      cursor: grabbing;

      & > div,
      strong,
      svg {
        opacity: 0;
      }
    `}

  @media(max-width: 800px) {
    & > div {
      width: 100%;
      flex-wrap: wrap;
      height: auto;

      & > p {
        overflow: hidden;
        text-overflow: ellipsis;
        direction: ltr;
      }
      & > svg {
        opacity: 1;
      }
    }
  }
`;
