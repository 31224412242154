import { BarLoader } from 'react-spinners'
import { ImBlocked } from 'react-icons/im'
import { colors } from '../../styles/colors'
import { PrimaryButton, SecondaryButton, TertiaryButton } from './styles'

export default function SafeButton(props) {
  return (
    <>
      {
        props.variant === "primary"
          ? <PrimaryButton 
              invisible={props.invisible} 
              disabled={props.loading || props.blocked} 
              {...props}
            >
              {
                props.loading 
                  ? <BarLoader height={6} width={80} color={colors.secondary} />
                  : props.blocked 
                    ? props.blockedMessage || <ImBlocked size={20} color={props.blockedColor ? props.blockedColor : colors.white} />
                    : props.children
              }
            </PrimaryButton>
          : props.variant === "secondary"
            ? <SecondaryButton 
                invisible={props.invisible} 
                disabled={props.loading || props.blocked} 
                {...props}
              >
                {
                  props.loading 
                    ? <BarLoader height={6} width={80} color={colors.secondary} />
                    : props.blocked 
                      ? props.blockedMessage || <ImBlocked size={20} color={colors.white} />
                      : props.children
                }
              </SecondaryButton>
            : props.variant === "tertiary" 
                ? <TertiaryButton
                invisible={props.invisible} 
                disabled={props.loading || props.blocked} 
                {...props}
                >
                  {
                    props.loading 
                      ? <BarLoader height={6} width={80} color={colors.secondary} />
                      : props.blocked 
                        ? props.blockedMessage || <ImBlocked size={20} color={colors.white} />
                        : props.children
                  }
                </TertiaryButton>
                : <PrimaryButton 
                    invisible={props.invisible} 
                    disabled={props.loading || props.blocked} 
                    {...props}
                  >
                    {
                      props.loading 
                        ? <BarLoader height={6} width={80} color={colors.primary} />
                        : props.blocked 
                          ? props.blockedMessage || <ImBlocked size={20} color={colors.white} />
                          : props.children
                    }
                  </PrimaryButton>
      }
    </>
  )
}