import React from 'react'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import logoImg from '../../assets/images/SAFYSIGN-LOGO-PNG.png'

import {
  SignContainer,
  SignInPanel,
  SignFormContainer,
  BorderOffset,
  SingPanelContainer
} from './styles'

export function Auth({children}) {
  return (
    <SignContainer>
      <SingPanelContainer>
        <SignInPanel>
          <ToastContainer />
          <img src={logoImg} alt="SafySign logo" className="logo" />
          <SignFormContainer>
            <Outlet />
            {children ? children : <></>}
          </SignFormContainer>
        </SignInPanel>
        <BorderOffset />
      </SingPanelContainer>
    </SignContainer>
  )
}
