import styled from 'styled-components'
import { colors } from '../../styles/colors'

export const Container = styled.div`
  max-width: calc(100vw - 294px);
  width: 100%;
  height: max-content;
  background: ${colors.white};
  padding: 1rem;
  margin: 2rem 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  h1 {
    color: ${colors.white};
    width: 100%;
    text-align: center;
    background: ${colors.primary};
  }

  @media (max-width: 700px) {
    max-width: calc(100vw);
  }
`
