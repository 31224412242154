import { SelectPicker } from 'rsuite'
import { SignatoryStyle } from './styles'
import { RiUserFill } from 'react-icons/ri'
import { useDrag, useDrop } from 'react-dnd'
import { colors } from '../../styles/colors'
import { MdDeleteForever } from 'react-icons/md'
import React, { useRef, useContext } from 'react'
import SignatoryContext from '../../pages/Dashboard/context'

export const selectPickerData = [
  {
    label: 'Signatario',
    value: 1
  },
  {
    label: 'Parte',
    value: 2
  },
  {
    label: 'Contraparte',
    value: 3
  },
  {
    label: 'Testemunha',
    value: 4
  },
  {
    label: 'Avalista',
    value: 5
  },
  {
    label: 'Interveniente',
    value: 6
  },
  {
    label: 'Endossante',
    value: 7
  },
  {
    label: 'Fiador',
    value: 8
  },
  {
    label: 'Solidário',
    value: 9
  },
  {
    label: 'Fiel depositário',
    value: 10
  }
]

export default function Signatory({ position, children, selectPaper }) {
  const ref = useRef()
  const { moveSignatory, removeSignatory } = useContext(SignatoryContext)

  const [{ isDragging }, dragRef] = useDrag({
    item: {type: 'signatories', position},
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const [, dropRef] = useDrop({
    accept: 'signatories',
    drop(item) {
      const draggedPosition = item.position
      const targetPosition = position

      if (draggedPosition === targetPosition) return null

      moveSignatory(draggedPosition, targetPosition)

      item.position = targetPosition
    }
  })

  dragRef(dropRef(ref))

  return (
    <SignatoryStyle ref={ref} isDragging={isDragging}>
      <strong style={{ color: `${colors.primary}` }}>{position + 1 + '°'}</strong>
      <div>
        <RiUserFill color={`${colors.primary}`}/>
        <SelectPicker
          searchable={false}
          data={selectPickerData}
          appearance="subtle"
          defaultValue={1}
          cleanable={false}
          menuStyle={{ minWidth: '150px' }}
          onSelect={value => selectPaper(position, value)}
        />
        <p>{children}</p>
      </div>
      <MdDeleteForever onClick={() => removeSignatory(position)} />
    </SignatoryStyle>
  )
}
