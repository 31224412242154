import styled, { keyframes } from 'styled-components'
import { colors } from '../../styles/colors'

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1}
`

export const Container = styled.div`
  display:flex;
  background-color: ${colors.gray300};
  margin-top: 15px!important;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  transform: translate(-50% -50%);
  padding-left: 10px;
  padding-right: 10px;
  gap: 5px;

  p {
    font-weight: 700;
  }

  .buttonsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    gap: 15px;
  }

  #plano_ilimitado_usuarios, #plano_ilimitado_documentos{
    display: none;
  }

  input[type="radio"], input[type="radio"] + label  {
    display: none;
  }
`

export const ErrorMessage = styled.p`
  color: red;
  font-size: 1.5;
`

export const InputContainer = styled.div`
  text-align: start;
  width: 660px;

  &:not(:first-child){
    width: 210px;
  }

  Input {
  background: #ececec;
  border-radius: 8px;
  height: 40px;
  padding: 0 10px;
  border: none;
  width: 100%;
  }
`
export const RadioContainer = styled.div`
  text-align: start;
  display: flex;
  align-items: center;
  gap: 5px;

`

export const Form = styled.form`
  width: 100%;
  align-items: center;

  width: 100%;
  max-width: 660px;
  margin: 0 auto;

`

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 15px;
`

export const UserList = styled.ul`
  display: ${props => (props.invisible ? 'none' : 'flex')};
  background: #fff;
  padding: 2rem;
  margin: 2rem;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  animation: ${fadeIn} linear 0.2s;
  border: 2px solid #3DACE0;

  li {
    height: 3rem;
    display: flex;
    justify-content: left;
    align-items: center;
  }

  & > li > input {
    margin: 0.5rem;
  }
`

export const InputText = styled.input`
 background: #ececec;
  border-radius: 8px;
  height: 80px;
  padding: 0 10px;
  border: none;
  width: 100%;
  text-align: center;
  margin-top: 15px;
`

export const Title = styled.ul`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: ${colors.gray200};
  padding-left: 10px;
  padding-right: 10px;

  a{
    text-decoration: none;
  }

  p{
    font-size: 1.8rem;
    font-weight: 900;
    color: ${colors.primary};

    span{
    color: ${colors.darker};
    }

    @media (max-width:450px){
      font-size: 18px;
    }
  }
`