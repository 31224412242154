import React from "react"
import { TermoContainer, Alinea, Paragrafo, ContainerTerm } from "./styles"

export default function Term({ props }) {
  return (
    <ContainerTerm>
      <TermoContainer>
        <h2>TERMO E CONDIÇÕES DOS SERVIÇOS SAFY SIGN</h2>
        <Paragrafo>
          Estes Termos e Condições dos Serviços SAFY SIGN (&quot;Termos&quot;)
          regem o acesso e a utilização dos websites e serviços da SAFY SIGN
          (&quot;SAFY SIGN&quot;, &quot;nós&quot; ou &quot;nos&quot;)
          (coletivamente, o &quot;Site&quot;) pelos visitantes do site
          (&quot;Visitantes do Site&quot;) e pessoas ou entidades que adquirem
          serviços (&quot;Serviços SAFY SIGN&quot;) ou criam uma conta
          (&quot;Conta&quot;) e seus Usuários Autorizados (coletivamente,
          &quot;Clientes&quot;). Ao utilizar o Site ou quaisquer Serviços SAFY
          SIGN, você, como Visitante do Site ou Cliente, aceita estes Termos
          (por si mesmo ou em nome de uma entidade jurídica que você
          represente). Um &quot;Usuário Autorizado&quot; de um Cliente é, cada
          um, uma pessoa física, quer seja um funcionário, parceiro comercial,
          subcontratado ou agente de um Cliente, que está registrada ou
          autorizada pelo Cliente para usar os Serviços SAFY SIGN de acordo com
          estes Termos, até o número máximo de usuários ou de utilizações
          especificado no momento da compra. Clientes e Visitantes do Site podem
          ser designados como &quot;você&quot; e &quot;seu&quot; nestes Termos,
          conforme aplicável
        </Paragrafo>
        <Paragrafo>
          Se você for um Cliente e você ou sua empresa/organização/entidade
          estiver vinculada a um Contrato Principal de Serviços com a SAFY SIGN
          (&quot;Termos Corporativos&quot;), então esses Termos se aplicarão, se
          for o caso, apenas ao uso do Site ou de quaisquer Serviços SAFY SIGN
          na medida que tal uso já não seja regido pelo Contrato Principal de
          Serviços. Para evitar dúvidas, todas as referências ao
          &quot;Site&quot; nestes Termos também incluem os Serviços SAFY SIGN.
        </Paragrafo>
        <Paragrafo>
          AO ACESSAR, USAR OU FAZER DOWNLOAD DE QUAISQUER MATERIAIS DO SITE,
          VOCÊ CONCORDA EM CUMPRIR E SE VINCULAR A ESTES TERMOS. SE VOCÊ NÃO
          CONCORDAR COM ESTES TERMOS, VOCÊ NÃO ESTARÁ AUTORIZADO E DEVERÁ
          ENCERRAR O USO DO SITE IMEDIATAMENTE, SOB PENA DA ADOÇÃO DE MEDIDAS
          JUDICIAIS.
        </Paragrafo>
        <h3>1. ATUALIZAÇÕES E COMUNICAÇÕES</h3>
        <Paragrafo>
          1.1 Nós podemos, de tempos em tempos, revisar estes Termos ou termos e
          condições adicionais que sejam relevantes a um Serviço SAFY SIGN
          específico para refletir mudanças na lei ou nos Serviços SAFY SIGN.
          Nós publicaremos os termos revisados no Site com a data da última
          atualização. POR FAVOR, CONSULTE O SITE REGULARMENTE PARA OBTER
          TEMPESTIVAMENTE NOTIFICAÇÃO DE QUAISQUER REVISÕES. SE VOCÊ CONTINUAR A
          UTILIZAR OS SERVIÇOS SAFY SIGN APÓS AS REVISÕES ENTRAREM EM VIGOR,
          VOCÊ CONCORDA EM ESTAR VINCULADO AOS TERMOS REVISADOS. Você concorda
          que não seremos responsáveis perante você ou terceiros por quaisquer
          modificações dos Termos
        </Paragrafo>
        <Paragrafo>
          1.2 Você concorda em receber todas as comunicações, contratos e
          notificações fornecidos por nós relacionados a quaisquer Serviços SAFY
          SIGN (&quot;Comunicações&quot;), incluindo, mas não se limitado a,
          Comunicações relacionadas a nossa entrega dos Serviços SAFY SIGN e a
          sua compra de ou assinatura dos Serviços SAFY SIGN, por meios
          eletrônicos, incluindo e-mail, mensagem de texto e notificações em
          aplicativo, ou por meio de publicação no Site ou através de quaisquer
          Serviços SAFY SIGN. Você concorda que todas as Comunicações que
          enviarmos eletronicamente a você satisfazem a quaisquer requisitos
          legais que determinam que as Comunicações devem ser escritas ou
          entregues em formato específico, e concorda em manter suas informações
          de contato da Conta atualizadas.
        </Paragrafo>
        <Paragrafo>
          <strong>AVISO IMPORTANTE:</strong> ESTES TERMOS E CONDIÇÕES CONTÊM UMA
          DISPOSIÇÃO DE ARBITRAGEM VINCULANTE E RENÚNCIA A AÇÕES COLETIVAS EM
          RELAÇÃO A LITÍGIOS ADVINDOS DA UTILIZAÇÃO DOS SERVIÇOS SAFY SIGN. ISSO
          AFETA SEUS DIREITOS, CONFORME DETALHADO NA CLÁUSULA DE RENÚNCIA DE
          AÇÃO COLETIVA ABAIXO. POR FAVOR, LEIA COM ATENÇÃO.
        </Paragrafo>
        <h3>2. TERMOS ADICIONAIS PARA SERVIÇOS SAFY SIGN</h3>
        <Paragrafo>
          2.1 Assinatura SAFY SIGN. Se você usar o Assinatura SAFY SIGN, você
          aceita os Termos do Anexo de Assinatura SAFY SIGN <u>abaixo.</u>
        </Paragrafo>
        <Paragrafo>
          2.2 Salas SAFY SIGN. Se você usar os cofres SAFY SIGN, você aceita os
          Termos do Anexo de Salas de Transação SAFY SIGN <u>abaixo.</u>
        </Paragrafo>
        <h3>3. DIREITOS DE USO E ACESSO</h3>
        <Paragrafo>
          3.1 Elegibilidade para Usar. Você declara e garante que: (a) você é
          maior de idade (18 anos de idade ou mais, ou a maioridade na
          jurisdição em que você reside) e é capaz de concordar com estes
          Termos; e (b) você (ou seus Usuários Autorizados, conforme aplicável)
          não está e não estará quando da utilização do Site, localizado em, sob
          controle de, cidadão de ou residente em um país ou território sob
          embargo do Brasil, nem é um usuário final proibido sob as Leis de
          Controle de Exportação (conforme definido na Cláusula 12.3). Você
          reconhece que não está autorizado a usar o Site se não estiver apto a
          fazer essas declarações. Se a SAFY SIGN o tiver proibido anteriormente
          de acessar o Site ou de usar os Serviços SAFY SIGN, você não está
          autorizado a acessar o Site ou usar os Serviços SAFY SIGN. Se você
          está concordando com estes Termos em nome de uma
          empresa/organização/entidade ou entidade, você declara e garante que
          está autorizado a concordar com estes Termos em nome da
          empresa/organização/entidade ou entidade e a vinculá-las a estes
          Termos (nesse caso, as referências a &quot;você&quot; e
          &quot;seu&quot; nestes Termos, exceto por esta frase, se referem à
          empresa/organização/entidade ou entidade).
        </Paragrafo>
        <Paragrafo>
          3.2 Licença Limitada. Mediante sua aceitação destes Termos, nós
          concedemos a você uma licença limitada, não-exclusiva e intransferível
          para acessar e usar o Site para seus fins comerciais internos e
          somente conforme permitido expressamente nestes Termos e qualquer
          plano de Cliente pago aplicável que permita o registro de uma Conta
          para utilizar um Serviço SAFY SIGN (&quot;Plano de Assinatura&quot;),
          quando aplicável. Você não usará nem permitirá o uso do Site para
          qualquer fim ilegal ou de maneira inconsistente com as disposições
          destes Termos. Se você for ou se tornar um concorrente direto da SAFY
          SIGN, você não poderá acessar ou usar os Serviços SAFY SIGN sem o
          consentimento explícito, prévio e escrito da SAFY SIGN e, nesse caso,
          apenas para os fins autorizados por escrito. Exceto se de outra forma
          restrito por estes Termos, a SAFY SIGN concedem a você permissão
          não-exclusiva, intransferível e limitada para exibir, copiar e fazer
          download de conteúdo e materiais no Site, contanto que você: (a)
          retenha todas as notificações de direitos autorais e outros avisos de
          propriedade no conteúdo e materiais; (b) use-os somente para fins
          pessoais ou internos, não comerciais, ou de acordo com qualquer Plano
          de Assinatura aplicável; e (c) não os modifique de forma alguma. Cada
          Plano de Assinatura individual inclui restrições e exigências que
          definem os recursos que um Cliente poderá acessar. Qualquer violação
          por você das disposições de licença contidas nesta Cláusula 3ª poderá
          resultar no término imediato do seu direito de usar o Site, assim
          como, potencial responsabilização por violação de direitos autorais ou
          outras reivindicações, dependendo das circunstâncias.
        </Paragrafo>
        <h3>4. TITULARIDADE</h3>
        <Paragrafo>
          4.1 Propriedade Intelectual. O Site contém materiais que são
          proprietários e protegidos por leis de direitos autorais, disposições
          de tratados internacionais, marcas registradas, marcas de serviço e
          outras leis e tratados de propriedade intelectual.
        </Paragrafo>
        <Paragrafo>
          4.1.1 O Site também está protegido como obra coletiva ou compilação
          por leis de direitos autorais e outras leis e tratados. Você concorda
          em cumprir todas as leis de direitos autorais e outras leis
          aplicáveis, bem como outros avisos ou restrições adicionais de
          direitos autorais contidos no Site. Você reconhece que o Site contém
          obras originais que foram desenvolvidas, compiladas, preparadas,
          revisadas, selecionadas e organizadas pela SAFY SIGN e por outras
          entidades por meio da aplicação de métodos e padrão de julgamento
          desenvolvidos e aplicados por meio do gasto de tempo, esforço e
          dinheiro substanciais, os quais constituem propriedade intelectual
          valiosa da SAFY SIGN e de tais entidades. Todos os direitos presentes
          e futuros referentes a segredos comerciais, patentes, projetos
          (designs), direitos autorais, marcas registradas, direitos de base de
          dados, marcas de serviço, know-how e outros direitos de propriedade
          intelectual ou outros direitos proprietários de qualquer tipo,
          Documentação, quaisquer melhorias, contribuições de design ou obras
          derivadas dos mesmos, e qualquer conhecimento ou processo relacionado
          aos mesmos, incluindo direitos sobre todas as aplicações e registros
          referentes ao Site serão e permanecerão a todo momento entre você e a
          SAFY SIGN, propriedade única e exclusiva da SAFY SIGN.
        </Paragrafo>
        <Paragrafo>
          4.1.2 As marcas registradas, os logotipos, slogans e as marcas de
          serviço exibidos no Site (coletivamente, as &quot;Marcas
          Registradas&quot;) são Marcas registradas ou não registradas da SAFY
          SIGN e de outros. As Marcas Registradas não poderão ser genericamente
          usadas em anúncios ou publicidade ou de outra forma para indicar
          patrocínio ou afiliação da SAFY SIGN a qualquer produto, serviço,
          evento ou empresa/organização/entidade sem a permissão prévia,
          expressa da SAFY SIGN por escrito.
        </Paragrafo>
        <Paragrafo>
          4.1.3. Direitos Autorais. A SAFY SIGN respeita as leis de direito
          autoral e espera que seus usuários façam o mesmo. Se você acredita que
          qualquer conteúdo ou material no Site infringe seus direitos autorais,
          por favor nos notifique através do canal{" "}
          <u>suporte@SAFYsign.com.br</u>
        </Paragrafo>
        <Paragrafo>
          4.2 Restrições ao Uso do Software O Software disponível para download
          por meio do Site ou de websites ou aplicativos de terceiros (o
          &quot;Software&quot;) é obra da SAFY SIGN ou de fornecedores terceiros
          protegida por direitos autorais. O uso do Software é regido por estes
          Termos. A reprodução ou distribuição do Software não autorizadas é
          expressamente proibida por lei e podem resultar em penalidades civis e
          criminais. Os infratores poderão ser processados.
        </Paragrafo>
        <Paragrafo>
          4.3 Conteúdo e Submissões/Conteúdo de Usuário não relacionados a
          “eDocumentos”.{" "}
        </Paragrafo>
        <Paragrafo>
          4.3.1 Submissões. O Site ou os Serviços SAFY SIGN podem permitir que
          você submeta, publique, faça upload ou disponibilize de outra forma
          (coletivamente, &quot;Publicar&quot;) conteúdo, como perguntas,
          mensagens públicas, ideias, feedback sobre produto, comentários e
          outro conteúdo (coletivamente, &quot;Conteúdo de Usuário&quot;) que
          poderá ou não estar disponível para visualização por outros usuários.
          Se você Publicar Conteúdo de Usuário, salvo se indicado por nós de
          outra forma, você nos concederá o direito não exclusivo, isento de
          royalties e totalmente sublicenciável de acessar, visualizar, usar,
          reproduzir, modificar, adaptar, publicar, traduzir, criar obras
          derivadas de, distribuir, copiar e exibir o Conteúdo de Usuário em
          todo o mundo e em qualquer forma, mídia ou tecnologia conhecida
          atualmente ou que venha a ser desenvolvida. Você também permite que
          qualquer outro usuário visualize, copie, acesse, armazene ou reproduza
          tal Conteúdo de Usuário para o uso pessoal daquele usuário. Você nos
          concederá o direito de usar o nome e outras informações sobre você que
          você submeta em relação Conteúdo de Usuário. Você declara e garante
          que: (a) você possui ou de outra forma controla todos os direitos do
          Conteúdo de Usuário que você Publicar; (b) o Conteúdo de Usuário que
          você Publicar é verdadeiro e preciso; e (c) a utilização do Conteúdo
          de Usuário que você Publicar não infringe estes Termos nem quaisquer
          leis aplicáveis. Para evitar dúvidas, o Conteúdo de Usuário não inclui
          qualquer documento, tais como contrato, divulgação, ou notificação que
          você depositar no serviço de Assinatura SAFY SIGN para processamento
          (&quot;eDocumentos&quot;).
        </Paragrafo>
        <Paragrafo>
          4.3.2 Triagem e Remoção. Você reconhece e concorda que a SAFY SIGN e
          seus designados poderão ou não, a critério da SAFY SIGN, realizar
          pré-triagem do Conteúdo de Usuário antes que seja exibido no Site ou
          nos Serviços SAFY SIGN, mas que a SAFY SIGN não tem a obrigação de
          fazê-la. Você também reconhece e concorda que a SAFY SIGN se reserva o
          direito (mas não assume a obrigação), a seu critério exclusivo, de
          rejeitar, mover, editar ou remover qualquer Conteúdo de Usuário que
          seja enviado ao Site ou aos Serviços SAFY SIGN. Sem limitar as
          disposições acima, a SAFY SIGN e seus designados terão o direito de
          remover Conteúdo de Usuário que infrinja estes Termos ou que seja de
          qualquer forma repreensível a critério exclusivo da SAFY SIGN. Você
          reconhece e concorda que a SAFY SIGN não verifica, adota, ratifica nem
          sanciona Conteúdo do Usuário, e você concorda que deverá avaliar e
          assumir todos os riscos associados a sua utilização do Conteúdo de
          Usuário ou sua confiança na precisão, integridade ou utilidade do
          Conteúdo de Usuário.
        </Paragrafo>
        <h3>5. RESTRIÇÕES AO USO DO SITE</h3>
        <Paragrafo>
          5.1 Ao usar o Site, incluindo qualquer Serviço SAFY SIGN, você
          concorda, especificamente, em não realizar atividade nem transmitir
          qualquer informação que, a nosso critério exclusivo:{" "}
        </Paragrafo>
        <Alinea>
          a. Seja ilegal ou infrinja leis ou regulamentos federais, estaduais ou
          municipais;
        </Alinea>
        <Alinea>
          b. Promova atividades ilegais ou discuta atividades ilegais com a
          intenção de praticá-las;
        </Alinea>
        <Alinea>
          c. Infrinja o direito de terceiros, incluindo, mas sem limitação,
          direito à privacidade, direito de publicidade, direitos autorais,
          marca registrada, patente, segredo comercial ou outros direitos
          proprietários ou de propriedade intelectual;
        </Alinea>
        <Alinea>
          d. Seja prejudicial, ameaçador, abusivo, assediante, injurioso,
          indecente, difamatório, sexualmente explícito ou pornográfico,
          discriminatório, vulgar, profano, obsceno, difamatório, contenha
          discurso de ódio, violento ou que promova violência, inflamatório ou
          repreensível de alguma outra forma;
        </Alinea>
        <Alinea>
          e. Interfira com o uso e gozo dos Serviços por qualquer outra pessoa;
        </Alinea>
        <Alinea>f. Tente passar-se por outra pessoa ou entidade</Alinea>
        <Alinea>
          g. Seja comercial de maneira a infringir estes Termos, incluindo, mas
          sem limitação, a utilização do Site para spam, pesquisas, concurso,
          esquemas de pirâmide ou outros materiais publicitários;
        </Alinea>
        <Alinea>
          h. Declare falsamente, distorça ou oculte sua afiliação com outra
          pessoa ou entidade;
        </Alinea>
        <Alinea>
          i. Acesse ou use a conta de outro usuário sem permissão;
        </Alinea>
        <Alinea>
          j. Distribua vírus de computador ou outro código, arquivos ou
          programas que interrompam, destruam ou limitem a funcionalidade de
          qualquer software ou hardware de computador ou equipamento de
          comunicações eletrônicas;
        </Alinea>
        <Alinea>
          k. Interfira em, interrompa, desative, sobrecarregue ou destrua a
          funcionalidade ou uso de quaisquer recursos do Site, ou dos servidores
          ou redes conectadas ao Site, ou quaisquer um dos Serviços SAFY SIGN;
        </Alinea>
        <Alinea>
          l. Realize &quot;hacking&quot; ou acesse sem permissão nossos
          registros proprietários ou confidenciais, aqueles de outro usuário ou
          os de qualquer pessoa;
        </Alinea>
        <Alinea>
          m. Solicite, indevidamente, informações pessoais ou sensíveis de
          outros usuários, incluindo sem limitação, endereço, dados de cartão de
          crédito ou de conta bancária, ou senhas
        </Alinea>
        <Alinea>
          n. Decompile, realize engenharia reversa, desmonte ou tente, de outra
          forma, derivar o código-fonte a partir do Site, salvo autorização
          expressa presente nestes Termos ou na lei e somente na medida em que
          permitida pela lei aplicável, sem consentimento
        </Alinea>
        <Alinea>
          o. Remova, contorne, desative, danifique ou interfira, de outra forma,
          com funções relacionadas à segurança, ou recursos que aplicam
          limitações ao uso do Site;
        </Alinea>
        <Alinea>
          p. Use meios automatizados ou manuais para violar as restrições em
          cabeçalhos de <i>robot exclusion</i> no site, se houver, ou ignore ou
          contorne outras medidas empregadas para impedir ou limitar o acesso,
          por exemplo, por meio de práticas como “captura de tela”, “captura de
          banco de dados” ou qualquer outra atividade com o propósito de obter
          listas de usuários ou outras informações;
        </Alinea>
        <Alinea>
          q. Modifique, copie, extraia (scrape) ou vasculhe (crawl), exiba,
          distribua, publique, licencie, venda, alugue, arrende, empreste,
          transfira, ou comercialize de qualquer outra forma quaisquer materiais
          ou conteúdo no Site;
        </Alinea>
        <Alinea>
          r. Use os Serviços para avaliação comparativa (benchmarking) ou para
          compilar informações para um produto ou serviço;
        </Alinea>
        <Alinea>
          s. Faça download (exceto por meio do armazenamento em cache de página
          necessário para o uso pessoal, ou salvo autorização expressa presente
          nestes Termos), distribua, publique, transmita, desempenhe, reproduza,
          divulgue, duplique, faça upload, licencie, crie obras derivadas de ou
          ofereça para venda qualquer conteúdo ou outras informações contidas em
          ou obtidas de ou por meio do Site ou de Serviços SAFY SIGN, por
          qualquer meio, salvo disposição nestes Termos ou com consentimento
          prévio por escrito da SAFY SIGN; ou
        </Alinea>
        <Alinea>t. Tente realizar qualquer uma das atividades acima.</Alinea>
        <Paragrafo>
          Se você acha que há conteúdo no Site que viole as restrições entre em
          contato conosco.{" "}
        </Paragrafo>
        <Paragrafo>
          5.2 Adicionalmente, os Clientes não deverão realizar as atividades a
          seguir, nem permitir que outros as realizem, em relação aos Serviços
          SAFY SIGN:
        </Paragrafo>
        <Paragrafo>
          Usar os Serviços SAFY SIGN ou permitir o acesso a eles de maneira a
          contornar as restrições de uso contratuais ou a exceder o uso
          autorizado ou as métricas de utilização estabelecidas nestes Termos,
          em qualquer Plano de Assinatura aplicável ou na Política de Uso
          Razoável da SAFY SIGN.
        </Paragrafo>
        <Alinea>
          a. Licenciar, sublicenciar, vender, revender, alugar, arrendar,
          transferir, distribuir, realizar compartilhamento de tempo (time
          share) ou disponibilizar, de outra forma, qualquer parte dos Serviços
          SAFY SIGN ou da documentação técnica e funcional da SAFY SIGN então
          vigentes (“Documentação”) para acesso de terceiros, salvo disposição
          diversa expressa nestes Termos; ou
        </Alinea>
        <Alinea>
          b. Acessar ou usar os Serviços SAFY SIGN ou a Documentação com o
          objetivo de desenvolver ou operar produtos ou serviços visando
          oferecê-los a terceiros em concorrência com os Serviços SAFY SIGN ou
          permitir seu acesso a um concorrente direto da SAFY SIGN.
        </Alinea>
        <Paragrafo>
          5.3 Você não poderá colocar o Site em um quadro, colocar janelas
          pop-up sobre as suas páginas ou afetar de outra forma a exibição de
          suas páginas. Você poderá criar links para o Site, desde que você
          reconheça e concorde a não criar links para o Site em qualquer website
          que contenha tópico, nome, material ou informação que sejam
          inapropriados, profanos, difamatórios, infringentes, obscenos,
          indecentes ou ilegais, ou que viole qualquer direito de propriedade
          intelectual, proprietário, de privacidade ou de publicidade. Qualquer
          violação a essa disposição poderá, a nosso critério exclusivo,
          resultar no término de seu uso e acesso ao Site, efetiva e
          imediatamente.
        </Paragrafo>
        <Paragrafo>
          5.4 Você reconhece que nós não temos a obrigação de monitorar o seu
          acesso ou uso do Site – e de outra pessoa – para verificar violações a
          estes Termos, nem de revisar ou editar qualquer conteúdo. No entanto,
          nós temos o direito de fazê-lo para fins de operacionalizar e melhorar
          o Site (incluindo sem limitação para prevenção de fraudes, avaliação
          de risco, fins de investigação e suporte ao cliente, análise e
          publicidade), para averiguar seu cumprimento com estes Termos e para
          cumprir a lei aplicável ou ordem ou exigência judicial, administrativa
          ou governamental.
        </Paragrafo>
        <h3>6. PRIVACIDADE</h3>
        <Paragrafo>
          6.1 Política de Privacidade da SAFY SIGN você reconhece que, salvo
          conforme descrito nestes Termos ou nos Termos Corporativos aplicáveis,
          as informações que você fornecer a nós ou que nós coletarmos serão
          usadas e protegidas conforme descrito na Política de Privacidade da
          SAFY SIGN . Leia a Política de Privacidade com atenção.
        </Paragrafo>
        <Paragrafo>
          6.2 Processamento/Transferência de Dados. Se o Cliente ou Afiliada do
          Cliente estiver estabelecido no Reino Unido, um Estado Membro da Área
          Econômica Europeia, ou Suíça, o Anexo de Proteção de Dados para
          Assinatura SAFY SIGN se aplicará ao processamento de qualquer Dado
          Pessoal (conforme definido na Cláusula 1ª do DPA).
        </Paragrafo>
        <Paragrafo>
          6.3 Acesso e Divulgação. Podemos acessar, manter ou compartilhar
          quaisquer de suas informações quando acreditarmos de boa-fé que esse
          compartilhamento seja razoavelmente necessário para investigar,
          prevenir ou tomar medidas relacionadas a possíveis atividades ilegais
          ou para cumprir um processo legal (por exemplo, uma intimação ou outro
          processo legal). Nós também poderemos compartilhar suas informações em
          situações que envolvam ameaças potenciais à integridade física de
          qualquer pessoa, violações da Política de Privacidade da SAFY SIGN ou
          dos nossos termos ou contratos de usuário; ou para responder às
          reivindicações de violação de direitos de terceiros e/ou para proteger
          os direitos, a propriedade e a segurança da SAFY SIGN, de nossos
          funcionários, de usuários ou do público. Isso pode envolver o
          compartilhamento das suas informações com as autoridades, agências
          governamentais, tribunais e/ou outras organizações.
        </Paragrafo>
        <h3>7. TERMOS ESPECÍFICOS DOS SERVIÇOS SAFY SIGN</h3>
        <Paragrafo>
          7.1 Direito de Usar os Serviços SAFY SIGN. Sujeito a estes Termos, a
          SAFY SIGN prestará os Serviços SAFY SIGN aos Clientes de acordo com o
          Plano de Assinatura de cada Cliente, e a SAFY SIGN concede a cada
          Cliente uma licença e direito limitados, não-exclusivos e
          intransferíveis, durante a Vigência, unicamente para seus propósitos
          comerciais internos e de acordo com a Documentação, para: (a) usar os
          Serviços SAFY SIGN; (b) implementar, configurar e, através de seu
          Administrador da Conta, permitir que seus Usuários Autorizados acessem
          e usem os Serviços SAFY SIGN até os eventuais limites ou máximos
          aplicáveis; e (c) acessar e usar a Documentação.
        </Paragrafo>
        <Paragrafo>
          7.1.1 Usuários Autorizados. Usuários Autorizados do Cliente devem ser
          identificados por um endereço de e-mail e um nome de usuário
          exclusivos, e duas ou mais pessoas físicas não podem usar os Serviços
          SAFY SIGN como o mesmo Usuário Autorizado. Se o Usuário Autorizado não
          for funcionário do Cliente, o uso dos Serviços SAFY SIGN será
          permitido somente se o usuário estiver sob confidencialidade e outras
          obrigações com o Cliente que sejam, ao menos, tão restritivas quanto
          estes Termos e estiver acessando ou usando os Serviços SAFY SIGN
          apenas para suportar os propósitos comerciais internos do Cliente.
        </Paragrafo>
        <Paragrafo>
          7.1.2 Administrador da Conta. O Cliente poderá designar e
          expressamente autorizar um Usuário(s) Autorizado(s) como seu agente
          para gerenciar a Conta do Cliente, e gerenciamento da Conta do
          Cliente, inclui, sem limitação, configuração das configurações de
          administração, designação de autorização de acesso e uso, solicitação
          de serviços diversos ou adicionais, fornecimento de registro de
          utilização e performance, gerenciamento de modelos (templates),
          execuções de campanhas e eventos aprovados, auxílio nas integrações de
          produto de terceiros e aceitação de notificações, divulgações
          (disclosures) e termos e condições (“Administrador da Conta”). O
          Cliente poderá indicar um empregado ou um terceiro parceiro de
          negócios ou um subcontratado para atuar como seu(s) Administrador(es)
          da Conta e poderá alterar essa atribuição a qualquer tempo através de
          sua Conta.
        </Paragrafo>
        <Paragrafo>7.2 Termos de Pagamento.</Paragrafo>
        <Paragrafo>
          7.2.1 Plano de Assinatura. Os preços, os recursos e as opções dos
          Serviços SAFY SIGN dependem do Plano de Assinatura selecionado, bem
          como das eventuais mudanças instigadas pelo Cliente. A SAFY SIGN não
          garante e nem declara que um Plano de Assinatura específico será
          oferecido indefinidamente, e reserva-se o direito de alterar os preços
          ou os recursos e opções de um Plano de Assinatura específico sem aviso
          prévio
        </Paragrafo>
        <Paragrafo>
          7.2.2 Sem Reembolsos. O Cliente pagará à SAFY SIGN, tempestivamente,
          todas as taxas associadas ao seu Plano de Assinatura, sua Conta ou ao
          uso dos Serviços SAFY SIGN, incluindo, mas sem limitação, pelos
          Usuários Autorizados. OS PAGAMENTOS DO CLIENTE NÃO SÃO REEMBOLSÁVEIS
          SALVO DISPOSIÇÃO EXPRESSA NESTES TERMOS. Cobranças referentes a Planos
          de Assinatura pré-pagos serão enviadas ao Cliente antecipadamente.
          Cobranças referentes a compras por uso e Plano de Assinatura padrão
          serão enviadas posteriormente, salvo disposição diversa no Plano de
          Assinatura.
        </Paragrafo>
        <Paragrafo>
          7.2.3 Cobranças Recorrentes. Ao comprar um Plano de Assinatura, você
          deverá informar, de forma precisa e completa, um método de pagamento
          válido, que você esteja autorizado a usar. Você será faturado pelo seu
          Plano de Assinatura seja através do método de pagamento informado por
          você, tal como um cartão de crédito, ou através de um fornecedor
          intermediário, ou loja de aplicativo similar (“App Store”). O Cliente
          deverá informar a SAFY SIGN imediatamente qualquer alteração em seu
          endereço de cobrança e deverá atualizar sua Conta quando houver
          alterações relacionadas ao seu método de pagamento. AO COMPLETAR O
          REGISTRO DE UM PLANO DE ASSINATURA, O CLIENTE AUTORIZA A SAFY SIGN OU
          UM AGENTE DA SAFY SIGN A DEBITAR, EM SEU MÉTODO DE PAGAMENTO, DE FORMA
          RECORRENTE (POR EXEMPLO, MENSALMENTE OU ANUALMENTE)
          (&quot;AUTORIZAÇÃO&quot;) VALOR REFERENTE A: (a) COBRANÇAS DO PLANO DE
          ASSINATURA APLICÁVEIS; (b) TODOS OS TRIBUTOS APLICÁVEIS; E (c)
          QUALQUER OUTRA COBRANÇA INCORRIDA EM CONEXÃO AO USO DOS SERVIÇOS SAFY
          SIGN PELO CLIENTE. A Autorização continua durante a Vigência de
          Assinatura aplicável e qualquer Renovação de Vigência (conforme
          definido na Cláusula 8.2.3, abaixo) até que o Cliente realize o
          cancelamento conforme estabelecido na Cláusula 8.2 destes Termos.
        </Paragrafo>
        <Paragrafo>
          7.2.4 Encargos Moratórios e Custos de Cobrança. Se a SAFY SIGN não
          receber o pagamento por meio do método de pagamento do Cliente, o
          Cliente concorda em pagar todos os valores devidos mediante
          solicitação. O valor não pago até o vencimento estará sujeito a
          encargos financeiros equivalentes a 1% do saldo não pago por mês ou à
          taxa máxima permitida pela lei aplicável, o que for menor, determinado
          e composto diariamente desde a data de vencimento até a data de
          pagamento. O Cliente reembolsará eventuais custos ou despesas
          (incluindo, sem limitação, honorários advocatícios razoáveis)
          incorridos pela SAFY SIGN para cobrar o valor que não foi pago quando
          devido. A SAFY SIGN poderá aceitar pagamento de qualquer valor, sem
          que isso afete o direito da SAFY SIGN de receber o restante do valor
          devido ou de buscar qualquer outro direito ou remédio. Os valores
          devidos à SAFY SIGN não poderão, por qualquer motivo, ser retidos ou
          compensados pelo Cliente, por qualquer motivo, de valor devido ou
          supostamente devido pela SAFY SIGN.
        </Paragrafo>
        <Paragrafo>
          7.2.5 Faturas. A SAFY SIGN fornecerá informações sobre cobrança e uso
          no formato que nós escolhermos, o qual poderemos alterar de tempos em
          tempos. A SAFY SIGN reserva-se o direito de corrigir eventuais erros
          ou equívocos que identificar, mesmo que a SAFY SIGN já tenha emitido
          uma fatura ou recebido o pagamento. O Cliente concorda em nos
          notificar sobre eventuais problemas ou discrepâncias na cobrança em
          até 30 (trinta) dias após o seu primeiro surgimento na fatura. Se o
          Cliente não trouxer tais problemas/discrepâncias ao nosso conhecimento
          em até 30 (trinta) dias, o Cliente concorda que renuncia ao seu
          direito de contestar tais problemas ou discrepâncias.
        </Paragrafo>
        <Paragrafo>
          7.2.6 Ciclos de Cobrança. As datas de término de um ciclo de cobrança
          poderão mudar de tempos em tempos. Quando um ciclo de cobrança
          abranger menos ou mais que um mês completo, poderemos fazer ajustes
          e/ou rateios razoáveis. O Cliente concorda que nós poderemos (a nosso
          critério) acumular as cobranças incorridas durante um ciclo de
          cobrança e enviá-las como uma ou mais cobranças agregadas durante ou
          ao fim de um ciclo, e que poderemos adiar a obtenção de autorização ou
          pagamento junto ao emissor do cartão de pagamento do Cliente ou à App
          Store até o envio da(s) cobrança (s) acumulada(s).
        </Paragrafo>
        <Paragrafo>
          7.2.7 Programas de Benefícios. Você poderá receber ou ser elegível a
          algumas estruturas de precificação, descontos, recursos, promoções e
          outros benefícios (coletivamente, &quot;Benefícios&quot;) por meio de
          um contrato empresarial ou governamental conosco (&quot;Termos
          Empresariais&quot;). Tais Benefícios são fornecidos unicamente como
          resultado dos Termos Empresariais correspondentes, podendo ser
          modificados ou rescindidos sem notificação. Se você usa os Serviços
          SAFY SIGN e uma entidade empresarial ou governamental paga suas
          cobranças é, de alguma outra forma, responsável pelas cobranças, você
          nos autoriza a compartilhar as informações de sua conta com tal
          entidade e/ou com os seus agentes autorizados. Se você está inscrito
          em um Plano de Assinatura ou recebe certos Benefícios relacionados a
          Termos Empresariais conosco, mas é responsável por suas próprias
          cobranças, você nos autoriza a compartilhar informações da conta
          suficientes para verificar se você continua sendo elegível a tais
          Benefícios e ao Plano de Assinatura.
        </Paragrafo>
        <Paragrafo>
          7.2.8 Responsabilidade Tributária. Todos os pagamentos exigidos por
          estes Termos incluem impostos ou outros tributos governamentais
          semelhantes, incluindo impostos de venda e de uso, impostos de valor
          agregado, impostos sobre mercadorias e serviços, contribuições,
          comerciais, de serviço e outros impostos transacionais semelhantes
          aplicados por qualquer jurisdição, As obrigações da SAFY SIGN e do
          Cliente segundo esta Seção 7.2.9 (Responsabilidade sobre Impostos)
          permanecerão em vigor após a rescisão ou expiração destes Termos.
        </Paragrafo>
        <Paragrafo>
          7.2.9 Fornecedor Intermediário de Cobrança. Se seu Plano de Assinatura
          for baseado num fornecedor intermediário de cobrança, seu fornecedor
          intermediário automaticamente debitará mensalmente de sua conta no
          cartão de crédito ou na App Store o custo de seu Plano de Assinatura e
          os tributos aplicáveis. Se você não estiver adimplente com os
          pagamentos de seu Plano de Assinatura, nos reservamos o direito de
          encerrar sua conta, suspender seu acesso ao seu Plano de Assinatura ou
          converter sua assinatura do Plano de Assinatura em uma conta de
          não-assinatura. Você será responsável pelo pagamento de todos os
          valores vencidos.
        </Paragrafo>
        <Paragrafo>
          7.3 Avaliação Gratuita e Ofertas Especiais para Serviços SAFY SIGN.
        </Paragrafo>
        <Paragrafo>
          7.3.1 Se você fizer o registro para uma avaliação gratuita, oferta
          promocional ou outra oferta limitada para o uso dos Serviços SAFY SIGN
          (&quot;Avaliação Gratuita&quot;), termos e condições adicionais
          poderão ser apresentados a você durante o registro para a Avaliação
          Gratuita, e tais termos e condições são incorporados a estes Termos
          por referência, sendo legalmente vinculantes. Esta Cláusula 7.3
          (Avaliação Gratuita e Ofertas Especiais para Serviços SAFY SIGN)
          substitui e se aplica inobstante quaisquer disposições conflitantes em
          relação ao acesso e uso de uma Avaliação Gratuita
        </Paragrafo>
        <Paragrafo>
          7.3.2 A SAFY SIGN reserva-se o direito de reduzir a vigência de um
          período de avaliação ou de encerrá-lo sem notificação prévia
        </Paragrafo>
        <Paragrafo>
          7.3.3 A versão dos Serviços SAFY SIGN que está disponível para
          Avaliação Gratuita poderá não incluir ou permitir o acesso a todos os
          recursos ou funções. QUAISQUER DADOS QUE O CLIENTE INSERIR NOS
          SERVIÇOS SAFY SIGN E QUAISQUER CONFIGURAÇÕES FEITAS POR OU PARA UM
          CLIENTE DURANTE A AVALIAÇÃO GRATUITA SERÃO PERDIDOS PERMANENTEMENTE AO
          FINAL DO PERÍODO DE AVALIAÇÃO, A MENOS QUE O CLIENTE: (a) COMPRE UM
          PLANO DE ASSINATURA DOS SERVIÇOS SAFY SIGN QUE TENHA ABRANGÊNCIA
          EQUIVALENTE OU SUPERIOR AQUELE DA AVALIAÇÃO; OU (b) EXPORTE TAIS DADOS
          ANTES DO FINAL DO PERÍODO DE AVALIAÇÃO.
        </Paragrafo>
        <Paragrafo>
          7.3.4 Não obstante qualquer outra disposição nestes Termos, incluindo,
          sem limitação, as garantias descritas na Cláusula 9 (Garantias e
          Isenções) ou quaisquer termos e condições específicos a um serviço
          aplicáveis a um Serviço SAFY SIGN específico, incluindo apêndices e
          anexos que acompanham uma Anexo (&quot;Anexo de Serviço&quot;),
          durante uma Avaliação Gratuita, os Serviços SAFY SIGN são prestados
          &quot;NO ESTADO EM QUE SE ENCONTRAM&quot; e &quot;CONFORME
          DISPONÍVEL&quot; SEM QUALQUER GARANTIA QUE POSSA SER ESTABELECIDA
          NESTES TERMOS, E A SAFY SIGN ISENTA-SE DE QUAISQUER GARANTIAS
          IMPLÍCITAS, INCLUINDO, SEM LIMITAÇÃO, COMERCIABILIDADE OU ADEQUAÇÃO A
          UM PROPÓSITO ESPECÍFICO E A RESPONSABILIDADE TOTAL ACUMULADA DA SAFY
          SIGN EM DECORRÊNCIA DE OU RELACIONADA AO USO PELO CLIENTE DA AVALIAÇÃO
          GRATUITA SERÁ EQUIVALENTE A U$S100,00 (CEM DÓLARES ESTADUNIDENSES).
        </Paragrafo>
        <Paragrafo>7.4 Dados.</Paragrafo>
        <Paragrafo>
          7.4.1 Dados em Geral. Você será responsável por dados que você
          fornecer ou usar nos Serviços SAFY SIGN. Você é o único responsável
          por determinar se os Serviços SAFY SIGN são adequados para sua empresa
          ou empresa/organização/entidade e por cumprir todos os regulamentos,
          leis ou convenções aplicáveis aos dados que você fornecer e ao seu uso
          dos Serviços SAFY SIGN e do Site.
        </Paragrafo>
        <Paragrafo>
          7.4.2 Dados Pessoais. O Cliente certifica que sua coleta e uso de
          informações ou dados pessoais fornecidos durante o uso do Site está de
          acordo com todas as leis, regras e regulamentos de proteção de dados
          aplicáveis. O Cliente e seus Usuários Autorizados declaram estar
          cientes de que a SAFY SIGN pode processar tais dados pessoais de
          acordo com a Política de Privacidade da SAFY SIGN que pode ser
          solicita à SAFY SIGN a qualquer momento.
        </Paragrafo>
        <Paragrafo>7.5 Garantias do Cliente.</Paragrafo>
        <Paragrafo>
          Por meio deste, o Cliente declara e garante à SAFY SIGN que: (a)
          possui todos os direitos e autoridade necessários para usar os
          Serviços SAFY SIGN segundo estes Termos e para conceder todos os
          direitos aplicáveis neste instrumento; (b) é responsável por todo o
          uso dos Serviços SAFY SIGN associados à sua Conta; (c) é o único
          responsável em manter a confidencialidade de seus nomes de Conta e
          senha(s); (d) notificará imediatamente à SAFY SIGN, de qualquer uso
          não-autorizado da Conta do Cliente que tome conhecimento; (e) concorda
          que a SAFY SIGN não serão responsáveis por perdas decorrentes da
          utilização da Conta do Cliente por terceiros, independentemente de tal
          uso dar-se com ou sem conhecimento e consentimento; (f) usará os
          Serviços SAFY SIGN apenas para fins legais e de acordo com estes
          Termos; (g) quaisquer informações que forneça à SAFY SIGN serão
          verdadeiras, precisas e corretas; e (h) não tentará obter acesso não
          autorizado ao Sistema ou aos Serviços SAFY SIGN ou a outras contas,
          sistemas de computador ou redes sob controle ou responsabilidade da
          SAFY SIGN por meio de hacking, cracking, mineração de senhas ou
          qualquer outro meio não autorizado.
        </Paragrafo>
        <Paragrafo>7.6 Confidencialidade </Paragrafo>
        <Paragrafo>
          7.6.1 Informações Confidenciais. &quot;Informações Confidenciais&quot;
          significa (a) para a SAFY SIGN, os Serviços SAFY SIGN e a
          Documentação; (b) para o Cliente, os Dados do Cliente; (c) quaisquer
          outras informações de uma parte que é divulgada por escrito ou de
          forma oral e é indicada como confidencial ou proprietária no momento
          da divulgação (e em caso de divulgação de forma oral, resumida por
          escrito em até 30 (trinta) dias da divulgação inicial e entregue ao
          Destinatário), ou que em razão da natureza da informação o
          Destinatário entende de forma clara que se trata de informação
          confidencial da parte divulgadora; e (d) os termos e condições
          específicos destes Termos, e qualquer termo aditivo ou anexo dele,
          entre as partes. Informações Confidenciais não devem incluir qualquer
          informação que: (i) eram ou se tornam conhecidas de forma geral pelo
          público sem que seja por falha ou violação destes Termos pelo
          Destinatário; (ii) estava em posse legítima do Destinatário no momento
          da divulgação sem restrições de uso ou divulgação; (iii) foi
          desenvolvida de forma independente pelo Destinatário sem o uso das
          Informações Confidenciais da parte divulgadora; ou (iv) foi obtida de
          forma legítima pelo Destinatário de um terceiro que não estava sujeito
          ao dever de confidencialidade e sem restrições de uso ou divulgação.
          Todas as informações fornecidas à SAFY SIGN que não sejam Informações
          Confidenciais serão tratadas de acordo com a{" "}
          <u>Política de Privacidade da SAFY SIGN.</u>
        </Paragrafo>
        <Paragrafo>
          7.6.2 Uso Restrito e Não Divulgação. Durante e após a Vigência de
          Assinatura, a parte que recebe as Informações Confidenciais
          (“Destinatário”) deverá: (a) usar as Informações Confidenciais da
          outra parte apenas para os fins para que foram fornecidas; (b) não
          divulgar tais Informações Confidenciais a terceiros, exceto na medida
          do necessário para seus advogados, auditores, consultores, e
          prestadores de serviços que estejam sob obrigações de
          confidencialidade que sejam, ao menos, tão restritivas quanto as
          disposições contidas no presente instrumento; e (c) proteger tais
          Informações Confidenciais contra uso e divulgação não autorizados na
          mesma medida (mas usando nada menos do que o um grau razoável de
          cuidado) em que protege suas próprias Informações Confidenciais de
          natureza similar. Não obstante o exposto anteriormente, o Cliente
          autoriza, expressamente, a SAFY SIGN a usar e processar os Dados de
          Cliente conforme descrito na Política de Privacidade da SAFY SIGN que
          estabelece, mas não se limita, a entrega de eDocumentos conforme
          indicado pelo uso do Cliente dos Serviços SAFY SIGN e o
          compartilhamento de Dados de Transação (conforme definido no Anexo de
          Assinatura) com pessoas que estejam autorizadas a visualizar, aprovar
          ou assinar e Documentos criados pelo Cliente.
        </Paragrafo>
        <Paragrafo>
          7.6.3 Divulgação Necessária. Se a SAFY SIGN for obrigada por lei a
          divulgar Informações Confidenciais, a SAFY SIGN avisará prontamente
          por escrito ao Cliente antes de divulgá-las, a menos que seja proibida
          de fazê-lo pelo processo legal ou administrativo, e auxiliará o
          Cliente a obter, quando disponível razoavelmente, uma ordem que
          proteja as Informações Confidenciais contra divulgação pública.
        </Paragrafo>
        <Paragrafo>
          7.6.4 Titularidade. Não obstante qualquer outra disposição nestes
          Termos, a SAFY SIGN reconhece que, entre as partes, todas as
          Informações Confidenciais que receber do Cliente, incluindo todas as
          cópias da mesma em posse ou controle do Destinatário, em qualquer
          mídia, são proprietárias e de titularidade exclusiva do Cliente. Nada
          nestes Termos concede à SAFY SIGN qualquer direito, título ou
          participação sobre quaisquer Informações Confidenciais do Cliente. A
          incorporação pela SAFY SIGN de Informações Confidenciais da parte
          divulgadora em seus materiais próprios não tornará as Informações
          Confidenciais não confidenciais.
        </Paragrafo>
        <h3>8. VIGÊNCIA E RESCISÃO</h3>
        <Paragrafo>
          8.1 Rescisão – Visitantes do Site. Um Visitante do Site poderá
          rescindir seu uso do Site a qualquer momento deixando de usar o Site.
          A SAFY SIGN pode rescindir seu uso do Site e negar a você acesso ao
          Site a nosso critério exclusivo, por qualquer motivo ou sem motivo,
          incluindo por violação destes Termos.
        </Paragrafo>
        <Paragrafo>8.2 Prazo e Rescisão – Clientes.</Paragrafo>
        <Paragrafo>
          8.2.1 Suspensão do Acesso aos Serviços SAFY SIGN. A SAFY SIGN pode
          suspender qualquer uso dos Serviços SAFY SIGN, remover qualquer
          conteúdo ou desativar ou encerrar qualquer Conta ou Usuário Autorizado
          que a SAFY SIGN razoavelmente acredite, de boa-fé, estar infringindo
          estes Termos. A SAFY SIGN usará esforços comercialmente razoáveis para
          notificar você antes de tal suspensão ou desativação, a menos que a
          SAFY SIGN razoavelmente acredite que: (a) são proibidas de fazê-lo sob
          a lei aplicável ou devido ao processo legal, como processos, ordens,
          mandados e semelhantes de tribunal ou agência administrativa
          governamental; ou (b) seja necessário adiar a notificação para evitar
          prejuízo iminente aos Serviços SAFY SIGN ou a terceiros. Nas
          circunstâncias em que a notificação seja adiada, a SAFY SIGN fornecerá
          a notificação se e quando as restrições relacionadas na frase anterior
          não mais se aplicarem.
        </Paragrafo>
        <Paragrafo>
          8.2.2 Vigência. O período de vigência destes Termos
          (&quot;Vigência&quot;), com relação aos Serviços SAFY SIGN, começará
          na data em que o Cliente aceitá-los e continua até que o Plano de
          Assinatura do Cliente expire ou que seu uso dos Serviços SAFY SIGN
          seja cessado (incluindo devido à rescisão em conformidade com esta
          Cláusula 8.2), o que for posterior.
        </Paragrafo>
        <Paragrafo>
          8.2.3 Prazo de Assinatura e Renovações Automáticas. Os Planos de
          Assinatura da SAFY SIGN são automaticamente renovados, salvo indicação
          em outra forma. Se você comprar um Plano de Assinatura você concorda
          em pagar as taxas aplicáveis então vigentes associadas com o Plano de
          Assinatura e adicionalmente concorda e reconhece que este será
          renovado automaticamente a menos que, antes do atual fim do período
          corrente de vigência do Plano de Assinatura (&quot;Prazo de
          Assinatura&quot;): (a) você encerre sua Conta; (b) você configure sua
          Conta para não realizar a renovação automática, através do log in nos
          Serviços SAFY SIGN ou nos contatando; (c) a SAFY SIGN recusar-se a
          renovar seu Plano de Assinatura; ou (d) estes Termos sejam devidamente
          rescindidos de outra forma, conforme as disposições deste instrumento.
          O Plano de Assinatura será renovado automaticamente mensal ou
          anualmente, dependendo do método escolhido por você (“Renovação de
          Vigência”).
        </Paragrafo>
        <Paragrafo>
          Códigos promocionais apenas poderão ser utilizados no primeiro ano de
          Vigência de Assinatura. Se você adquiriu seu Plano de Assinatura com
          um código promocional, a cada renovação do seu Plano de Assinatura
          você será cobrado pelo montante anual integral. Se seu Plano de
          Assinatura for rescindido por qualquer motivo, e você adquirir outro
          Plano de Assinatura, você não será elegível a utilizar um código
          promocional.
        </Paragrafo>
        <Paragrafo>
          Nós nos reservamos o direito de modificar, rescindir ou de outra forma
          alterar as taxas e recursos associados ao seu Plano de Assinatura. Nós
          também poderemos recomendar a compra de um novo Plano de Assinatura
          que seja comparável ao seu Plano de Assinatura anterior que está se
          encerrando. Antes de alterarmos as taxas e cobranças em vigor, ou
          adicionar novas taxas e cobranças, nós notificaremos você com pelo
          menos 30 (trinta) dias de antecedência. Se nós notificarmos você com
          pelo menos 30 (trinta) dias de antecedência, o seu uso continuado dos
          Serviços SAFY SIGN após as alterações terem sido feitas, constituirá a
          sua aceitação sobre as alterações. Se você não desejar continuar com a
          assinatura com as novas taxas ou recursos, você poderá rescindir seu
          Plano de Assinatura conforme descrito na Cláusula 8.2.4. Se você
          aceitar o novo Plano de Assinatura, seus os termos e condições,
          juntamente com estes Termos, aplicar-se-ão na Renovação de Vigência e
          posteriormente.
        </Paragrafo>
        <Paragrafo>
          8.2.4 Rescisão pelo Cliente. Você poderá encerrar sua Conta a qualquer
          momento mediante notificação por escrito com 10 (dez) dias de
          antecedência à SAFY SIGN. Se desejar encerrar, você deve nos notificar
          previamente entrando em contato conosco. Se você adquiriu seu Plano de
          Assinatura através de um serviço externo, tal como em uma App Store,
          você deverá usar as ferramentas disponibilizadas por tais serviços
          para gerenciar e/ou rescindir seu Plano de Assinatura. Não obstante o
          previsto na Cláusula 7.2.2, se um Cliente rescindir seu Plano de
          Assinatura anual nos primeiros 30 (trinta) dias da Vigência inicial de
          Assinatura, ele poderá solicitar por escrito à SAFY SIGN o reembolso
          das taxas pagas à SAFY SIGN pela Vigência Inicial da Assinatura, que a
          SAFY SIGN analisará, sem obrigação, de boa-fé. A SAFY SIGN não terá
          obrigação de considerar solicitações de reembolso referentes à
          rescisão de um Plano de Assinatura se a rescisão não ocorrer nos
          primeiros 30 (trinta) dias da Vigência Inicial de Assinatura , ou se
          tiver havido uma violação de outros Termos neste instrumento, ou se
          registros indicarem que houve uso produtivo substancial nesse período.
        </Paragrafo>
        <Paragrafo>
          8.2.5 Inadimplemento; Rescisão pela SAFY SIGN. Um Cliente estará
          inadimplente em relação a estes Termos se: (a) não pagar
          tempestivamente qualquer valor devido a nós ou a uma Afiliada nossa;
          (b) ele ou um Usuário Autorizado associado com sua Conta violar
          qualquer disposição destes Termos ou infringir qualquer política
          publicada aplicável aos Serviços SAFY SIGN; (c) ele estiver ou se
          tornar sujeito a qualquer procedimento do Código de Falência ou leis
          semelhantes; ou (d) se, a nosso critério exclusivo, nós acreditarmos
          que a continuação da utilização dos Serviços SAFY SIGN por parte do
          Cliente (ou de seus Usuários Autorizados ou signatários) acarretar em
          risco legal para a SAFY SIGN ou representar uma ameaça à segurança dos
          Serviços SAFY SIGN ou dos clientes da SAFY SIGN. Se um Cliente estiver
          inadimplente, nós poderemos, sem notificação: (i) suspender sua Conta
          e seu uso dos Serviços SAFY SIGN; (ii) encerrar sua Conta; (iii)
          cobrar taxas de reativação para reativar a sua Conta; e (iv) buscar
          qualquer outro remédio que esteja disponível. Uma &quot;Afiliada&quot;
          da SAFY SIGN significa qualquer pessoa jurídica que seja de
          propriedade da SAFY SIGN, Inc., que seja proprietária da SAFY SIGN,
          Inc., ou que esteja sob controle comum com a SAFY SIGN Inc. Uma
          “Afiliada” do Cliente significa qualquer pessoa jurídica que seja de
          propriedade do Cliente, que seja proprietária do Cliente ou que esteja
          sob controle comum com o Cliente. &quot;Controle&quot; e &quot;ser
          proprietário&quot; significam possuir mais do que 50% da titularidade
          de uma pessoa jurídica ou o direito de direcionar a administração da
          entidade.
        </Paragrafo>
        <Paragrafo>
          8.2.6 Efeito da Rescisão. Se estes Termos expirarem ou forem
          rescindidos por qualquer motivo: (a) o Cliente pagará à SAFY SIGN
          quaisquer valores que foram acumulados anteriormente e que continuem
          em aberto quando da data da rescisão ou expiração, incluindo os
          valores referentes ao ciclo de cobrança em que ocorrer a rescisão; (b)
          toda e qualquer responsabilidade do Cliente perante a SAFY SIGN que
          foram acumuladas antes da data efetiva da expiração ou rescisão
          sobreviverão; (c) licenças e direitos de uso concedidos ao Cliente
          referentes ao Site e aos Serviços SAFY SIGN e propriedade intelectual
          serão rescindidos imediatamente; (d) a obrigação da SAFY SIGN de
          prestar outros serviços ao Cliente segundo estes Termos será
          rescindida imediatamente, exceto eventuais serviços que devam,
          expressamente, ser prestados após a expiração ou rescisão destes
          Termos; e (e) as disposições da Cláusula 3 (Utilização e Direitos de
          Acesso), Cláusula 4 (Titularidade), Cláusula 5.3 (Restrições ao Uso do
          Site), Cláusula 5.4 (Conformidade com os Termos), Cláusula 6
          (Privacidade), Cláusula 7.2.2 (Sem Reembolsos), Cláusula 7.2.7
          (Programas de Benefícios), Cláusula 7.2.8 (Responsabilidade
          Tributária); Cláusula 7.3 (Avaliação Gratuitas e Ofertas Especiais
          para Serviços SAFY SIGN), Cláusula 7.4 (Dados), Cláusula 7.6
          (Confidencialidade), Cláusula 8.2.6 (Efeito da Rescisão), Cláusula 9
          (Garantias e Isenções), Cláusula 10 (Obrigações de Indenização),
          Cláusula 11 (Limitações de Responsabilidade) e Cláusula 12 (Geral)
          sobreviverão, bem como disposições designadas a sobreviver sob os
          Anexos de Serviço e anexos e Apêndices destes Termos.
        </Paragrafo>
        <h3>9. GARANTIAS E ISENÇÕES</h3>
        <Paragrafo>
          OS SERVIÇOS SAFY SIGN, A DOCUMENTAÇÃO E O SITE SÃO FORNECIDOS &quot;NO
          ESTADO QUE SE ENCONTRAM&quot; (“AS IS”) E &quot;CONFORME
          DISPONÍVEIS&quot;. O SEU USO DOS SERVIÇOS SAFY SIGN, DA DOCUMENTAÇÃO E
          DO SITE SERÁ POR SEU RISCO EXCLUSIVO. A SAFY SIGN E RESPECTIVOS
          DIRETORES, FUNCIONÁRIOS, MEMBROS, ACIONISTAS, AGENTES, AFILIADOS,
          SUBSIDIÁRIAS E LICENCIADORES (&quot;PARTES DA SAFY SIGN&quot;): (a)
          NÃO FAZEM DECLARAÇÕES OU GARANTIAS ADICIONAIS DE QUALQUER TIPO, SEJAM
          EXPRESSAS, IMPLÍCITAS (DE FATO OU POR OPERAÇÃO DA LEI) OU
          REGULATÓRIAS, EM RELAÇÃO A QUALQUER ASSUNTO; (b) ISENTAM-SE,
          EXPRESSAMENTE, DAS GARANTIAS IMPLÍCITAS DE COMERCIABILIDADE, ADEQUAÇÃO
          A UM PROPÓSITO PARTICULAR, QUALIDADE, PRECISÃO E TITULARIDADE; E (c)
          NÃO GARANTEM QUE OS SERVIÇOS SAFY SIGN, A DOCUMENTAÇÃO OU SITE SÃO OU
          SERÃO LIVRES DE ERRO, QUE ATENDERÃO AOS SEUS REQUISITOS, QUE SERÃO
          ENTREGUES DENTRO DO PRAZO OU QUE SERÃO SEGUROS. VOCÊ SERÁ O ÚNICO
          RESPONSÁVEL POR EVENTUAIS DANOS ADVINDOS DA UTILIZAÇÃO DOS SERVIÇOS
          SAFY SIGN, DA DOCUMENTAÇÃO OU DO SITE.
        </Paragrafo>
        <Paragrafo>
          AS PARTES DA SAFY SIGN NÃO DECLARAM NEM GARANTEM QUE OS SERVIÇOS SAFY
          SIGN, A DOCUMENTAÇÃO E O SITE FORAM OU SERÃO FORNECIDOS COM A DEVIDA
          HABILIDADE, CUIDADO OU DILIGÊNCIA, NEM A PRECISÃO OU COMPLETUDE DOS
          SERVIÇOS SAFY SIGN, DA DOCUMENTAÇÃO OU DO CONTEÚDO DO SITE, E NÃO
          ASSUMEM RESPONSABILIDADE POR: (i) ERROS, EQUÍVOCOS OU IMPRECISÕES DE
          CONTEÚDO; (ii) LESÕES PESSOAIS OU DANOS À PROPRIEDADE, DE QUALQUER
          NATUREZA, ADVINDOS DE SEU ACESSO E UTILIZAÇÃO DOS SERVIÇOS SAFY SIGN,
          DA DOCUMENTAÇÃO E DO SITE; (iii) QUALQUER ACESSO OU UTILIZAÇÃO NÃO
          AUTORIZADOS DE NOSSOS SERVIDORES E/OU DE QUAISQUER INFORMAÇÕES
          PESSOAIS E/OU INFORMAÇÕES FINANCEIRAS ARMAZENADAS NOS MESMOS; (iv)
          QUAISQUER INTERRUPÇÕES OU CESSAÇÃO DE TRANSMISSÃO DE OU PARA O SITE;
          (v) ERROS, VÍRUS, CAVALOS DE TROIA OU SEMELHANTES QUE VENHAM A SER
          TRANSMITIDOS PARA OU ATRAVÉS DO SITE POR MEIO DAS AÇÕES DE TERCEIROS;
          (vi) A PERDA DE SEUS DADOS OU CONTEÚDO NO SITE; E/OU (vii) QUAISQUER
          ERROS OU OMISSÕES EM QUALQUER CONTEÚDO, OU EVENTUAIS PERDAS OU DANOS
          DE QUALQUER TIPO DECORRENTES DA UTILIZAÇÃO DE QUALQUER CONTEÚDO
          PUBLICADO, ENVIADO POR E-MAIL, TRANSMITIDO OU DISPONIBILIZADO DE
          ALGUMA OUTRA FORMA POR MEIO DOS SERVIÇOS SAFY SIGN, DA DOCUMENTAÇÃO E
          DO SITE. VOCÊ NÃO TERÁ O DIREITO DE FAZER OU REPASSAR GARANTIAS EM
          NOME DA SAFY SIGN PARA TERCEIROS. DADO QUE ALGUNS ESTADOS E
          JURISDIÇÕES NÃO PERMITEM LIMITAÇÕES SOBRE GARANTIAS IMPLÍCITAS, A
          LIMITAÇÃO ACIMA PODE NÃO SE APLICAR A VOCÊ. NESSE CASO, TAIS GARANTIAS
          SÃO LIMITADAS NA MÁXIMA EXTENSÃO E NO MÍNIMO PERÍODO PERMITIDOS PELA
          LEI APLICÁVEL.
        </Paragrafo>
        <Paragrafo>
          AS PARTES DA SAFY SIGN NÃO GARANTEM, ENDOSSAM OU ASSUMEM
          RESPONSABILIDADE POR QUALQUER PRODUTO OU SERVIÇO ANUNCIADO OU
          OFERECIDO POR TERCEIROS POR MEIO DO SITE, E AS PARTES DA SAFY SIGN NÃO
          SERÃO PARTES OU SERÃO DE QUALQUER FORMA RESPONSÁVEL POR MONITORAR
          QUALQUER TRANSAÇÃO ENTRE VOCÊ E PRESTADORES DE PRODUTOS OU SERVIÇOS
          TERCEIROS. VOCÊ É O ÚNICO RESPONSÁVEL POR TODAS AS SUAS COMUNICAÇÕES E
          INTERAÇÕES COM OUTRAS PESSOAS QUE DECORRAM DE SUA UTILIZAÇÃO DO SITE.
        </Paragrafo>
        <h3>10. OBRIGAÇÕES DE INDENIZAÇÃO.</h3>
        <Paragrafo>
          Você defenderá, indenizará e isentará a nós e aos nossos Afiliados,
          diretores, funcionários, fornecedores, consultores e agentes de toda e
          qualquer reivindicação, responsabilidade, danos e custos de terceiros
          (incluindo, entre outros, honorários advocatícios) advindos ou
          relacionados, conforme aplicável, a: (a) seu acesso a e uso do Site;
          (b) violação destes Termos por você , ou seu(s) Administrador(es) da
          Conta ou por Usuários Autorizados, conforme aplicável; (c) violação
          por você dos direitos de propriedade intelectual ou outros direitos de
          qualquer pessoa ou entidade; (d) a natureza e o conteúdo de todos os
          Dados de Cliente processados pelos Serviços SAFY SIGN; ou (e)
          quaisquer produtos ou serviços comprados ou obtidos por você em
          conexão com o Site.
        </Paragrafo>
        <Paragrafo>
          A SAFY SIGN reserva-se o direito exclusivo de resolver, entrar em
          acordo e pagar, sem seu consentimento prévio, quaisquer e todas as
          reivindicações ou ações ajuizadas contra nós. Nós nos reservamos o
          direito, às suas custas, de assumir a defesa e o controle exclusivos
          de qualquer assunto para o qual você seja requerido a nos indenizar e
          você concorda em cooperar com nossa defesa nessas reivindicações. Você
          concorda em não resolver qualquer assunto em que sejamos nomeados como
          réu e/ou nos quais você tem obrigações de indenização sem nosso
          consentimento prévio por escrito. Nós usaremos os esforços razoáveis
          para notificá-lo sobre essas reivindicações, ações ou processos assim
          que tomarmos ciência destes.
        </Paragrafo>
        <h3>11. LIMITAÇÕES DE RESPONSABILIDADE</h3>
        <Paragrafo>
          11.1 Isenção de Danos Consequentes. NÃO OBSTANTE QUALQUER DISPOSIÇÃO
          EM CONTRÁRIO CONTIDA NESTES TERMOS, A SAFY SIGN NÃO SERÁ, EM NENHUMA
          CIRCUNSTÂNCIA, RESPONSÁVEL PERANTE VOCÊ POR QUAISQUER DANOS
          CONSEQUENTES, INCIDENTAIS, ESPECIAIS, DE COBERTURA, PUNITIVOS OU
          EXEMPLARES ADVINDOS DE OU RELACIONADOS ÀS TRANSAÇÕES CONTEMPLADAS
          NESTES TERMOS, INCLUINDO, MAS NÃO SE LIMITANDO, AVIAMENTO, INTERRUPÇÃO
          DE TRABALHO, PERDAS DE LUCROS OU PERDA DE NEGÓCIOS, MESMO QUE
          INFORMADA DA PROBABILIDADE DE TAIS PERDAS E INDEPENDENTEMENTE DE TAIS
          REIVINDICAÇÕES SEREM BASEADAS EM CONTRATO, ATO ILÍCITO (INCLUINDO
          NEGLIGÊNCIA) OU QUALQUER OUTRA TEORIA LEGAL.
        </Paragrafo>
        <Paragrafo>
          NA MÁXIMA EXTENSÃO PERMITIDA PELA LEI APLICÁVEL, AS PARTES DA SAFY
          SIGN NÃO SERÃO, EM NENHUM CASO, RESPONSÁVEIS PERANTE VOCÊ POR
          QUAISQUER DANOS DIRETOS, INDIRETOS, INCIDENTAIS, ESPECIAIS, PUNITIVOS
          OU CONSEQUENTES ADVINDOS DE: (a) USO DO SITE, DA DOCUMENTAÇÃO OU DOS
          SERVIÇOS SAFY SIGN; (b) ERROS, EQUÍVOCOS OU IMPRECISÕES DE CONTEÚDO;
          (c) LESÕES PESSOAIS OU DANOS À PROPRIEDADE, DE QUALQUER NATUREZA,
          ADVINDOS DE SEU ACESSO A E USO DO SITE, DA DOCUMENTAÇÃO OU DOS
          SERVIÇOS SAFY SIGN; (d) QUALQUER ACESSO OU USO NÃO AUTORIZADO DE
          NOSSOS SERVIDORES E/OU QUAISQUER INFORMAÇÕES PESSOAIS E/OU INFORMAÇÕES
          FINANCEIRAS ARMAZENADAS NOS MESMOS; (e) INTERRUPÇÃO OU CESSAÇÃO DE
          TRANSMISSÃO DE OU PARA NOSSOS SERVIDORES; (f) QUAISQUER ERROS, VÍRUS,
          CAVALOS DE TROIA OU SIMILARES QUE VENHAM A SER TRANSMITIDOS PARA OU
          POR MEIO DO SITE, DA DOCUMENTAÇÃO OU DOS SERVIÇOS SAFY SIGN POR
          TERCEIROS; (g) PERDA DE SEUS DADOS OU CONTEÚDO NO SITE, NA
          DOCUMENTAÇÃO OU NOS SERVIÇOS SAFY SIGN; (h) QUAISQUER ERROS OU
          OMISSÕES EM QUALQUER CONTEÚDO OU PERDAS OU DANOS DE QUALQUER TIPO
          ADVINDOS DE SUA UTILIZAÇÃO DE QUALQUER CONTEÚDO PUBLICADO, TRANSMITIDO
          OU DISPONIBILIZADO DE ALGUMA OUTRA FORMA POR MEIO DO SITE, DA
          DOCUMENTAÇÃO OU DOS SERVIÇOS SAFY SIGN, QUER SEJA BASEADO EM GARANTIA,
          CONTRATO, ATO ILÍCITO (INCLUINDO NEGLIGÊNCIA) OU QUALQUER OUTRA TEORIA
          LEGAL, E INDEPENDENTEMENTE DE AS PARTES DA SAFY SIGN TEREM SIDO
          ADVERTIDAS DA POSSIBILIDADE DE TAIS DANOS; E/OU (i) A DIVULGAÇÃO DE
          INFORMAÇÕES DE ACORDO COM ESTES TERMOS OU COM NOSSA POLÍTICA DE
          PRIVACIDADE.
        </Paragrafo>
        <Paragrafo>
          Alguns países e jurisdições não permitem a limitação ou exclusão de
          danos consequentes, diretos, indiretos ou de outros tipos em contratos
          com clientes, e, uma vez que você é um cliente, as limitações ou
          exclusões nesta Cláusula 11.1 podem não se aplicar a você.
        </Paragrafo>
        <Paragrafo>
          11.2 Limite de Danos. NOSSA RESPONSABILIDADE TOTAL PERANTE VOCÊ POR
          QUALQUER AÇÃO DECORRENTE DE OU RELACIONADA A ESTES TERMOS OU AO SEU
          USO DO SITE (INCLUINDO, SEM LIMITAÇÃO, REIVINDICAÇÕES DE GARANTIA),
          INDEPENDENTEMENTE DO FÓRUM E SE QUALQUER AÇÃO OU REIVINDICAÇÃO É
          BASEADA EM CONTRATO, ATO ILÍCITO (INCLUINDO NEGLIGÊNCIA) OU QUALQUER
          OUTRA TEORIA LEGAL OU EQUITATIVA, NÃO EXCEDERÁ O VALOR TOTAL PAGO POR
          VOCÊ PARA A SAFY SIGN PELOS SERVIÇOS SAFY SIGN QUE RESULTARAM NA
          REIVINDICAÇÃO CONFORME ESTES TERMOS DURANTE O PERÍODO DE DOZE (12)
          MESES ANTERIORES À DATA DO PRIMEIRO EVENTO QUE ORIGINOU A
          RESPONSABILIDADE OU US$ 100, O QUE FOR MAIOR. A EXISTÊNCIA DE MAIS DE
          UMA REIVINDICAÇÃO NÃO AUMENTARÁ ESSE LIMITE.
        </Paragrafo>
        <Paragrafo>
          11.3 Alocações Independentes de Risco. Cada disposição destes Termos
          que estabelece uma limitação de responsabilidade, isenção de garantia
          ou exclusão de danos representa uma alocação dos riscos destes Termos
          acordada entre as partes. Essa alocação é um elemento essencial dos
          fundamentos da negociação entre as partes. Cada uma dessas disposições
          pode ser separada e é independente de todas as demais disposições
          destes Termos, e cada uma dessas disposições se aplicará ainda que as
          garantias nestes Termos não tenham cumprido seu propósito essencial.
        </Paragrafo>
        <Paragrafo>11.4 Limitações Jurisdicionais.</Paragrafo>
        <Paragrafo>
          11.4.1 Dado que alguns estados e jurisdições não permitem a limitação
          de responsabilidade em algumas instâncias, partes da limitação acima
          podem não se aplicar a você. Nesse caso, tais exclusões e limitações
          aplicar-se-ão até a extensão máxima permitida pela lei vigente (e
          nossa responsabilidade será limitada ou excluída conforme permitido
          pela lei vigente). Se você for morador de Nova Jersey, as limitações
          na Cláusula 11 se aplicam especificamente a você.
        </Paragrafo>
        <h3>12. GERAL</h3>
        <Paragrafo>
          12.1 Conteúdo de Terceiros. Nós poderemos fornecer, ou terceiros
          poderão fornecer, links para outros websites, serviços ou recursos de
          terceiros que estão além de nosso controle. Nós não fazemos qualquer
          garantia sobre a qualidade, a adequação, a funcionalidade ou a
          legalidade de qualquer conteúdo de terceiros para os quais sejam
          fornecidos links, e você renuncia, por meio deste, a qualquer
          reivindicação que possa ter contra nós em referência a tais serviços.
          A SAFY SIGN NÃO É RESPONSÁVEL PELO CONTEÚDO NA INTERNET OU EM PÁGINAS
          DA WEB QUE ESTEJAM LOCALIZADAS FORA DO SITE OU EM PUBLICAÇÕES DE
          CONTEÚDO DE USUÁRIO. Sua correspondência ou negociação com anunciantes
          ou parceiros encontrados no ou por meio do Site, bem como participação
          em promoções dos mesmos, incluindo pagamento e entrega de mercadorias
          ou serviços relacionados e quaisquer outros termos, condições,
          garantias ou declarações associadas com tais negociações, dão-se
          exclusivamente entre você e tal anunciante ou parceiro. Você concorda
          que não somos responsáveis por perdas ou danos de qualquer tipo
          advindos de tais negociações ou que resultem da presença de tais
          anunciantes ou de links para websites ou recursos de terceiros no
          Site.
        </Paragrafo>
        <Paragrafo>
          12.2 Relacionamento. A todo momento, você e a SAFY SIGN serão
          contratantes independentes e não serão agentes ou representantes da
          outra parte. Estes Termos não têm a intenção de criar um
          relacionamento de joint venture, parceria ou franquia entre as partes.
          Entidades que não sejam partes deste não se beneficiam e não podem
          aplicar estes Termos. Não há terceiros beneficiários a estes Termos.
          Você não deverá declarar a ninguém que é agente da SAFY SIGN nem que
          está de outra forma autorizado a vincular ou comprometer a SAFY SIGN
          de nenhuma maneira sem a autorização prévia por escrito da SAFY SIGN.
        </Paragrafo>
        <Paragrafo>
          12.3 Leis de Controle de Exportação. Você reconhece que o Site, a
          Documentação e/ou os Serviços SAFY SIGN e quaisquer produtos,
          informações, documentação, Software, tecnologia e dados técnicos
          relacionados, bem como quaisquer derivados dos mesmos, que a SAFY SIGN
          disponibiliza (coletivamente, &quot;Dados Excluídos&quot;) estão
          sujeitos a leis e regulamentos de controle de exportação.{" "}
        </Paragrafo>
        <Paragrafo>
          12.4 Cessão. Você não poderá ceder seus direitos ou obrigações sob
          estes Termos sem o consentimento prévio da SAFY SIGN por escrito. Se o
          consentimento for concedido, estes Termos serão vinculantes para seus
          sucessores e cessionários. Qualquer tentativa de sua parte de
          transferir seus direitos, deveres ou obrigações sob estes Termos é
          nula, salvo disposição expressa nestes Termos. A SAFY SIGN poderá
          livremente ceder os direitos, deveres e obrigações da SAFY SIGN sob
          estes Termos.
        </Paragrafo>
        <Paragrafo>
          12.5 Notificações. Salvo autorização em contrário nestes Termos,
          qualquer notificação exigida ou permitida em relação aos Serviços SAFY
          SIGN será efetiva somente se por escrito e enviada usando: (a)
          Serviços SAFY SIGN; (b) correspondência certificada ou registrada; ou
          (c) transportadora segurada, à parte apropriada, no endereço
          estabelecido nas informações de cadastro do Cliente, ou no Site para a
          SAFY SIGN, com cópia, no caso da SAFY SIGN. O Cliente ou a SAFY SIGN
          poderão alterar o endereço de recebimento de notificações por meio de
          notificação a outra parte de acordo com esta Cláusula 12. As
          notificações serão consideradas entregues mediante o recebimento, no
          caso de entrega pelos Serviços SAFY SIGN; 2 (dois) dias úteis após a
          data da remessa; ou 1 (um) dia útil após a entrega a uma
          transportadora
        </Paragrafo>
        <Paragrafo>
          12.6 Força Maior. Exceto por quaisquer obrigações de pagamento, nem
          você nem a SAFY SIGN serão responsáveis pelo descumprimento de uma
          obrigação estabelecida nestes Termos quando tal descumprimento for
          causado por força maior (incluindo caso fortuito, desastres naturais,
          guerra, insurreição civil, ação por entidade governamental, greve ou
          outras causas além do controle razoável da parte). A parte afetada
          pelo evento de força maior notificará a outra parte em um período
          comercialmente razoável e envidará esforços comercialmente razoáveis
          para retomar o desempenho assim que seja viável. Obrigações não
          desempenhadas por motivo de força maior serão desempenhadas assim que
          possível, tão logo seja razoável, quando o evento de força maior for
          concluído.
        </Paragrafo>
        <Paragrafo>
          12.7 Arbitragem Obrigatória, Renúncia a Ações Coletivas Aplicáveis aos
          Clientes.
        </Paragrafo>
        <Paragrafo>
          LEIA ESTA SEÇÃO COM ATENÇÃO. ELA AFETA SEUS DIREITOS LEGAIS, INCLUINDO
          SEU DIREITO DE INICIAR UM PROCESSO JUDICIAL.
        </Paragrafo>
        <Paragrafo>
          12.7.1 Você concorda que estes Termos afetam o comércio interestadual
          e que a Lei de Arbitragem brasileira rege a interpretação e a
          aplicação destas disposições de arbitragem. Esta Cláusula 12.7, que
          deverá ser interpretada de forma ampla, rege todo e qualquer litígio
          entre nós, incluindo, entre outros, reivindicações referentes a
          qualquer aspecto de nosso relacionamento, sejam baseadas em contrato,
          responsabilidade civil, estatuto, fraude, declaração falsa ou qualquer
          outra teoria legal; reivindicações que surgiram antes destes Termos ou
          de anteriores; e reivindicações que possam surgir após a rescisão
          destes Termos. Os únicos litígios excluídos desta proibição ampla são
          o litígio referentes as certas propriedades intelectuais, conforme
          disposto abaixo.
        </Paragrafo>
        <Paragrafo>
          12.7.2 Resolução Inicial de Litígio. A maioria dos litígios pode ser
          solucionada sem precisar recorrer à arbitragem. Você concorda que, se
          tiver algum litígio conosco, antes de tomar qualquer medida formal,
          você entrará em contato conosco no endereço eletrônico responsável
          pelo recebimento e fornecerá uma breve descrição escrita sobre o
          litígio e suas informações de contato (incluindo seu nome de usuário,
          caso o litígio seja relacionado a uma Conta). Exceto por propriedade
          intelectual, você e a SAFY SIGN concordam em envidar esforços dentro
          do razoável para solucionar qualquer litígio, reivindicação, questão
          ou discordância diretamente, por meio de consulta à SAFY SIGN, e
          negociações em boa-fé serão uma condição para que qualquer uma das
          partes inicie uma ação judicial ou arbitragem.
        </Paragrafo>
        <Paragrafo>
          12.7.3 Arbitragem Vinculante. Se as partes não chegarem a uma solução
          acordada em um período de 30 (trinta) dias após o início da resolução
          de litígio informal segundo a disposição de Resolução Inicial de
          Litígio acima, qualquer uma das partes poderá iniciar arbitragem
          vinculante como o único meio de solucionar reivindicações (salvo
          disposição abaixo), sujeito aos Termos estabelecidos abaixo.
          Especificamente, todas as reivindicações advindas de ou relacionadas a
          estes Termos, ao relacionamento das partes entre si e/ou à sua
          utilização dos Serviços SAFY SIGN serão solucionadas por arbitragem DE
          ACORDO com as Regras e Procedimentos de Arbitragem, excluindo
          quaisquer regras ou procedimentos que regem ou permitem ações
          coletivas.
        </Paragrafo>
        <Paragrafo>
          12.7.4 Poderes do Árbitro. O árbitro, e não qualquer tribunal ou
          agência federal, estadual ou municipal, terá a autoridade exclusiva de
          solucionar todos os litígios advindos de ou relacionados à
          interpretação, aplicação, capacidade de aplicação ou formação destes
          Termos, incluindo, entre outros, qualquer reivindicação de que parte
          dos Termos ou todos os Termos sejam nulos, se uma reivindicação está
          ou não sujeita à arbitragem, ou a questão de renúncia por conduta de
          litígio. O árbitro terá o poder de conceder qualquer reparação que
          estaria disponível em um tribunal por lei ou por equidade. A concessão
          do árbitro será feita por escrito e será vinculante sobre as partes,
          podendo ser registrada como um julgamento em qualquer tribunal da
          jurisdição competente.
        </Paragrafo>
        <Paragrafo>
          12.7.7 Renúncia à Ação Coletiva. As partes concordam que as ações
          judiciais serão realizadas apenas de forma individual por cada parte e
          não como ação coletiva ou outra ação de representação, e as partes
          renunciam, expressamente, ao seu direito de iniciar uma ação coletiva
          ou de solicitar reparação com base coletiva. VOCÊ E A SAFY SIGN
          CONCORDAM QUE CADA PARTE PODE FAZER REIVINDICAÇÕES A OUTRA APENAS
          INDIVIDUALMENTE E NÃO COMO QUEIXOSO OU MEMBRO DE CLASSE EM QUALQUER
          PROCEDIMENTO COLETIVO OU REPRESENTATIVO. Se um tribunal ou um árbitro
          determinar que a renúncia à ação coletiva estabelecida neste parágrafo
          é nula ou não pode ser aplicada por qualquer motivo, ou que uma
          arbitragem pode ser realizada como ação coletiva, as disposições de
          arbitragem estabelecidas acima serão consideradas inteiramente nulas,
          e considerar-se-á que as partes não concordaram em arbitrar litígios.
        </Paragrafo>
        <Paragrafo>
          12.7.8 Exceção: Litígio envolvendo Propriedade Intelectual. Não
          obstante a decisão das partes de solucionar todos os litígios por meio
          de arbitragem, qualquer uma das partes pode solicitar ações de
          execução, determinações de validade ou reivindicações advindas de ou
          relacionadas a roubo, pirataria ou uso não autorizado de propriedade
          intelectual em qualquer tribunal estadual ou federal com jurisdição ou
          no Escritório de Patentes e Marcas Registradas no BRASIL, a fim de
          proteger seus direitos de propriedade intelectual (&quot;direitos de
          propriedade intelectual&quot; significa patentes, direitos autorais,
          direitos morais, marcas e segredos comerciais, mas não direitos de
          privacidade ou publicidade)
        </Paragrafo>
        <Paragrafo>
          12.7.9 Sobrevivência. Esta seção de Arbitragem Obrigatória e Renúncia
          a Ações Coletivas permanecerá em vigor após a rescisão de seu uso do
          Site.
        </Paragrafo>
        <Paragrafo>
          12.8 Acordo Integral. Estes Termos, que incluem o texto e os
          parágrafos anteriores à Cláusula 1ª, são a expressão final, completa e
          exclusiva do acordo entre você e a SAFY SIGN referente aos Se sob
          estes Termos. Estes Termos substituem comunicações orais e escritas
          anteriores com relação ao assunto em questão (incluindo contratos de
          confidencialidade referentes aos Serviços SAFY SIGN estabelecidos
          nestes Termos), e as partes renunciam de fiar-se em tais comunicações,
          e os Termos se aplicam além de qualquer outro termo que você busque
          impor ou incorporar ou que sejam implícitos pelo setor, por costume,
          por prática ou pelo processo de negociação. A SAFY SIGN rejeita, por
          meio deste, quaisquer termos adicionais ou conflitantes estabelecidos
          uma ordem de compra ou qualquer outro material de compra enviado pelo
          Cliente e condiciona a concordância unicamente com base nestes Termos
          e nas condições destes Termos, conforme oferecidos pela SAFY SIGN.
          Salvo permissão explícita nestes Termos, nenhuma modificação ou emenda
          a estes Termos entrará em vigor a menos que seja feita por escrito e
          assinada por um agente autorizado da parte contra a qual a modificação
          ou emenda está sendo solicitada. Em caso de inconsistência ou
          conflito, a ordem de precedência, em ordem decrescente de regência, é:
          (a) o Plano de Assinatura; (b) quaisquer anexos ou apêndices ao Anexo
          de Serviço; (c) a Anexo de Serviço; e (d) estes Termos.
        </Paragrafo>
        <Paragrafo>
          12.9 A SAFY SIGN será totalmente responsável por negligência
          intencional e culposa, bem como por quaisquer danos advindos de lesão
          à vida, corpo ou saúde causada pela SAFY SIGN. Em caso de
          responsabilidade por negligência leve, a SAFY SIGN será responsável
          somente por infração de uma obrigação essencial (&quot;Dever
          Substancial&quot;) e qualquer responsabilidade será limitada aos danos
          comuns e previsíveis e não incluirão responsabilidade por ausência de
          resultados econômicos, perda de lucro, ou danos indiretos. Um Dever
          Substancial, segundo o significado desta Cláusula 12.9, é uma
          obrigação cujo cumprimento é essencial para o desempenho destes Termos
          e em cujo cumprimento a parte contratante pode confiar.
        </Paragrafo>
        <Paragrafo>
          12.10 Idioma e Traduções. A SAFY SIGN poderá fornecer traduções destes
          Termos ou de outros termos ou políticas. As traduções são fornecidas
          para fins de informação e, se houver uma inconsistência ou conflito
          entre uma tradução e a versão em inglês, a versão em inglês terá
          precedência.
        </Paragrafo>
        <Paragrafo>
          12.11 Renúncia. A renúncia por qualquer uma das partes de qualquer
          infração de qualquer disposição destes Termos não implica em renúncia
          a qualquer outra violação. A falha de qualquer uma das partes em
          insistir no cumprimento estrito de qualquer acordo ou obrigação
          conforme estes Termos não será uma renúncia ao direito de tal parte de
          exigir cumprimento estrito no futuro e não será interpretada como uma
          novação destes Termos.
        </Paragrafo>
        <Paragrafo>
          12.12 Autonomia das Cláusulas. Se qualquer uma das partes destes
          Termos for considerada ilegal, inexequível ou inválida, as demais
          partes destes Termos permanecerão em pleno vigor e efeito. Se qualquer
          limitação material ou restrição à concessão de qualquer licença a você
          de acordo com estes Termos for considerada ilegal, inexequível ou
          inválida, a licença cessará imediatamente.
        </Paragrafo>
        <Paragrafo>
          12.13 Como Entrar em Contato Conosco. Se você tiver dúvidas sobre o
          Site ou os Termos, precificação, reclamações ou outras consultas,
          entre em contato com a SAFY SIGN nos endereços fornecidos no site.
        </Paragrafo>
        <h3>1. DEFINIÇÕES</h3>
        <Paragrafo>
          &quot;Assinatura SAFY SIGN&quot; significa o Serviço SAFY SIGN de
          assinatura eletrônica sob demanda, que fornece serviços de exibição
          on-line, entrega certificada, reconhecimento, assinatura eletrônica e
          armazenamento para “eDocumentos” por meio da Internet.
        </Paragrafo>
        <Paragrafo>
          &quot;Assinatura SAFY SIGN&quot; significa o Serviço SAFY SIGN de
          assinatura eletrônica sob demanda, que fornece serviços de exibição
          on-line, entrega certificada, reconhecimento, assinatura eletrônica e
          armazenamento para “eDocumentos” por meio da Internet
        </Paragrafo>
        <Paragrafo>
          &quot;Signatário&quot; significa uma pessoa designada por um Usuário
          Autorizado para acessar e/ou tomar ações com relação a “eDocumentos”
          enviados a tais indivíduos via Assinatura SAFY SIGN.
        </Paragrafo>
        <Paragrafo>
          &quot;Sistema&quot; refere-se aos sistemas e programas de software, as
          instalações de comunicação e rede e o hardware e equipamento usados
          pela SAFY SIGN ou seus agentes para tornar disponível o serviço
          Assinatura SAFY SIGN por meio da Internet.
        </Paragrafo>
        <Paragrafo>
          &quot;Dados de Transação&quot; significam os metadados associados a um
          Envelope (como histórico de transação, valor de hash de imagem, método
          e horário da exclusão do Envelope, nomes do remetente e do
          destinatário, endereços de e-mail e IDs de assinatura) que a SAFY SIGN
          poderá usar para gerar e manter a trilha de auditoria digital
          necessária para a Assinatura SAFY SIGN.
        </Paragrafo>
        <h3>2. LIMITAÇÕES ADICIONAIS AO USO E RESPONSABILIDADES DO CLIENTE</h3>
        <Paragrafo>
          2.1 A disponibilização do Assinatura SAFY SIGN pela SAFY SIGN está
          condicionada ao conhecimento e concordância do Cliente das seguintes
          disposições:
        </Paragrafo>
        <Alinea>
          (a) A Assinatura SAFY SIGN facilita a assinatura de “eDocumentos”
          entre as partes de tais “eDocumentos”. Nada neste Anexo de Serviço
          poderá ser interpretado de modo a tornar a SAFY SIGN uma parte em
          qualquer “eDocumento” processado por meio de Assinatura SAFY SIGN, e a
          SAFY SIGN não presta garantias ou declarações relacionadas às
          transações celebradas por meio de qualquer “eDocumento”
        </Alinea>
        <Alinea>
          (b) Entre a SAFY SIGN e o Cliente, o Cliente detém exclusivo controle
          sobre e responsabilidade pelo conteúdo, qualidade e formato de
          qualquer “eDocumento”. Sem limitar o exposto acima, todos os
          “eDocumentos”, juntamente com todas as mensagens inclusas dentro de um
          Envelope, armazenados no Sistema pela SAFY SIGN são mantidos em
          formato criptografado, não tendo a SAFY SIGN controle ou acesso sobre
          tal conteúdo, exceto na medida em que o acesso é solicitado por
          escrito e disponibilizado pelo Cliente para a SAFY SIGN;
        </Alinea>
        <Alinea>
          (c) Alguns tipos de contratos e documentos poderão estar excetuados
          nas leis de assinatura eletrônica (por exemplo, testamentos e acordos
          referentes a direito de família), ou poderão estar sujeitos a
          regulamentações específicas promulgadas pelas várias agências
          governamentais em relação a assinaturas eletrônicas e registros
          eletrônicos. A SAFY SIGN não é responsável por determinar se qualquer
          “eDocumento” em particular (i) está sujeito a uma exceção às leis
          aplicáveis sobre assinatura eletrônica; ou se está sujeito a
          promulgações de agências específicas; ou se pode ser formado
          legalmente por assinaturas eletrônicas;
        </Alinea>
        <Alinea>
          (d) A SAFY SIGN não é responsável por determinar o período de tempo em
          que contratos, documentos ou outros registros devem ser mantidos ou
          armazenados segundo as leis e regulamentos aplicáveis ou processos
          judiciais ou administrativos. Além disso, a SAFY SIGN não é
          responsável por produzir quaisquer dos “eDocumentos” do Cliente ou
          outros documentos a quaisquer terceiros;
        </Alinea>
        <Alinea>
          (e) Algumas leis ou regulamentos de proteção ao consumidor ou
          similares podem aplicar exigências especiais em relação a transações
          eletrônicas que envolvam um ou mais &quot;consumidores&quot;, como
          (entre outras) a exigência de que o consumidor consinta com o método
          de contratação e/ou que o consumidor receba uma cópia, ou o acesso a
          uma cópia, do registro escrito da transação em papel ou em outro meio
          não eletrônico. A SAFY SIGN não realiza nem é responsável por realizar
          as seguintes ações: (i) determinar se uma transação específica envolve
          ou não um &quot;consumidor&quot; ; (ii) conceder ou obter tais
          consentimentos ou determinar se tais consentimentos foram revogados;
          (iii) fornecer quaisquer informações ou divulgações em relação a uma
          tentativa de obter tais consentimentos; (iv) analisar legalmente,
          atualizar ou corrigir qualquer informação ou divulgação dada
          atualmente ou anteriormente; (v) fornecer tais cópias ou acesso, salvo
          disposição expressa na Documentação para todas as transações,
          consumeristas ou não; ou (vi) cumprir tais exigências especiais;
        </Alinea>
        <Alinea>
          (f) O Cliente compromete-se a determinar se algum
          &quot;consumidor&quot; está envolvido em um “eDocumento” apresentado
          para processamento por seus Usuários Autorizados e, em caso positivo,
          cumprir todas as exigências impostas pela lei sobre tais “eDocumentos”
          ou sua formação.
        </Alinea>
        <Alinea>
          (g) O Cliente concorda que seu(s) Administrador(es) da Conta designado
          possui poderes para fornecer à SAFY SIGN e aceitar da SAFY SIGN
          quaisquer autorizações, solicitações ou consentimentos necessários em
          nome do Cliente com relação à Conta do Cliente; e
        </Alinea>
        <Alinea>
          (h) O Cliente concorda que é exclusivamente responsável pela precisão
          e adequação das instruções dadas por ele e por seu pessoal à SAFY SIGN
          em relação aos Serviços, incluindo, sem limitação, instruções por meio
          de sua Conta, conforme feitas pelo Administrador da Conta designado.
          2.2 Planos de Assinatura comprados em SAFY SIGN.com não poderão ser
          usados em conjunto com APIs da SAFY SIGN, estando disponíveis para uso
          com um número limitado de integrações.
        </Alinea>
        <h3>3. ARMAZENAMENTO E EXCLUSÃO DE “eDOCUMENTOS”</h3>
        <Paragrafo>
          3.1 Envio, Armazenamento. Durante a Vigência, a SAFY SIGN enviará e
          armazenará “eDocumentos” segundo estes Termos do Plano de Assinatura.
          No entanto, a SAFY SIGN poderá estabelecer e fazer cumprir limites
          para uso razoável, a fim de evitar uso abusivo ou indevidamente
          oneroso da Assinatura SAFY SIGN. O Cliente, através de seu(s)
          Administrador(es) de Conta, poderá recuperar e armazenar cópias de
          “eDocumentos” para armazenamento fora do Sistema a qualquer momento
          durante o Prazo de Assinatura, contanto que o Cliente esteja em
          situação financeira regular segundo estes Termos, e poderá excluir ou
          remover “eDocumentos” do Sistema a seu critério
        </Paragrafo>
        <Paragrafo>
          3.2 “eDocumentos” Incompletos. A SAFY SIGN poderá, a seu critério
          exclusivo, excluir do Sistema eDocumentos incompletos, imediatamente e
          sem notificação, mediante: (a) a expiração do Envelope (caso o Cliente
          tenha estabelecido uma data de expiração para o Envelope, que não pode
          exceder 365 dias); ou (b) a expiração do Prazo de Assinatura; o que
          ocorrer primeiro. A SAFY SIGN não assume qualquer responsabilidade
          pela omissão ou incapacidade de uma parte de assinar eletronicamente
          quaisquer “eDocumentos” dentro de tal período de tempo.
        </Paragrafo>
        <Paragrafo>
          3.3 Exclusão. A SAFY SIGN poderá excluir uma Conta e Dados de Cliente,
          incluindo, entre outros, “eDocumentos” (completos ou não), mediante a
          expiração do Prazo de Assinatura ou a rescisão dos Termos conforme
          descrito na Cláusula 8.2.
        </Paragrafo>
        <Paragrafo>
          3.4 Retenção de Dados de Transação. A SAFY SIGN poderá reter Dados de
          Transação pelo período em que houver um motivo de negócios para
          fazê-lo.
        </Paragrafo>
        <h3>4. SEGURANÇA DA INFORMAÇÃO E DADOS PESSOAIS</h3>
        <Paragrafo>
          4.1 Responsabilidades do Cliente. A Assinatura SAFY SIGN oferece ao
          Cliente alguns recursos e funcionalidades que o Cliente pode optar por
          usar, incluindo a capacidade de recuperar e excluir “eDocumentos” no
          Sistema. O Cliente é responsável por devidamente: (a) configurar a
          Assinatura SAFY SIGN; (b) usar e fazer cumprir os controles
          disponíveis referentes à Assinatura SAFY SIGN (incluindo controles de
          segurança); e (c) tomar as medidas que o Cliente considerar
          necessárias, de acordo com a funcionalidade do Assinatura SAFY SIGN, a
          fim de manter a segurança, proteção, exclusão e backup apropriados dos
          Dados de Cliente, o que inclui controlar a gestão do acesso e
          credenciais de Usuários Autorizados ao Assinatura SAFY SIGN, controlar
          os Dados de Cliente que são Processados pelo Assinatura SAFY SIGN e
          controlar o arquivamento ou a exclusão de “eDocumentos” no Sistema. O
          Cliente reconhece que a SAFY SIGN não tem obrigação de proteger os
          Dados de Cliente, incluindo Dados Pessoais (definidos abaixo), que o
          Cliente optar por armazenar ou transferir fora da Assinatura SAFY SIGN
          (por exemplo, off-line ou em armazenamento local).
        </Paragrafo>
        <Paragrafo>
          4.2 Segurança da Informação. A SAFY SIGN tomará as medidas técnicas e
          organizacionais que sejam comercialmente razoáveis a fim de evitar
          acesso, uso, alteração ou divulgação ilegais ou não autorizados dos
          Dados de Cliente.
        </Paragrafo>
        <Paragrafo>
          {" "}
          4.3 Processamento/Transferência de Dados. Se o Cliente ou Afiliada do
          Cliente estiver estabelecido no Reino Unido, um Estado Membro da Área
          Econômica Europeia, ou Suíça (“EEA”), o Anexo de Proteção de Dados
          para Assinatura SAFY SIGN se aplicará ao processamento de qualquer
          Dado Pessoal (conforme definido na Cláusula 1ª do DPA).
        </Paragrafo>
        <h3>5. CONTAS E ADMINISTRAÇÃO ORGANIZACIONAL</h3>
        <Paragrafo>
          5.1 Cada Conta é associada a um único endereço de e-mail. Se o domínio
          do endereço de e-mail principal associado a uma Conta for de
          propriedade de uma empresa ou outra empresa/organização/entidade
          (&quot;Entidade&quot;) e tiver sido atribuído ao Cliente enquanto
          funcionário, subcontratado ou membro da entidade, como{" "}
          <u>seunome@seuempregador.com</u> ou
          <u>seunome@entidadesemfinslucrativos.org</u> (&quot;Endereço de E mail
          da Entidade&quot;), o Cliente concederá a essa Entidade e a seu(s)
          Administrador(es) de Conta permissão para: (a) identificar Contas
          criadas com um Endereço de E-mail da Entidade; e (b) restringir ou
          rescindir o acesso a uma Conta criada com um Endereço de E-mail da
          Entidade. O Cliente reconhece e concorda que a SAFY SIGN poderá
          auxiliar a Entidade em tal administração.
        </Paragrafo>
        <h3>6. PLANOS E PREÇOS DE ASSINATURA</h3>
        <Paragrafo>
          6.1 Os preços, recursos e opções da Assinatura SAFY SIGN dependem do
          Plano de Assinatura selecionado pelo Cliente, bem como eventuais
          mudanças instigadas pelo Cliente. Por exemplo: (a) se o Cliente
          adicionar Usuários Autorizados, a SAFY SIGN cobrará o valor da
          assinatura aplicável para cada Usuário Autorizado adicional; ou (b) se
          o Cliente enviar mais Envelopes do que estão incluídos em seu Plano de
          Assinatura, a SAFY SIGN poderá cobrar pelos envelopes adicionais ou
          transferir o Cliente para um novo Plano de Assinatura. O Cliente
          também poderá comprar serviços opcionais periodicamente ou por uso. A
          SAFY SIGN poderá alterar os preços ou alterar os recursos e opções de
          um Plano de Assinatura específico sem aviso prévio.
        </Paragrafo>
        <h3>7. PAGAMENTOS SAFY SIGN</h3>
        <Paragrafo>
          7.1 A Assinatura SAFY SIGN pode ser solicitada com “Pagamentos SAFY
          SIGN“, que significa a funcionalidade que permite ao Cliente submeter
          contratos, faturas e outros documentos aos Signatários via Assinatura
          SAFY SIGN para facilitar o envio das credenciais de pagamento e
          autorizações do Signatário diretamente para as aplicações de
          pagamento, gateways, processadores e prestadores de serviço de
          pagamento que armazenam, processam ou transmitem dados de titulares de
          cartão como parte da autorização ou liquidação (“Aplicações de
          Pagamento”).
        </Paragrafo>
        <Paragrafo>
          7.2 A disponibilização de Pagamentos SAFY SIGN pela SAFY SIGN está
          condicionado ao reconhecimento e a concordância do Cliente sobre as
          seguintes disposições:
        </Paragrafo>
        <Alinea>
          a. As atividades de processamento de pagamento viabilizada através de
          Pagamentos SAFY SIGN são entre o Cliente e um Aplicativo de Pagamento,
          ou qualquer outra parte designada pelo Cliente, e não com a SAFY SIGN.
          O Cliente é o único responsável por registrar e manter uma conta com
          as Aplicações de Pagamento, e por cumprir com todos os contratos
          termos de uso, ou outros termos e condições entre o Cliente e tais
          Aplicações de Pagamento. As Aplicações de Pagamento são empresas
          independentes, e não agentes, empregados ou subcontratados da SAFY
          SIGN. A SAFY SIGN não controla os métodos de pagamento (por exemplo,
          cartão de crédito, cartão de débito, transferências ACH)
          disponibilizados pelos Aplicativos de Pagamento por meio de Assinatura
          SAFY SIGN, nem os produtos ou serviços que são vendidos ou comprados
          pelo Cliente através do Pagamentos SAFY SIGN. O Cliente reconhece e
          concorda que a SAFY SIGN não pode garantir que um Signatário do
          Aplicativo de Pagamento ou que terceiros concluam o processamento de
          um pagamento ou que estejam autorizados a fazê-lo.
        </Alinea>
        <Alinea>
          b. O Cliente autoriza a SAFY SIGN a armazenar, processar e transmitir
          Dados de Cliente, conforme necessário, a um Aplicativo de Pagamento
          para viabilizar o processamento de pagamento entre o Cliente e
          terceiros designados pelo Cliente. Os Pagamentos SAFY SIGN armazenarão
          temporariamente informações recebidas do Cliente, como informações de
          conta para um Aplicativo de Pagamento, apenas para fins de viabilizar
          o processamento de pagamento.
        </Alinea>
        <Alinea>
          c. O Cliente é o único responsável por cumprir: (i) a quaisquer
          padrões aplicáveis desenvolvidos e publicados pelas redes de pagamento
          (tais como Visa, Mastercard, American Express e qualquer outra rede de
          crédito, débito ou rede de transferência eletrônica de fundos),
          incluindo, mas sem limitação, o Padrão de Segurança de Dados de Cartão
          de Pagamento (“PCI DSS”) atual; e (2) todas as leis e regulamentações
          aplicáveis ao processamento de pagamento realizado pelo Cliente por
          meio de Pagamentos SAFY SIGN; incluindo, mas sem limitação àqueles que
          podem ser aplicáveis ao Cliente: em relação a coleta e armazenamento
          de informações, incluindo credenciais de pagamento sobre os
          Signatários; fazer divulgações adequadas, claras e conspícuas
          relacionadas ao armazenamento e uso das credenciais de pagamento dos
          Signatários; e o uso de credenciais de pagamento armazenadas para
          receber pagamentos futuros. (d) O Cliente é o único responsável por
          todo e qualquer litígio com Aplicativos de Pagamento ou Signatários
          referentes a um processamento de pagamento que foi viabilizado por
          meio do Pagamentos SAFY SIGN, incluindo, mas sem limitação a: (1)
          estorno; (2) produtos ou serviços não recebidos; (3) devolução,
          entrega atrasada ou cancelamento de produtos ou serviços; (4)
          transações canceladas; (5) transações ou encargos duplicados; (6)
          débitos e créditos eletrônicos envolvendo contas bancárias, cartões de
          débito, cartões de crédito e emissões de cheque; e (7) o período de
          tempo necessário para realizar o processamento de pagamento.
        </Alinea>
        <Paragrafo>
          7.3 Na medida aplicável a SAFY SIGN pelo fornecimento de Pagamentos
          SAFY SIGN, a SAFY SIGN declara que está atualmente em conformidade, e
          continuará em conformidade com os padrões PCI DSS vigentes. A SAFY
          SIGN reconhece que os números de conta de cartões de crédito e débito
          ou dados relacionados que foram processados via Pagamentos SAFY SIGN
          são, conforme aplicável, propriedade exclusiva do Cliente, dos
          emissores de cartão de crédito, das redes de pagamento relevantes e
          das entidades licenciadas para processar transações de cartão de
          crédito e débito em nome do Cliente, e reconhece ainda que essas
          informações podem ser usadas pela SAFY SIGN somente para ajudar as
          partes anteriores a concluir as atividades de processamento descritas
          no Contrato.
        </Paragrafo>
        <h3>ANEXO DE SERVIÇO para SALAS SAFY SIGN</h3>
        <Paragrafo>
          Salvo definição em contrário neste Anexo de Serviço, os termos em
          letra maiúscula terão os significados dados a eles nestes Termos.
        </Paragrafo>
        <h3>1. DEFINIÇÕES</h3>
        <Paragrafo>
          &quot;Salas SAFY SIGN&quot; significam os Serviços SAFY SIGN para
          gestão de transações, que fornece exibição on-line de Salas, gestão de
          “eDocumentos”, pessoas e tarefas, e serviços de armazenamento de
          “eDocumentos” por meio da Internet.
        </Paragrafo>
        <Paragrafo>
          &quot;Dados de Salas&quot; significam os metadados associados a uma
          Sala, conforme determinado pelo Cliente ou sua Conta (como histórico
          de atividade de transação e marca de data e hora de atividades) e
          mantidos pela SAFY SIGN para fornecer Salas SAFY SIGN.
        </Paragrafo>
        <Paragrafo>
          &quot;Sala(s)&quot; significa o espaço de trabalho on-line para gestão
          de transações criado pelo Cliente no serviço Salas SAFY SIGN.
        </Paragrafo>
        <h3>2. SALAS SAFY SIGN</h3>
        <Paragrafo>
          2.1 Durante a Vigência e sujeito ao cumprimento destes Termos e deste
          Anexo de Serviço, o Cliente e seus Usuários Autorizados terão o
          direito de criar e/ou gerenciar Salas em sua Conta quando o Cliente se
          registrar por meio do método fornecido pela SAFY SIGN. O Cliente e
          seus Usuários autorizados terão o direito de criar e excluir Salas,
          fazer upload, remover e visualizar conteúdo, dados ou eDocumentos
          compartilhados nas Salas SAFY SIGN.
        </Paragrafo>
        <Paragrafo>
          2.2 Conta. O Cliente é o único responsável por: (a) a configuração da
          Conta; (b) a operação, o desempenho e a segurança de equipamento,
          redes ou outros recursos de computação do Cliente usados para conectar
          às Salas SAFY SIGN; (c) garantir que todos os Usuários Autorizados
          saiam ou façam logoff das Salas SAFY SIGN ao final de cada sessão; (d)
          manter a confidencialidade da Conta, de informações de identificação
          do usuário e de senhas usados em conjunto com as Salas SAFY SIGN; e
          (e) todos os usos da Conta pelo Cliente e de seus Usuários
          Autorizados. A SAFY SIGN reserva-se o direito de suspender o Salas
          SAFY SIGN se o Cliente utilizar indevidamente ou compartilhar
          informações de login com Usuários Autorizados ou com terceiros. O
          Cliente notificará imediatamente à SAFY SIGN qualquer acesso ou uso
          não autorizados de sua Conta, em qualquer violação de segurança. A
          SAFY SIGN não será responsável por perdas que o sua senha ou Conta por
          uma parte não autorizada, em virtude da falha do Cliente em proteger
          suas informações de login.
        </Paragrafo>
        <Paragrafo>
          2.3 Armazenamento e Exclusão. O Cliente poderá recuperar seus
          “eDocumentos” armazenados a qualquer momento durante a Vigência. A
          SAFY SIGN manterá todas as Salas e armazenará os “eDocumentos”
          associados durante a Vigência, por padrão. O Cliente poderá fechar ou
          excluir suas Salas ou excluir Documentos Eletrônicos em tais Salas a
          qualquer momento durante a vigência.
        </Paragrafo>
        <h3>3. RESPONSABILIDADES ADICIONAIS DO CLIENTE</h3>
        <Paragrafo>
          3.1 A prestação do serviço Salas SAFY SIGN pela SAFY SIGN está
          condicionada ao conhecimento concordância do Cliente das seguintes
          disposições:
        </Paragrafo>
        <Alinea>
          a. O Cliente é responsável por todas as atividades que ocorrerem nas
          Salas SAFY SIGN acessíveis por meio de sua Conta e por garantir que o
          uso de Salas SAFY SIGN esteja de acordo com estes Termos;
        </Alinea>
        <Alinea>
          b. o Cliente detém controle e responsabilidade exclusivos sobre o
          conteúdo, a qualidade e o formato de qualquer transação facilitada por
          meio de Salas SAFY SIGN. Nada neste Anexo de Serviço deverá ser
          interpretado de modo a tornar a SAFY SIGN uma parte em qualquer
          transação processada por meio do Salas SAFY SIGN, e a SAFY SIGN não
          presta qualquer declaração ou garantia alguma sobre as transações
          celebradas por meio do uso do Salas SAFY SIGN pelo Cliente;
        </Alinea>
        <Alinea>
          c. O Cliente é o único responsável por sua confiança sobre qualquer
          conteúdo, conselho, opinião, declaração, sugestão ou outra informação
          (&quot;Conteúdo de Terceiros&quot;) oferecidos por terceiros que sejam
          compartilhados e distribuídos por meio das Salas SAFY SIGN. Tal
          Conteúdo de Terceiros apresentado ou disseminado por meio do Salas
          SAFY SIGN representa unicamente a visão de seu respectivo autor ou
          fornecedor de tal conteúdo, que permanece unicamente responsável por
          sua precisão e confiabilidade;
        </Alinea>
        <Alinea>
          d. O Cliente é responsável por determinar o período de tempo em que
          “eDocumentos” devem ser retidos ou armazenados segundo as leis e os
          regulamentos aplicáveis ou processos legal ou administrativo. Além
          disso, o Cliente é responsável por produzir os “eDocumentos”
          disponibilizados a terceiros nas Salas; e
        </Alinea>
        <Alinea>
          e. O Cliente é responsável por cumprir leis ou regulamentos de
          proteção ao consumidor ou similares que podem aplicar requerimentos
          especiais em relação a transações eletrônicas que envolvem um ou mais
          &quot;consumidores&quot;, como (entre outras) a requerimentos de que o
          consumidor consinta com o método de contratação e/ou que o consumidor
          receba uma cópia, ou o acesso a uma cópia, do registro escrito da
          transação em papel ou em outro meio não eletrônico
        </Alinea>
        {props}
      </TermoContainer>
    </ContainerTerm>
  )
}
