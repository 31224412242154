/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import Modal from '@mui/material/Modal'
import React, { useEffect } from 'react'
import Card from '../../components/Card'
import { Outlet } from 'react-router-dom'
import NavBar from '../../components/NavBar'
import { useNavigate } from 'react-router-dom'
import { GiPartyPopper } from 'react-icons/gi'
import { useAuth } from './../../hooks/useAuth'
import { ToastContainer } from 'react-toastify'
import StatusBar from '../../components/StatusBar'
import { cookies } from './../../hooks/useCookies'
import useWindowSize from '../../hooks/useWindowSize'
import TermoContrato from '../../components/TermContract'
import { Container, Content, NavContainer } from './styles'
import SafeButton from './../../components/SafeButton/index'
import { SignUpModalText } from './../../pages/Signature/styles'
import Navigation from './../../components/NavBar/Navigation/index'
import { SignUpModalContainer } from '../../pages/Signature/styles'

export default function Core() {

  const [bonusModal, setBonusModal] = useState(false)

  const navigate = useNavigate()
  const { width } = useWindowSize()
  const { user } = useAuth()
  
  useEffect(() => {
    if(cookies.get('@Safysign:bonusPlan') === 'true') {
      setBonusModal(true)
      cookies.remove('@Safysign:bonusPlan')
    }
    if(user?.reset_password === true){
      navigate('./reset-password', {replace:true})
    }
    if(user?.plan === null && user?.company === null) {
      navigate('./select/plan', {replace:true})
    }
  }, [])
  
  return (
    <Container>
      <ToastContainer />
      <NavBar />
      <StatusBar />
      <NavContainer>
        {width > 700 ? <Navigation /> : <></>}
        <Content>
          <Card>
          <Outlet />
          <Modal open={bonusModal} size="xs" onClose={() => setBonusModal(false)}>
                <SignUpModalContainer>
                  <GiPartyPopper size='80%' />
                    {/* <SignUpDraw src={addFilesSvg} /> */}
                    <SignUpModalText>
                        Parabéns, você foi premiado com o primeiro mês do plano completamente por nossa conta, aproveite nossa plataforma!
                    </SignUpModalText>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div>
                                <SafeButton
                                    variant='primary'
                                    style={{ width: '90%' }}
                                    onClick={() => setBonusModal(false)}
                                >
                                    Uhu!
                                </SafeButton>
                        </div>
                    </div>
                </SignUpModalContainer>
            </Modal>
          {user?.term === true ? <></> : <TermoContrato />}
          </Card>
        </Content>
      </NavContainer>
    </Container>
  )

}