import App from './App'
import React from 'react'
import { IntlProvider } from 'rsuite'
import { createRoot } from 'react-dom/client'
import 'rsuite/dist/styles/rsuite-default.css'
import ptBR from 'rsuite/lib/IntlProvider/locales/pt_BR'

const root = createRoot(
  document.getElementById('root')
)

root.render(
  <React.StrictMode>
    {/* <GoogleReCaptchaProvider> */}
    <IntlProvider locale={ptBR} >
      <App />
    </IntlProvider>
    {/* </GoogleReCaptchaProvider> */}
  </React.StrictMode>
)