import styled from 'styled-components'
import { colors } from '../../../styles/colors'

import InputStyles from '../../Input'

export const Input = styled.input`
  background: ${colors.gray200};
  border-radius: 18px 0 18px 0;
  height: 32px;
  padding: 0 10px;
  text-align: center;
  border: none;
  border-bottom: 1px solid ${colors.dark};
  min-width: 10px;
  width: auto;
`

export const InputNormal = styled(InputStyles)``