import styled, { css } from 'styled-components'
import { colors } from '../../styles/colors'

export const Container = styled.div`
  width: 100%;
  text-align: right;
  
  .error {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 0.8rem;
    color: ${colors.primary};
  }

  input {
    ${
      props => props.isErrored 
      && css`
        border: 1px solid ${colors.primary}!important;
      `
    }
  }
`

export const InputStyles = styled.input`
  background: #ececec;
  border-radius: 8px;
  height: 40px;
  padding: 0 10px;
  border: none;
`