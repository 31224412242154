/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { toast } from "react-toastify"
import Modal from '@mui/material/Modal'
import { MdCheck } from 'react-icons/md'
import { Pagination } from "@mui/material"
import { GridLoader } from 'react-spinners'
import { CgUserList } from "react-icons/cg"
import * as api from "../../../services/api"
import { IoBusiness } from "react-icons/io5"
import { GiModernCity } from "react-icons/gi"
import Input from "../../../components/Input"
import { TiBusinessCard } from "react-icons/ti"
import { useState, useEffect, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import { AiOutlineFieldNumber } from "react-icons/ai"
import { cookies } from "./../../../hooks/useCookies"
import { currencyFormatter } from "../../../utils/INTL"
import SafeButton from "../../../components/SafeButton"
import useWindowSize2 from './../../../hooks/useWindowSize2'
import { RiMapPinUserFill, RiGovernmentFill } from "react-icons/ri"
import { BsFillTelephoneFill, BsFillMapFill } from "react-icons/bs"
import { InputMaskNormal as InputMask } from "../../../components/Input/InputMask"
import { FaCalendar, FaUser, FaGlobeAmericas, FaMapPin, FaMapSigns } from "react-icons/fa"
import { MdSmartphone, MdOutlineAlternateEmail, MdOutlineDomainVerification } from "react-icons/md"
import { ModalContainer, ModalContainer2, PlanoColuna, PlanoPlaceholder, ChangeModal, Container2 } from './../../../components/Plan/styles'

export default function NewAccount() {

  const dateRef = useRef()

  const navigate = useNavigate()

  const [planos, setPlanos] = useState([])
  const [loading, setLoading] = useState()
  const [valuesForSubmitUser,] = useState({})
  const [company, setCompany] = useState(false)
  const [valuesForSubmitCompany,] = useState({})
  const [showForm, setShowForm] = useState(false)
  const [valueInput, setValueInput] = useState({})
  const [placeholder, setPlaceholder] = useState(true)
  const [companyToSend, setCompanyToSend] = useState()
  const [formCompany, setFormCompany] = useState(false)
  const [response, setResponse] = useState()
  const [planSelected, setPlanSelected] = useState(null)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [confirmChangeModal, setConfirmChangeModal] = useState(false)
  const [switchForSubmitCompany, setSwitchForSubmitCompany] = useState(false)

  const width = useWindowSize2()

  let chroma = 0

  const initialValueUser = {
    first_name: "",
    last_name: "",
    cpf: "",
    age: "",
    email: "",
    phone: "",
  };

  const initialValueCompany = {
    first_name: "",
    last_name: "",
    cpf: "",
    age: "",
    email: "",
    phone: "",
    site: "",
    plan: "",
    cnpj: "",
    number: "",
    coupon: "",
    domain: "",
    balance: "",
    complement: "",
    responsible: "",
    fantasy_name: "",
    social_reason: "",
  };

  let isCompany = formCompany === true ? initialValueCompany : initialValueUser;

  useEffect(() => {
    getCurrentPage(1)
  }, [])

  function getCurrentPage(currentPage) {

    api
      .get(`/plan/list/?visibility=1&page=${currentPage}&category=2`)
      .then((response) => {
        if (response?.status === 200) {
          setPlanos(response?.data.detail.results)
          setResponse(response.data.detail.count)
          setPlaceholder(false)
        } else {
          toast.error('Não foi possivel resgatar a listagem de planos, tente novamente mais tarde', {
              position: toast.POSITION.TOP_RIGHT
            })
        }
      })
      .catch((error) => {
        toast.error('Não foi possivel resgatar a listagem de planos, tente novamente mais tarde', {
            position: toast.POSITION.TOP_RIGHT
          })
      })
  }

  // function takeValueNumber(event) {
  //   let addNumber = valueInput;
  //   addNumber.company_adress_number = event.target.value;
  //   setValueInput(addNumber);
  // }

  function submitUserForCompany(idCompany) {
    let data = { ...valuesForSubmitUser, company: idCompany };

    api
      .post("/user/create/", data, "application/json")
      .then((response) => {
        if (response?.status === 201) {
          navigate("../", { replace: true });
          cookies.set("@Safysign:verify-toast-company", "true");
        } else if (response?.response?.status === 400) {
          toast.success(
            "Empresa cadastrada com sucesso, favor verificar novamente o usuário informado",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          toast.error(response.data.detail === 'usuário com este email já existe.' ? 'usuário com este email já existe.' : 'Verifique os campos e tente novamente', {
              position: toast.POSITION.TOP_RIGHT,
            });

          setSwitchForSubmitCompany(true);

          cookies.set("@Safysign:company-for-user-error", idCompany);
        }
      })
      .catch((error) => {
        toast.error("Tente novamente mais tarde", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }

  const apiCep = async (event) => {
    let cep = event.target.value;
    let changedCep = "";

    if (cep.includes("-")) {
      changedCep = cep.replace("-", "");
    }
    if (changedCep.length < 8) {
      setValueInput({
        bairro: "",
        cep: "",
        cidade: "",
        logradouro: "",
        uf: "",
        // complement: "",
        ddd: "",
        gia: "",
        ibge: "",
        siafi: "",
      });
    }
    if (changedCep.length === 8) {
      await fetch(`https://viacep.com.br/ws/${changedCep}/json`, {
        method: "GET",
      }).then(async (response) => {
        let data = await response.json();
        setValueInput({
          bairro: data.bairro,
          cep: data.cep,
          cidade: data.localidade,
          logradouro: data.logradouro,
          uf: data.uf,
          ddd: data.ddd,
          gia: data.gia,
          ibge: data.ibge,
          siafi: data.siafi,
        });
      });
    }
  };

  const valuesForSubmit = () => {
    const dataToSubmit = {
      ...values,
      address: {
        ...valueInput,
        complement: values.complement,
        company_address_number: values.company_address_number,
      },
    };
    const userOfCompany = {
      first_name: dataToSubmit.first_name,
      last_name: dataToSubmit.last_name,
      cpf: dataToSubmit.cpf,
      age: dataToSubmit.age,
      email: dataToSubmit.email,
      phone: dataToSubmit.phone,
    };
    const companyToSubmit = {
      address: dataToSubmit.address,
      site: dataToSubmit.site,
      plan: dataToSubmit.plan,
      cnpj: dataToSubmit.cnpj,
      number: dataToSubmit.number,
      coupon: dataToSubmit.coupon,
      domain: dataToSubmit.domain,
      balance: dataToSubmit.balance,
      // complement: dataToSubmit.complement,
      responsible: dataToSubmit.responsible,
      fantasy_name: dataToSubmit.fantasy_name,
      social_reason: dataToSubmit.social_reason,
    };

    setCompanyToSend({ ...userOfCompany, ...companyToSubmit })
    setShowForm(true)
  };

  // useEffect(() => {
  //   if (
  //     Object.keys(valuesForSubmitCompany).length > 0 &&
  //     Object.keys(valuesForSubmitUser).length > 0
  //   ) {
  //     submit();
  //   }
  // }, [valuesForSubmitCompany, valuesForSubmitUser]);

  const { handleChange, handleSubmit, values } = useFormik({
    initialValues: {
      isCompany,
      complement: "",
      company_address_number: ""
    },
    onSubmit: company === true ? valuesForSubmit : submit,
  });

  function submit() {
    setLoading(true);
    if (company === false) {
      api
        .post("/user/create/", values, "application/json")
        .then((response) => {
          toast.dismiss();
          if (response.status === 201) {
            navigate("../", { replace: true });
            cookies.set("@Safysign:verify-toast", "true");
          } else if (response.response.status === 400) {
            toast.error(response.response.data.detail, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Tente novamente mais tarde", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          toast.error("Tente novamente mais tarde", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      if (switchForSubmitCompany === false) {
        api
          .post("/company/create/", valuesForSubmitCompany, "application/json")
          .then((response) => {
            if (response.status === 201) {
              let idCompany = response.data.id;
              submitUserForCompany(idCompany);
           } else if (response.response.status === 400 && response.response.data.detail.users === "0") {
              let IdCompany = response.response.data.detail.id
              submitUserForCompany(IdCompany);
            } else if (response.response.status === 400) {
              toast.error("Já existe uma empresa cadastrada com este cnpj, favor entre em contato com o administrador", {
                  position: toast.POSITION.TOP_RIGHT,
                });
            }
          })
          .catch((error) => {
            toast.error("Ocorreu um erro, tente novamente mais tarde", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
      if (switchForSubmitCompany === true) {
        api
          .post("/company/create/", valuesForSubmitCompany, "application/json")
          .then((response) => {
            if (response.status === 201) {
              let idCompany = response.data.id;
              submitUserForCompany(idCompany);
            } else if (response.response.status === 400 && response.response.data.detail.users === "0") {
              let IdCompany = response.response.data.detail.id
              submitUserForCompany(IdCompany);
            } else if (response.response.status === 400 && response.response.data.detail.users !== "0") {
              toast.error("Já existe uma empresa cadastrada com este cnpj, favor entre em contato com o administrador", {
                  position: toast.POSITION.TOP_RIGHT,
                });
            } 
            else {
              toast.error("Ocorreu um erro, tente novamente mais tarde", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch((error) => {
            if (error.status !== 200) {
              toast.error("Ocorreu um erro, tente novamente mais tarde", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          });
      }
    }
    setTimeout(() => { setLoading(false) }, 1000)
  }

  function sendEmail() {
    api
    .post('/company/create/notificate/', companyToSend, "application/json")
    .then((response) => {
        if (response.status === 200) {
          navigate('/', { replace: true })
          toast.success('Empresa cadastrada, seus dados foram enviados para nosso time comercial e logo entraremos em contato para finalização do cadastro!', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false
          })
        } else {
          toast.error('Ocorreu um erro ao cadastrar a empresa, favor, tente novamente mais tarde', {
              position: toast.POSITION.TOP_RIGHT
            })
        }
      })
      .catch((error) => {
        toast.error('Ocorreu um erro ao cadastrar a empresa, favor, tente novamente mais tarde', {
          position: toast.POSITION.TOP_RIGHT
        })
      })
  }

  return (
    <>
      <div>
        <form onSubmit={handleSubmit}>
          <div>
            <div className="container-button">
              <SafeButton
                type="button"
                onClick={() => {
                  setFormCompany(!formCompany);
                  setCompany(!company);
                }}
                variant="secondary"
              >
                {company ? "Cadastrar como usuário" : "Cadastrar como empresa"}
              </SafeButton>
            </div>
            <div className="container-input">
              <div className="input-wrapper">
                <h3>DADOS DO USUÁRIO</h3>
                <div className="container-input">
                  <div className="input-wrapper">
                    <div className="form-icon">
                      <FaUser size={25} />
                    </div>
                    <Input
                      required
                      type="text"
                      name="first_name"
                      placeholder="Nome"
                      className="full-input"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-wrapper">
                    <div className={"form-icon"}>
                      <FaUser size={25} />
                    </div>
                    <Input
                      type="text"
                      name="last_name"
                      placeholder="Sobrenome"
                      className="full-input"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="container-input">
                  <div className="form-icon">
                    <CgUserList size={20} />
                  </div>
                  <InputMask
                    required
                    mask="999.999.999-99"
                    name="cpf"
                    placeholder="CPF"
                    maskChar={null}
                    onChange={handleChange}
                  />
                </div>
                <div className="container-input">
                  <div className="form-icon">
                    <FaCalendar size={20} />
                  </div>
                  <input
                    ref={dateRef}
                    onFocus={() => {
                      dateRef.current.type = 'date'
                    }}
                    onBlur={() => {
                      if (dateRef.current.value === undefined || dateRef.current.value === null || dateRef.current.value === '') {
                        dateRef.current.type = 'text'
                      }
                    }}
                    required
                    type="text"
                    maxLength={8}
                    name="age"
                    placeholder="Data de nascimento"
                    className="input-date"
                    onChange={handleChange}
                  />
                </div>
                <div className="container-input">
                  <div className="form-icon">
                    <MdOutlineAlternateEmail size={20} />
                  </div>
                  <Input
                    required
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    className="full-input"
                    onChange={handleChange}
                  />
                </div>
                <div className="container-input">
                  <div className="form-icon">
                    <MdSmartphone size={20} />
                  </div>
                  <InputMask
                    required
                    mask="(99) \9 9999-9999"
                    name="phone"
                    placeholder="Celular"
                    onChange={handleChange}
                  />
                </div>
              </div>
              {company ? (
                <div className="input-wrapper">
                  <h3>DADOS DA EMPRESA</h3>
                  <div className="container-input">
                    <div className="input-wrapper">
                      <div className="form-icon">
                        <IoBusiness size={25} />
                      </div>
                      <Input
                        required={company}
                        type="text"
                        name="social_reason"
                        placeholder="Razão social"
                        className="full-input"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="input-wrapper">
                      <div className={"form-icon"}>
                        <IoBusiness size={25} />
                      </div>
                      <Input
                        require
                        type="text"
                        name="fantasy_name"
                        placeholder="Nome fantasia"
                        className="full-input"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="container-input">
                    <div className="form-icon">
                      <TiBusinessCard size={20} />
                    </div>
                    <InputMask
                      required={company}
                      mask="99.999.999/9999-99"
                      name="cnpj"
                      placeholder="CNPJ"
                      maskChar={null}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="container-input">
                    <div className="form-icon">
                      <MdOutlineDomainVerification size={20} />
                    </div>
                    <Input
                      type="text"
                      name="domain"
                      placeholder="Dominio da empresa. Ex.: minhaempresa.com.br"
                      className="full-input"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="container-input">
                    <div className="form-icon">
                      <FaGlobeAmericas size={20} />
                    </div>
                    <Input
                      type="text"
                      name="site"
                      placeholder="Site da empresa"
                      className="full-input"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="container-input">
                    <div className="input-wrapper">
                      <div className="form-icon">
                        <MdOutlineAlternateEmail size={20} />
                      </div>
                      <Input
                        required={company}
                        type="text"
                        name="responsible"
                        placeholder="E-mail do responsável pela empresa"
                        className="full-input"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="input-wrapper">
                      <div className="form-icon">
                        <BsFillTelephoneFill size={20} />
                      </div>
                      <InputMask
                        mask="(99) 9999-9999"
                        name="number"
                        placeholder="Telefone da empresa"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            {company ? (
              <>
                <h3 style={{ paddingTop: "20px" }}>ENDEREÇO EMPRESARIAL</h3>
                <div className="container-input">
                  <div className="input-wrapper">
                    <div className="form-icon">
                      <BsFillMapFill size={20} />
                    </div>
                    <InputMask
                      required
                      mask="99999-999"
                      name="cep"
                      maskChar={null}
                      placeholder="CEP"
                      onChange={apiCep}
                    />
                  </div>
                  <div className="input-wrapper">
                    <div className="form-icon">
                      <FaMapPin size={20} />
                    </div>
                    <Input
                      required={company}
                      disabled
                      type="text"
                      name="logradouro"
                      value={valueInput.logradouro}
                      placeholder="Logradouro"
                      className="full-input"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-wrapper">
                    <div className="form-icon">
                      <AiOutlineFieldNumber size={20} />
                    </div>
                    <Input
                      required={company}
                      type="number"
                      name="company_address_number"
                      placeholder="Número"
                      className="full-input"
                      value={values.company_address_number}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="container-input">
                  <div className="input-wrapper">
                    <div className="form-icon">
                      <FaMapSigns size={20} />
                    </div>
                    <Input
                      required={company}
                      disabled
                      type="text"
                      name="bairro"
                      value={valueInput.bairro}
                      placeholder="Bairro"
                      className="full-input"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-wrapper">
                    <div className="form-icon">
                      <RiMapPinUserFill size={20} />
                    </div>
                    <Input
                      type="text"
                      name="complement"
                      placeholder="Complemento"
                      value={values.complement}
                      className="full-input"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="container-input">
                  <div className="input-wrapper">
                    <div className="form-icon">
                      <GiModernCity size={20} />
                    </div>
                    <Input
                      required={company}
                      disabled
                      type="text"
                      name="localidade"
                      value={valueInput.cidade}
                      placeholder="Cidade"
                      className="full-input"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-wrapper">
                    <div className="form-icon">
                      <RiGovernmentFill size={20} />
                    </div>
                    <Input
                      required={company}
                      disabled
                      type="text"
                      name="uf"
                      value={valueInput.uf}
                      placeholder="UF"
                      className="full-input"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="container-button">
              <Link to="/" className="link-secondary">
                Fazer login
              </Link>
              <SafeButton 
                type='submit' 
                loading={loading} 
                disabled={loading}
                >
                Cadastrar
              </SafeButton>
            </div>
          </div>
        </form>
      </div>
      <Modal
        open={showForm}
        onClose={() => {
          setShowForm(false)
          setPlanSelected(null)
        }}
      >
        <ModalContainer2 style={{ maxHeight: '96vh', overflow: 'scroll' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: "center", justifyContent: 'flex-start', width: '23%', marginLeft: '2%' }}>
              <SafeButton
                variant='secondary'
                onClick={() => {
                  setShowForm(false)
                  setPlanSelected(null)
                }}
                style={{ position: 'absolute' }}
              >
                Voltar
              </SafeButton>
            </div>
            {width[0] > 767 ? <div style={{ width: '54%', borderRadius: '50px', height: '69.21px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Pagination count={Math.ceil(response / 10)} variant="outlined" shape="rounded" onChange={(e, value) => { getCurrentPage(value); }} />
              {/* <p style={{textAlign:'center', fontSize:`${width[0] > 767 ? width[0]/21.3 : width[0]/15}px`, position:'relative', color:'black', fontFamily:'sans-serif'}}> Selecione um plano: </p> */}
            </div> : <div style={{ height: '69.21px' }}></div>}
            <div style={{ display: 'flex', alignItems: "center", justifyContent: 'flex-end', width: '23%', marginRight: '2%' }}>
              <SafeButton
                onClick={() => {
                  if (planSelected !== null) {
                    setCompanyToSend({ ...companyToSend, plan: planSelected?.name })
                    setConfirmChangeModal(true)
                  } else {
                    toast.error('Selecione pelo menos um plano', {
                      position: toast.POSITION.TOP_RIGHT
                    })
                  }
                }}
                style={{ position: 'absolute' }}
              >
                Finalizar Cadastro
              </SafeButton>
            </div>
          </div>
          <Container2 style={{ gridTemplateColumns: `repeat(auto-fill, minmax(240px, 1fr))` }}>
            {planos.map(plano => {
              if (chroma > 3) {
                chroma = 0
              } else {
                chroma++
              }

              return (
                <PlanoColuna
                  style={{ margin: '0', width: '96%', marginTop: '1rem' }}
                  key={plano?.id}
                  chroma={chroma}
                >
                  <div className='title'>
                    <h2>{plano?.name}</h2>
                  </div>
                  <ul style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <li style={{ fontSize: '1.7rem', width: 'max-content', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                      <MdCheck size={20} />{' '}
                      {
                        plano?.users > 9999
                          ? '+9999 usuários simultâneos.'
                          : plano?.users + ' usuários simultâneos.'
                      }
                    </li>
                    <li style={{ fontSize: '1.7rem' }}>
                      <MdCheck size={20} />{' '}
                      {
                        plano?.processes + ' documentos.'
                      }
                    </li>
                  </ul>
                  <p>{plano?.description}</p>
                  <h3>
                    {
                      plano.price === '0.0' || plano.price === '00' || plano.price === '0' || plano.price === '0.00'
                        ? 'Grátis'
                        : currencyFormatter(plano?.price)}{
                      plano.price === '0.0' || plano.price === '00' || plano.price === '0' || plano.price === '0.00'
                        ? ''
                        : plano?.plan_type === '1'
                          ? '/mês'
                          : '/ano'
                    }
                  </h3>
                  <button
                    style={
                      {
                        background: planSelected?.id === plano?.id && '#bbb',
                        cursor: planSelected?.id === plano?.id && 'no-drop'
                      }}
                    disabled={planSelected?.id === plano?.id}
                    onClick={() => {
                      setPlanSelected({ id: plano?.id, name: plano?.name })
                    }}
                  >
                    {planSelected?.id === plano?.id ? 'Selecionado' : 'Selecionar'}
                  </button>
                </PlanoColuna>
              )
            })}
            <PlanoPlaceholder visible={placeholder}>
              <GridLoader color="#aaa" />
            </PlanoPlaceholder>
            <PlanoPlaceholder visible={placeholder}>
              <GridLoader color="#aaa" />
            </PlanoPlaceholder>
            {
              width[0] > 1024
                ? <>
                  <PlanoPlaceholder visible={placeholder}>
                    <GridLoader color="#aaa" />
                  </PlanoPlaceholder>
                  <PlanoPlaceholder visible={placeholder}>
                    <GridLoader color="#aaa" />
                  </PlanoPlaceholder>
                  <PlanoPlaceholder visible={placeholder}>
                    <GridLoader color="#aaa" />
                  </PlanoPlaceholder>
                </>
                : ''
            }
          </Container2>
          {width[0] < 768 ? <div style={{ width: '100%', display: "flex", justifyContent: 'center', alignItems: 'center' }}>
            <Pagination count={Math.ceil(response / 10)} variant="outlined" shape="rounded" onChange={(e, value) => { getCurrentPage(value); }} />
          </div> : ''}
        </ModalContainer2>
      </Modal>
      <Modal
        open={confirmChangeModal}
      >
        <ModalContainer>
          <ChangeModal>
            <div style={{ fontSize: '2rem' }}>
              <p>Tem certeza que deseja finalizar o cadastro?</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '1rem' }}>
              <div>
                <SafeButton
                  variant='secondary'
                  onClick={() => {
                    setConfirmChangeModal(false)
                    setShowForm(true)
                  }}
                  loading={buttonLoading}
                >
                  Cancelar
                </SafeButton>
              </div>
              <div style={{ paddingLeft: '0.5rem' }}>
                <SafeButton
                  variant='primary'
                  loading={buttonLoading}
                  onClick={() => {
                    setButtonLoading(true)
                    sendEmail()
                  }}
                >
                  Finalizar
                </SafeButton>
              </div>
            </div>
          </ChangeModal>
        </ModalContainer>
      </Modal>
    </>
  );
}