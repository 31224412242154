import styled from 'styled-components'
import { colors } from './../../styles/colors';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const Documento = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colors.gray200};
  border-radius: 8px;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  margin: 0.5rem 0;
  transition: 0.5s;
  flex-wrap: wrap;
  height: auto;

  @media (max-width:426px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid ${colors.primary};
  }
  
  & > p + p {
    margin: 0;
  }

  & > p {
    width: 20rem;
  }

  & > strong {
    width: 20rem;
    font-size: 1.6rem;
  }

  .space{
    width: 300px;
  }
`

export const Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => {
    switch (props.chroma) {
      case 1:
        return 'orange'
      case 2:
        return '#60159a'
      case 3:
        return 'black'
      case 4:
        return 'red'
      default:
        return '#333'
    }
  }};
`

export const ModalContainer = styled.div`
  display:flex;
  background-color: ${colors.gray300};
  border-radius: 10px;
  padding: 16px;
  gap: 5px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
  height: 85vh;

  @media(max-width:1066px){
    width: 95%;
  }

  @media(max-width:700px){
    flex-wrap: wrap;
    height: 85vh;
    overflow-y: auto;
    width: 95%;
    align-items: center;
    justify-content: center;
  }
  
  & > aside {
    background: ${colors.gray300};
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 7px;
        position: absolute;
        border-radius: 80px;
        height: 7px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${colors.gray300};
        border-radius: 80px;
      }
  }
`

export const Title = styled.ul`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: ${colors.gray200};
  padding-left: 10px;
  padding-right: 10px;

  a{
    text-decoration: none;
  }

  p{
    font-size: 1.8rem;
    font-weight: 900;
    color: ${colors.primary};

    span{
    color: ${colors.darker};
    }

    @media (max-width:450px){
      font-size: 22px;
    }
  }
`

export const ModalContainerDelete = styled.div`
  display:flex;
  background-color: ${colors.gray300};
  border-radius: 10px;
  padding: 16px;
  gap: 5px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  @media(max-width:1066px){
    height: 20vh;
  } 

  @media(max-width:700px){
    flex-wrap: wrap;
    height: 85vh;
    overflow-y: auto;
    width: 95%;
    align-items: center;
    justify-content: center;
  }
  

.content{
  height: 100px;
}

.buttons{
  display: flex;
  width: 100px;
  margin: auto;
  gap: 15px;
}
`

export const Info = styled.p`
  background: ${props => {
    switch (props.chroma) {
      case 1:
        return 'green'
      case 2:
        return 'red'
      case 3:
        return `${colors.gray300}`
      default:
        return '#333'
    }
  }};
  color: #fff;
  width: 100%;
  line-height: 25px;
  text-align: center;
  margin: 1rem 0;
  padding: 0.5rem;
  border-radius: 6px;
`

export const FilterMenu = styled.div`
  width: 50%;
  background: #A5D4FA;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0.5rem;
  padding: 1rem;
  color: #333;
  font-weight: 600;
  font-size: 1.8rem;
  height: auto;

  @media(max-width: 800px){
    width: 90%;
  }

  & > div {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;

    & > input {
      margin-right: 0.25rem;
    }

    & > label {
      margin-right: 2rem;
    }
  }
`

export const FilterSubMenu = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0.5rem;
  padding: 1rem;
  color: #333;
  font-weight: 600;
  font-size: 1.5rem;
  height: auto;

  & > div {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 10px;

    & > input {
      margin-right: 0.25rem;
    }

    & > label {
      margin-right: 2rem;
    }
  }

  & > div > div#data-range > content {
      width: 88%;
      color: ${colors.primary};
      background-color: ${colors.primary};
    }

  @media(max-width: 360px){
    & > div > div#data-range {
      width: 88%;
    }
  }
`

export const CreatedBy = styled.p`
  width: 100%;
  text-align: center;
  background: #fff;
  padding: 0.5rem;
  border-radius: 8px;
`

export const Pagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: auto;

  & > button {
    margin: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    border-radius: 8px;
    border: none;
    color: ${colors.primary};
    
  }
`
/* export const ContainerModal = styled.div`
  display:flex;
  flex-direction: column;
  background-color: var(--gray300);
  border-radius: 10px;
  padding: 16px;
  gap: 5px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1100px;
  
` */