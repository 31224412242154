import styled from "styled-components"
import { colors } from "../../../styles/colors"

export const FixedContainer = styled.aside`
  max-width: 320px;
  height: 95%;
  display: flex;
  flex-direction: column;
  top: 0;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 30px;

  img {
    width: 40px;
    padding-left: 6px;
  }

  .clicked {
    background: ${colors.white};
    border: 2px solid ${colors.primary};
    
    p {
      color: ${colors.primary};
    }
  }

  & > div > a, & > a {
    font-size: 2rem;
    font-weight: 600;
    transition: 0.5s;
    text-decoration: none;

    & > div {
      display: flex;
      width: 98%;
      height: 50px;
      align-items: center;
      background-color: ${colors.gray200};
      margin-bottom: 6px;
      border-radius: 8px;
      margin-left: auto;
      margin-right: auto;

      p {
        font-size: 1.8rem;
        font-weight: 900;
        color: ${colors.darker};
      }

      & > svg {
        font-size: 3rem;
        color: ${colors.primary};
        padding-left: 10px;
        padding-right: 8px;
      }

      &:hover {
        background-color: ${colors.white};
      }
    }

    & > .admin-container {
      display: flex;
    }
  }
`

export const AdminContainer = styled.aside`
  flex-direction: column;
  top: 0;

  & > a {
    font-size: 2rem;
    font-weight: 600;
    transition: 0.5s;
    text-decoration: none;

    & > div {
      display: flex;
      width: 98%;
      height: 50px;
      align-items: center;
      background-color: ${colors.gray200};
      margin-bottom: 6px;
      border-radius: 8px;
      margin-left: auto;
      margin-right: auto;

      & > svg {
        font-size: 3rem;
        color: ${colors.primary};
        padding-left: 10px;
        padding-right: 6px;
      }

      p {
        font-size: 1.8rem;
        font-weight: 900;
        color: ${colors.darker};
      }

      &:hover {
        background-color: ${colors.white};
      }
    }

    & > .admin-container {
      display: flex;
    }
  }
`;

export const ShowNav = styled.div`
  width: 100%;
  color: ${colors.white};
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;

  & > svg {
    font-size: 3rem;
  }
`

export const Container = styled.div`
  width: 380px;
  height: 100%;
  box-sizing: content-box;
  min-width: 250px;
  border-radius: 0 0 10px 10px;
  background-color: ${colors.gray300};

  @media (max-width: 700px) {
    width: 100%;
  }
`
