import axios from "axios";
import { cookies } from "../hooks/useCookies";

const api = axios.create({
  baseURL: "https://service.safysign.com.br"
});

async function refreshToken() {
  return new Promise(async (resolve) => {
    await api
      .post("/user/token/refresh/", {
        refresh: cookies.get("@Safysign:refresh"),
      })
      .then((response) => {
        const responseData = response.data;
        cookies.set("@Safysign:refresh", responseData.refresh);
        cookies.set("@Safysign:access", responseData.access);
        defaults.headers.Authorization = `Bearer ${responseData.access}`;
        resolve(true);
      })
      .catch((error) => {
        cookies.remove('@Safysign:user')
        cookies.remove('@Safysign:access')
        cookies.remove('@Safysign:refresh')
        window.location.pathname = '/'
        resolve(error)
      });
  });
}

export function get(path) {
  return new Promise(async (resolve) => {
    await api
      .get(path, defaults)
      .then((response) => {
        resolve(response);
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          var res = await refreshToken();
          if (res) {
            await api
              .get(path, defaults)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                resolve(error);
              });
          }
        } else {
          resolve(error);
        }
      });
  });
}

export function destroy(path) {
  return new Promise(async (resolve) => {
    await api
      .delete(path, defaults)
      .then((response) => {
        resolve(response);
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          var res = await refreshToken();
          if (res) {
            await api
              .delete(path, defaults)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                resolve(error);
              });
          }
        } else {
          resolve(error.data);
        }
      });
  });
}

export function put(path, data) {
  return new Promise(async (resolve) => {
    await api
      .put(path, data, defaults)
      .then((response) => {
        resolve(response);
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          var res = await refreshToken();
          if (res) {
            await api
              .put(path, data, defaults)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                resolve(error);
              });
          }
        } else {
          resolve(error);
        }
      });
  });
}

export function post(path, data, { content_type }) {
  let _defaults = {
    headers: {
      Authorization: defaults.headers.Authorization,
      "Content-Type": defaults.headers["Content-Type"],
    },
  };
  if (content_type) {
    _defaults.headers["Content-Type"] = content_type;
  }
  return new Promise(async (resolve) => {
    await api
      .post(path, data, _defaults)
      .then((response) => {
        resolve(response);
      })
      .catch(async (error) => {
        if (
          error.response.status === 401 &&
          error.response.data.detail !== "Usuário e/ou senha incorreto(s)"
        ) {
          var res = await refreshToken();
          if (res) {
            await api
              .put(path, data, defaults)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                resolve(error);
              });
          }
        } else {
          resolve(error);
        }
      });
  });
}

export const defaults = {
  headers: {
    Authorization: "",
    "Content-Type": "application/json",
  },
};
