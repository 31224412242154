import styled from 'styled-components'
import { colors } from '../../styles/colors'
const loginBackground = require('../../assets/images/fundo_LOGIN.png')

export const SignContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 60px 0 80px 0;

  @media (max-width: 500px) {
    padding: 45px 0px 45px 0px;
  }

  background-image: url(${loginBackground});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

export const SingPanelContainer = styled.div`
  position: relative;
`

export const SignInPanel = styled.div`
  padding: 20px 30px;
  background-color: ${colors.white};
  border-radius: 35px;
  width: 100%;
  position: relative;
  z-index: 2;
  text-align: center;

  img.logo {
    border-bottom: 1px solid ${colors.primary};
    max-width: 290px;
    width: 100%;
  }
`

export const BorderOffset = styled.div`
  box-shadow: inset 0px 0px 0px 2px ${colors.primary};
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 35px;
  left: 25px;
  top: 25px;

  @media (max-width: 500px) {
    display: none;
  }
`

export const SignFormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  h4 {
    background-color: ${colors.primary};
    color: white;
    padding: 3px 10px;
    border-radius: 12px;
    display: inline-block;
    text-align: center;
    margin-bottom: 30px;
  }

  input {
    color: ${colors.secondary};
    width: 100%;
  }

  button {
    min-width: 92px;

    @media (max-width: 500px) {
      width: 100%;
    }
  }

  form {
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .cadastrar-mobile {
    max-width: 100%;
    width: 192px;

    @media (max-width: 500px) {
      max-width: 80%;
    }
  }

  .company-container {
    width: 1000px;
    padding-right: 60px;

    @media (max-width: 780px) {
      padding-right: 0px;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .address-form {
    @media (max-width: 780px) {
      width: 100% !important;
      margin-bottom: 10px;
    }
  }

  .company-form {
    width: 50%;
    float: left;
    padding: 0px 20px 0px 20px;
    margin-bottom: 30px;

    @media (max-width: 780px) {
      width: 100% !important;
      margin-bottom: 0px !important;
    }

    @media (min-width = 300px) {
      width: 100%;
      float: unset;
    }
  }

  .address-form {
    width: 50%;
    float: right;
    padding: 0px 20px 0px 20px;

    @media (min-width = 300px) {
      width: 100%;
      float: unset;
    }
  }

  .user-form {
    max-width: 500px;
    width: 50%;
    padding: 0px 20px 0px 20px;
    @media (max-width: 780px) {
      width: 100%;
      float: unset;
    }
  }

  .forgot-password {
    font-size: 12px;
    a {
      color: ${colors.darker};
      text-decoration: underline;
    }
    @media (max-width: 330px) {
      margin-top: 10px;
    }
  }

  .container-errors {
    margin-top: 15px;
    padding-left: 6%;
  }

  .container-input {
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    gap: 15px;

    @media (max-width: 500px) {
      flex-direction: column;
    }

    img {
      width: 50px;
      height: 50px;
      position: absolute;
      left: -2px;
      top: -5px;
    }

    input {
      padding-left: 60px;
      font-size: 15px;
      color: ${colors.dark};
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
    }

    .input-date {
      background: ${colors.gray200};
      border-radius: 8px;
      height: 40px;
      border: none;
      width: 100%;
      cursor: text;

      @media (max-width: 500px) {
        width: 100%;
      }
    }
  }

  .input-wrapper {
    position: relative;
    width: 100%;
  }

  .form-icon {
    background-color: ${colors.primary};
    padding: 5px;
    min-width: 44px;
    min-height: 44px;
    border: 2px solid white;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      max-width: 20px;
      max-height: 20px;
    }

    box-shadow: 0 0 0 2px ${colors.primary};

    border-radius: 50%;
    color: white;
    position: absolute;
    left: 1px;
    top: -3px;
  }

  .container-button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    gap: 18px;

    @media (max-width: 500px) {
      flex-direction: column-reverse;
    }
  }
`
