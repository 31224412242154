import React, { memo } from 'react'
import { Container } from './styles'
import CanvasDraw from 'react-canvas-draw'
import BckImg from '../../assets/images/line-sign.jpg'

export const SignCanvas = memo(React.forwardRef((props, ref) => {
  return (
    <Container style={{borderRadius:'15px', border: `4px dashed #8b8b8bac`}}>
      <CanvasDraw
        className='test'
        ref={ref}
        brushColor="#000000"
        lazyRadius={0}
        brushRadius={2}
        hideGrid={true}
        catenaryColor='#d7d7d7'
        canvasHeight={250}
        canvasWidth={500}
        imgSrc={BckImg}
        {...props}
      />
    </Container>
  )
}));

SignCanvas.displayName = 'SignCanvas';

export const saveSign = canvasRef => {
  const width = canvasRef.current.props.canvasWidth
  const height = canvasRef.current.props.canvasHeight
  const background = canvasRef.current.canvasContainer.children[3]
  const drawing = canvasRef.current.canvasContainer.children[1]
  const sign = JSON.parse(canvasRef.current.getSaveData())

  // Valida assinatura vazia
  if(Object.keys(sign["lines"]).length < 1){
    return { blob: null, dataUri: null }
  }

  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height

  // composite now
  canvas.getContext('2d').fillStyle = "#FFFFFF"
  canvas.getContext('2d').fillRect(0, 0, width, height)
  canvas.getContext('2d').drawImage(background, 0, 0)
  canvas.getContext('2d').globalAlpha = 1
  canvas.getContext('2d').drawImage(drawing, 0, 0)

  const dataUri = canvas.toDataURL('image/jpeg', 1.0)

  // converte o base64 para blob
  const data = dataUri.split(',')[1]
  const mimeType = dataUri.split(';')[0].slice(5)

  const bytes = window.atob(data)
  const buf = new ArrayBuffer(bytes.length)
  const arr = new Uint8Array(buf)

  for (let i = 0; i < bytes.length; i++) {
    arr[i] = bytes.charCodeAt(i)
  }

  const blob = new Blob([arr], { type: mimeType })
  return { blob: blob, dataUri: data }
}

export function cleanSign(canvasRef) {
  canvasRef.clear()
}
