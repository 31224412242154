import React from "react";
import { PDFContainer } from "./styles";

export default function PDFShow({ documentUrl }) {

  return (
    <PDFContainer style={{ height:'100%',display:'flex', justifyContent:'center', alignContent:'center', alignItems:'center'}}>
      <div style={{display:'flex', flexDirection:"column", alignItems:'center', alignContent:'center', justifyContent:"flex-end", width:'100%', height:'100%'}}>
        <iframe 
          title='pdf' 
          src={documentUrl} 
          style={{width:'100%', height:'100%', border:'none'}}
        >
        </iframe>
        {/* <Document
          file={documentUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          ref={PDFRef}
          loading={
            <LoadingContainer>
              <GridLoader color={`${colors.primary}`} size={30} />
            </LoadingContainer>
          }
        >
          <Page pageNumber={pageNumber} scale={PDFZoom} />
        </Document> */}
        <div style={{position:"fixed", width:'100%', marginBottom:'10rem'}}>
          {/* {PDF.isLoaded && (
              <PDFNav>
                <button onClick={minusZoom}>
                  <RiZoomOutFill />
                </button>
                <button onClick={plusZoom}>
                  <RiZoomInFill />
                </button>
                <button
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                >
                  <IoIosArrowDropleftCircle />
                </button>
                <p>
                  {pageNumber || (PDF.pages ? 1 : "--")} de {PDF.pages || "--"}
                </p>
                <button
                  type="button"
                  disabled={pageNumber >= PDF.pages}
                  onClick={nextPage}
                >
                  <IoIosArrowDroprightCircle />
                </button>
                <FaFileDownload onClick={saveFile} />
              </PDFNav>
            )} */}
          </div>
      </div>
    </PDFContainer>
  );
}
