import { Page } from 'react-pdf'
import SafeButton from '../SafeButton'
import React, { useState } from 'react'
import { FaUserAlt } from 'react-icons/fa'
import { selectPickerData } from '../Signatory'
import { Document } from 'react-pdf/dist/esm/entry.webpack'
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io'
import {
  MenuSignature,
  Container,
  InfoContainer,
  PDFContainer,
  PDFNav,
  Signatory
} from './styles'

export default function EditPDF({ documentArray, signatoriesProps, submit }) {
  const [numPages, setNumPages] = useState([])
  const [pageNumber, setPageNumber] = useState(1)

  const signatories = signatoriesProps.map(item => ({
    email: item.email,
    paper: item.type
  }))

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  return (
    <Container>
      <MenuSignature>
        <InfoContainer>
          <p>
            Confira se o documento e os signatários estão corretos e após a confirmação clique em
            &quot;Concluir&quot; para enviar o documento.
          </p>
        </InfoContainer>
        {signatories.map((signatory, index) => (
          <Signatory key={'signatario_' + index}>
            <div>
              <FaUserAlt size={20} />
            </div>
            <div>
              <strong>{signatory.email}</strong>
              <p>{selectPickerData[signatory.paper-1].label}</p>
            </div>
          </Signatory>
        ))}
        <div>
          <SafeButton 
            type="button"
            variant="primary"
            onClick={submit}
          >
            Concluir
          </SafeButton>
          <PDFNav>
            <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
              <IoIosArrowDropleftCircle />
            </button>
            <p>
              Página {pageNumber || (numPages ? 1 : '--')} de {numPages || '--'}
            </p>
            <button type="button" disabled={pageNumber >= numPages} onClick={nextPage}>
              <IoIosArrowDroprightCircle />
            </button>
          </PDFNav>
        </div>
      </MenuSignature>
      <PDFContainer>
        <Document file={documentArray[0]} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
      </PDFContainer>
    </Container>
  )
}