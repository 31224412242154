import styled from 'styled-components'
import { colors } from './../../styles/colors';

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
`
export const Container2 = styled.div`
  width: 100%;
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(240px, 1fr)); */
`

export const Test = styled.div`
  .rs-modal {
    height: 96vh;
  }
`

export const Title = styled.ul`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: ${colors.gray200};
  padding-left: 10px;
  padding-right: 10px;

  a{
    text-decoration: none;
  }

  p{
    font-size: 1.8rem;
    font-weight: 900;
    color: ${colors.primary};

    span{
    color: ${colors.darker};
    }

    @media (max-width:450px){
      font-size: 22px;
    }
  }
`

export const PlanoColuna = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  min-width: 23.5rem;
  margin: 1rem;
  padding: 0.5rem;
  color: ${colors.primary};
  border: 2px solid ${colors.primary};
  border-radius: 16px;
  transition: 0.5s;

  &:hover {
    text-decoration: none;
  }

  h2 {
    text-decoration: none;
    font-size: 2.2rem;
    text-align: center;
  }

  ul {
    margin: 2rem 0;
  }

  li {
    font-size: 1.8rem;
    padding: 0.5rem 0;
    display: flex;
    justify-content: left;
    align-items: center;
  }

  p {
    text-align: center;
    margin: 1rem 0;
    font-size: 1.8rem;
  }

  h3 {
    margin: 1.5rem 0;
    font-weight: 900;
    font-size: 3rem;
  }

  button {
    width: 130px;
    border: none;
    font-size: 2rem;
    background-color: ${colors.primary};
    height: 40px;
    color: #fff;
    margin-top: 2rem;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
        background-color: ${colors.secondary};
    }
  }

  .title{
    background-color: ${colors.primary};
    width: 105.5%;
    margin-top: -6px;
    border-bottom: 3px solid ${colors.primary};
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: #fff;
  }

  .title2{
    background-color: ${colors.primary};
    width: 105.5%;
    margin-top: -6px;
    border-bottom: 3px solid ${colors.primary};
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: #fff;
  }
`

export const ModalContainer = styled.div`
  display:flex;
  flex-direction: column;
  background-color: ${colors.gray300};
  border-radius: 10px;
  padding: 16px;
  gap: 5px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 548px;

  .buttonsContainer {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    gap: 16px;
  }
`
export const ModalContainer2 = styled.div`
  display:flex;
  flex-direction: column;
  background-color: ${colors.gray300};
  border-radius: 10px;
  padding: 16px;
  gap: 5px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;

  .buttonsContainer {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    gap: 16px;
  }
`

export const PlanoPlaceholder = styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f2f2f2;
  width: 25rem;
  height: 350px;
  margin: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.8);
`

export const ChangeModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  & > button {
    margin-top: 1.5rem;
  }
`