import styled from 'styled-components'
import { colors } from './../../styles/colors';

export const PDFContainer = styled.div`
  overflow: scroll;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1rem;
  position: relative;

  &::-webkit-scrollbar {
        width: 7px;
        position: absolute;
        border-radius: 80px;
        height: 7px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${colors.primary};
        border-radius: 80px;
      }
`

export const PDFNav = styled.div`
  height: 50px;
  padding: 1rem;
  background-color: ${colors.gray200};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 5;
  font-weight: 900;
  border-radius: 8px;
  width: 95%;
  max-width: 450px;
  
  & > button {
    background: transparent;
    height: 30px;
    border-radius: 50%;
    margin: 0 20px;
    color: ${colors.primary};
    transition: 0.5s;

    @media(max-width:425px){
    margin: 0;
  }

    &:hover {
      color: #333;
    }

    & > svg {
      font-size: 30px;
    }
  }

  & > svg {
    font-size: 30px;
    color: ${colors.primary};
    transition: 0.5s;

    &:hover {
      color: #333;
      cursor: pointer;
    }
  }
`

export const LoadingContainer = styled.div`
  height: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
