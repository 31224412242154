/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect } from 'react'
import { useAuth } from '../../hooks/useAuth'
import { Evento, Eventos, EventosContainer, Signatario, Status } from './styles'

export default function EventsComponent({ signatarios, processId, isCreatedByUser, style, styleAlt}) {

  const {getEventos, test, eventos} = useAuth()

  useEffect(() => {
    getEventos(processId)
  }, [processId])

  function getStatus(assinatura, assinaturaRecusada, idAssinatura) {
    if (assinaturaRecusada) return <Status chroma={1}>Recusado</Status>
    else if (assinatura) return <Status chroma={2}>Assinado</Status>
    else if (isCreatedByUser) {
      return (
        <Status
          chroma={3}
        >
          Pendente
        </Status>
      )
    } else {
      return <Status chroma={3}>Pendente</Status>
    }
  }

  function dateFormat(date) {
    if (date != null) {
        var splicedArray = date.split('T')[0].split('-')
        var arrDate = date.split("T");
        
        return (splicedArray[2] + "/" + splicedArray[1] + "/" + splicedArray[0] + ' ' + arrDate[1].split('.')[0])
    }
  }

  function getEventoMessage(tipoEvento) {
    const codigo = Number(tipoEvento)
    switch (codigo) {
      case 1:
        return (
          <>
            <p>Criou o processo.</p>
          </>
        )
      case 2:
        return (
          <>
            <p>E-mail enviado para este signatário.</p>
          </>
        )
      case 3:
        return (
          <>
            <p>Acessou o processo.</p>
          </>
        )
      case 4:
        return (
          <>
            <p>Assinou o documento.</p>
          </>
        )
      case 5:
        return 'Cancelou o processo.'
      case 6:
        return 'Excluiu o processo.'
      default:
        return ''
    }
  }

  return (
    <>
      <div style={style}>
        <Eventos style={styleAlt ? styleAlt : {}}>
          {signatarios?.map(signatario => {
            return (
            <Signatario key={signatario?.id} id={test.includes(signatario?.email) ? 1 : 2}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ verticalAlign: 'middle' }}>
                  {getStatus(
                    signatario?.signed,
                    signatario?.id
                    )}
                </div>
                <div style={{ verticalAlign: 'middle' }}>
                  <p title={signatario?.email}>{signatario?.email.length > 26 ? `${signatario?.email.substring(0,22)}...` : signatario?.email}</p>
                </div>
              </div>
              <EventosContainer>
                {
                  eventos?.map(evento => {
                    if(evento?.created_by === signatario?.email) {
                      return (
                        <Evento key={evento.id}>
                          <strong>{dateFormat(evento?.updated_at)}</strong>
                          {getEventoMessage(evento?.event)}
                        </Evento>
                      )
                    }
                  })
                }
              </EventosContainer>
            </Signatario>
          )})}
        </Eventos>
      </div>
    </>
  )

}
