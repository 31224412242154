export const { format: currencyFormatter } = new Intl.NumberFormat([], {
  style: 'currency',
  currency: 'BRL'
})

export const { format: dateFormatter } = new Intl.DateTimeFormat('pt-br', {
  dateStyle: 'short',
  timeStyle: 'long'
})

export const { format: dateFormatterWithoutHour } = new Intl.DateTimeFormat('pt-br', {
  dateStyle: 'short'
})

export function toMegaBytes(value) {
  Number(value)
  const formattedData = value / 1024 / 1024
  return formattedData.toFixed(2)
}

export function dateStringFormatter(value) {
  const data = value.split('-')
  data.reverse()
  return data.join('/')
}

export function dateFormat(date, mode = 1) {
  if (date != null && mode === 1) {
      let splicedArray = date.split('T')[0].split('-')
      let arrDate = date.split("T");

      return (splicedArray[2] + "/" + splicedArray[1] + "/" + splicedArray[0] + ' ' + arrDate[1].split('.')[0])
  } else {
      let splicedArray = date.split('-')

      return splicedArray[2] + "/" + splicedArray[1] + "/" + splicedArray[0]
  }
}