import styled from 'styled-components'
import { colors } from './../../styles/colors';

export const Eventos = styled.div`
  width: 100%;
  background: ${colors.white};
  padding: 1rem;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${colors.gray300};
  border-radius: 8px;
  background-color: ${colors.gray200};
  border-left: 5px solid ${colors.primary};
`

export const Signatario = styled.div`
  width: 100%;
  color: #333;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  background: ${colors.gray200};
  margin: 1rem;
  height: 4.5rem;
  overflow: hidden;
  transition: 0.5s;

  &:hover {
    cursor: pointer;
    height: ${props => {
      switch (props.id) {
        case 1 :
          return '16rem'
        case 2 :
          return '4.5rem'
        default :
          return '4.5rem'
      }
    }};
  }

  & > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem 0;
  }
`

export const Status = styled.p`
  background: ${props => {
    switch (props.chroma) {
      case 1:
        return '#c20a0a'
      case 2:
        return '#60159a'
      case 3:
        return '#ffcb0f'
      default:
        return '#333'
    }
  }};
  margin: 0;
  color: ${colors.white};
  border-radius: 8px;
  padding: 0 0.5rem;
`

export const EventosContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow-y: scroll;
  &::-webkit-scrollbar {
        width: 7px;
        position: absolute;
        border-radius: 80px;
        height: 7px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${colors.primary};
        border-radius: 80px;
      }
`

export const Evento = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-top: 1px solid ${colors.primary};
  border-bottom: 1px solid ${colors.primary};
  background: ${colors.white};
  color: #5d5d5d;
  padding: 1rem;
  margin: 1rem 0;
  transition: none;

  & > p {
    padding-left: 1rem;
    width: 68%;
  }

  & > strong {
    font-size: 1.3rem;
    width: 32%;
    text-align: right;
    font-weight: normal;
  }
`