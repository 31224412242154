import * as api from "../services/api";
import { toast } from "react-toastify";
import { cookies } from "../hooks/useCookies";
import { useNavigate } from 'react-router-dom';
import { createContext, useEffect, useState } from "react";

const AuthContext = createContext({});

export function AuthProvider({ children }) {

  const [test, setTest] = useState([])
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState()
  const [eventos, setEventos] = useState()

  const navigate = useNavigate()

  function defaultSession() {
    setUser(null);
    cookies.remove("@Safysign:user");
    cookies.remove("@Safysign:access");
    cookies.remove("@Safysign:refresh");
    navigate("../", { replace: true })

  }

  function logout() {
    defaultSession();
  }

  function Login(userData) {
    toast.loading("Aguarde...", {
      position: toast.POSITION.TOP_RIGHT,
    });
    api
      .post("/user/token/", userData, 'application/json')
      .then((response) => {
        toast.dismiss()
        setUser(response?.data)
        setLoading(false)
        if (response?.status === 200) {
          api.defaults.headers.Authorization = `Bearer ${response.data.access}`
          cookies.set("@Safysign:access", response.data.access);
          cookies.set("@Safysign:refresh", response.data.refresh);
          cookies.set("@Safysign:user", JSON.stringify(response.data));

          if (response?.data.reset_password === true) {
            navigate("../reset-password", { replace: true })
          } 
          else if(response?.data.plan === null) {
              if(response?.data.company === null) {
                navigate('/select/plan', {replace:true})
              }
              else if(response.data.company_plan === null ) {
                navigate('/select/plan', {replace:true})
              }
          }
          else if (response?.data.reset_password === false) {
            navigate("./", { replace: true })
          }
          } else if (response?.response.status === 401) {
            toast.error(response?.response.data.detail, {
              position: toast.POSITION.TOP_RIGHT,
            })
          } else {
            toast.error("Tente novamente mais tarde", {
              position: toast.POSITION.TOP_RIGHT,
            })
          }
      })

      .catch((error) => {
        toast.error("Tente novamente mais tarde", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  async function getEventos(processId) {
    api
      .get(`/process/retrieve/event/${processId}/ `)
        .then((response) => {
          let data = []
          setEventos(response?.data.detail.events)
          response?.data.detail.events.forEach((evento) => {
            if (!data.includes(evento.created_by)) {
              data.push(evento.created_by)
            }
          })
          setTest(data)
        })
  }

  useEffect(() => {
    const cookieUser = cookies.get("@Safysign:user");
    const cookieAccess = cookies.get("@Safysign:access");
    const cookieRefresh = cookies.get("@Safysign:refresh");

    if (cookieUser && cookieAccess && cookieRefresh) {
      setUser(cookieUser);
      api.defaults.headers.Authorization = `Bearer ${cookieAccess}`;
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ signed: Boolean(user), user, Login, logout, setUser, loading, setLoading, getEventos, test, eventos }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
