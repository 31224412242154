import Core from "../layouts/core";
import Plans from './../pages/Plans/index';
import Users from './../pages/Users/index';
import Dashboard from "../pages/Dashboard";
import Vaults from './../pages/Vaults/index';
import Plan from './../components/Plan/index';
import Profile from './../pages/Profile/index';
import ProcessList from "../pages/ProcessList";
import Signature from './../pages/Signature/index';
import ListClient from './../pages/ListClient/index';
import SelectPlan from './../pages/SelectPlan/index';
import CreatePlan from './../pages/CreatePlan/index';
import Certificate from './../pages/Certificate/index';
import { DashProvider } from "../contexts/dashContext";
import { Routes, Route, Navigate } from "react-router-dom";
import ScreensUser from './../components/ScreensUser/index';
import ClientScreen from './../components/ClientScreen/index';
import ResetPassword from "./../pages/Login/ResetPassword/index";

export default function SignRoutes() {
  return (
    <>
      <DashProvider>
        <Routes>
          <Route path="/" element={<Core />}>
            <Route index element={<Dashboard />} />
            <Route path="plan" element={<Plan />} />
            <Route path="users" element={<Users />} />
            <Route path="plans" element={<Plans />} />
            <Route path="vaults" element={<Vaults />} />
            <Route path='profile' element={<Profile />} />
            <Route path="vault/:id" element={<ProcessList/>} />
            <Route path="customers" element={<ListClient />} />
            <Route path="plans/create" element={<CreatePlan />} />
            <Route path="customers/create" element={<ClientScreen />} />
            <Route path='users/create' element={<ScreensUser changeFlag={false}/>} />
            <Route path="customers/edit/:id" element={<ClientScreen mode={true}/>} />
            <Route path='process/list/:status' element={<ProcessList mode={true}/>} />
            <Route path='users/edit/:id' element={<ScreensUser changeFlag={true}/>} />
            <Route path="trash" element={<ProcessList mode={false} isTrash={true} />} />
          </Route>
          <Route path="*" element={<Navigate to={'/'} />} />
          <Route path='/select/plan' element={<SelectPlan />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/certificate/:id/:hash" element={<Certificate />} />
          <Route path='/signature/:idProcess/:token' element={<Signature />} />
          <Route path="*" element={<Navigate to={'/'} />} />
        </Routes>
      </DashProvider>
    </>
  );
}