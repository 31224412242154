import styled from 'styled-components'
import { colors } from './../../styles/colors';

export const Screen = styled.div`
  width: 100%;
  height: 100%;

  & > header {
    height: 120px;
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 8px solid ${colors.primary};
    min-height: 65px;
    margin-bottom: 0;

    & > img {
      width: 300px;
      /* margin-left: 2rem; */
    }
  }
`

export const LabelContainer = styled.div`
    text-align: start;

  input {
  background: #ececec;
  border-radius: 8px;
  height: 40px;
  border: none;
  width: 100%;
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  height: calc(100vh - 120px);
   /* @media (max-width: 700px) {
    flex-wrap: wrap;
  } */

  @media screen and (width < 1023px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
    }

  & > aside {
    background: ${colors.gray300};
    color: #333;
    max-width: 380px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.8rem;

    /* @media (max-width: 700px) {
      max-width: 100%;
  } */

    & > form {
      width: 100%;
      align-items: center;

      width: 100%;
      max-width: 660px;
      margin: 0 auto;

      .buttonsContainer {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        gap: 16px;
      }

    }
  }

  aside + div > div {
    @media (min-width: 700px) {
      width: 100%;
    justify-content: center;
    display: flex;
  }
   
  }
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 15px;

  .small-container {
    width: 210px;
  }

  .half-container {
    width: 320px !important;
  }

  .terciary-container {
    width: 435px !important;
  }

`

export const InputContainer = styled.div`
  display: ${props => (props.hide ? 'none' : 'block')};
  text-align: start;
  width: 100%;
  gap: 16px;

  Input {
  background: #ececec;
  border-radius: 8px;
  height: 40px;
  padding: 0 10px;
  border: none;
  width: 100%;
  margin-top: 15px;
  }
`

export const ModalContainerFont = styled.div`
  display:flex;
  flex-direction: column;
  background-color: ${colors.gray300};
  border-radius: 10px;
  padding: 16px;
  gap: 5px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 548px;

  .buttonsContainer {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    gap: 16px;
  }
`

export const ErrorMessage = styled.p`
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
`

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding-bottom: 10px;
  background: ${props => props.status ? `${colors.primary}` : `${colors.primary}`};

  & > h2 {
    font-size: 18px;
    text-align: center;
    color: ${props => (props.status ? '#fff' : '#fff')};
  }

  strong {
      font-size: 18px;
      color: #fff;
    };
`

export const SignModal = styled.div`
  display:flex;
  flex-direction: column;
  background-color: ${colors.gray300};
  border-radius: 10px;
  padding: 16px;
  gap: 5px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 548px;
  .buttonsContainer {
      margin-top: 16px;
      display: flex;
      justify-content: center;
      gap: 16px;
  }
  
`

export const ModalContainer = styled.div`
 display:flex;
  flex-direction: column;
  background-color: ${colors.gray300};
  border-radius: 10px;
  padding: 16px;
  gap: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 348px;
  & > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`

export const ExpiredContainer = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    text-align: center;
    margin: 1rem 0;
  }
`

export const LoginModalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  background: ${colors.darker};
`

export const LoginModalTitle = styled.p`
  width: 100%;
  text-align: center;
  font-size: 2.2rem;
  color: #fff;
`

export const LoginModalText = styled.p`
  width: 100%;
  text-align: center;
  color: #fff;
`

export const LoginModalInputContainer = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > input {
    width: 100%;
    margin-bottom: 10px;
  }
`

export const LoginModalBtnContainer = styled.form`
  width: 60%;
  display: flex;
  justify-content: space-between;
`

export const KeyModalContainer = styled.div`
   display:flex;
  flex-direction: column;
  background-color: ${colors.gray300};
  border-radius: 10px;
  padding: 16px;
  gap: 5px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 348px;
`

export const KeyModalTitle = styled.p`
  width: 100%;
  text-align: center;
  font-size: 2.2rem;
`

export const KeyModalText = styled.p`
  width: 100%;
  text-align: center;
`

export const KeyModalInputContainer = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

   input {
    width: 100%;
    margin-bottom: 10px;
  }
`

export const KeyModalBtnContainer = styled.form`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  gap: 16px;
`

export const SignUpModalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 90%;
    max-width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${colors.gray300};
    border-radius: 10px;
`

export const SignUpModalText = styled.p`
  font-size: 1.8rem;
  width: 100%;
  text-align: center;
  margin: 20px 0;
`

export const SignUpDraw = styled.img`
  width: 80%;
  height: auto;
`

export const Font = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: ${colors.gray200};
  padding: 0.5rem;
  margin: 0.5rem 0;
  flex-wrap: wrap;
  height: auto;
  font-family: ${props => props.font};

  & > h4 {
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 8px solid ${colors.primary};
    height: 10vh;
    min-height: 65px;
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid ${colors.primary}
  }
`

export const Divider = styled.div`
  & > span {
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-bottom: 8px solid ${colors.primary};
    height: 10vh;
    min-height: 65px;
    margin-bottom: 0;
  }
`

export const PDFContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: -700px;
  position: relative;

  .test {
    width: 100%;
  }

  .pdfPage {
    display: flex;
    justify-content: center;

    @media(width < 633px){
      justify-content: flex-start;
    }
  }

  .react-pdf__Page {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .react-pdf__Page__annotations{
    display: none;
  }

  &::-webkit-scrollbar {
        width: 7px;
        position: absolute;
        border-radius: 80px;
        height: 7px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${colors.darker};
        border-radius: 80px;
      }
`

export const PDFNav = styled.div`
  margin-left: 10rem;
  height: 50px;
  padding: 1rem;
  background-color: ${colors.gray200};
  display: flex;
  align-items: center;
  margin-bottom: 5rem;
  justify-content: center;
  margin-top: 1rem;
  position: fixed;
  bottom: 5rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 5;
  font-weight: 900;
  border-radius: 8px;
  width: 95%;
  translate: 42%;
  max-width: 450px;

  @media screen and (width < 1023px) {
    margin-bottom: 2rem;
    position: relative;
    translate: 0;
    bottom: 0;
    max-width: 364px;
  }
  
  & > button {
    background: transparent;
    height: 30px;
    border-radius: 50%;
    margin: 0 20px;
    color: ${colors.primary};
    transition: 0.5s;

    @media screen and (width < 1023px) {
      margin: 0 12px
    }

    @media(width < 374px){
    margin: 0 8px;
  }

    &:hover {
      color: #333;
    }

    & > svg {
      font-size: 30px;
    }
  }

  & > svg {
    margin-left: 12px;
    margin-right: 12px;
    font-size: 30px;
    color: ${colors.primary};
    transition: 0.5s;

    &:hover {
      color: #333;
      cursor: pointer;
    }
  }
`

export const LoadingContainer = styled.div`
  height: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
