/* eslint-disable react-hooks/exhaustive-deps */
import { toast } from 'react-toastify';
import Modal from "@mui/material/Modal";
import { MdClose } from "react-icons/md";
import { FiCheck } from "react-icons/fi";
import * as api from "../../services/api";
import { Collapse } from "react-collapse";
import { HiPencil } from 'react-icons/hi';
import { Pagination } from "@mui/material";
import { FadeLoader } from "react-spinners";
import { dateFormat } from '../../utils/INTL';
import { colors } from './../../styles/colors';
import { useAuth } from "./../../hooks/useAuth";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate, Link } from "react-router-dom";
import SafeButton from "../../components/SafeButton";
import useWindowSize from "../../hooks/useWindowSize";
import React, { useState, useEffect, memo } from "react";
import { Container, ControlsContainer, TableContent, CardContent, CollapseContent, ModalContent } from "./styles";
import { IoMdArrowDropup } from 'react-icons/io';

const ListComponent = memo(({ users }) => {
  let header = [
    {label: 'Email', value: users?.email},
    {label: 'CPF', value: users?.cpf},
    {label: 'Nascimento', value: dateFormat(users?.age, 2)},
    {label: 'Cliente', value: users?.company_info !== null ? users?.company_info.social_reason : ''}
  ]

  return (
    header.map(item => {
      return(
        <CollapseContent key={users?.id}>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
          <div>
            <h5>{item?.label}</h5>
            <p>{item?.value}</p>
          </div>
          <HiPencil size={20} color={colors.primary} />
        </div>
      </CollapseContent>
      )
    })
  )
})

function Users() {
  const [page, setPage] = useState(1)
  const [users, setUsers] = useState([]);
  const [response, setResponse] = useState()
  const [collapse, setCollapse] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState({
    id: "",
    nome: "",
  });
  const [confirmDelModal, setConfirmDelModal] = useState(false);

  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    isAuthorized();
  }, []);

  function getUsers() {
    setIsLoading(true)
    api.get('/user/list/')
      .then((response) => {
        if (response?.status === 200) {
          setResponse(response?.data.detail.count)
          setIsLoading(false)
          setUsers(response?.data.detail.results)
        } else {
          toast.error('Ocorreu um erro ao Resgatar a listagem de usuários')
        }
      })
      .catch(() => toast.error('Ocorreu um erro ao Resgatar a listagem de usuários'))
  }

  function isAuthorized() {
    if (user.profile === '3' || user.profile === '2') {
      getUsers();
    } else {
      navigate("/unauthorized");
    }
  }

  function redirect(idUsuario) {
    navigate(`/users/edit/${idUsuario}`);
  }

  function delUserModal(user) {
    setCurrentUser({ id: user.id, name: user.get_full_name });
    setConfirmDelModal(true);
  }

  function closeConfirmDelModal() {
    setConfirmDelModal(false);
  }

  function getCurrentPage(item) {
    let yes = false
    setIsLoading(true)
    api.get(`/user/list/?page=${item}`)
      .then((response) => {
        if (response.status === 200) {
          setUsers(response.data.detail.results)
          yes = true
          if (yes === true) {
            setIsLoading(false)
          }
        }
      })
      .catch((error) => {
        toast.error(`Ocorreu um erro ao tentar requerir os processos da pagina ${item.target.innerText}, tente novamente mais tarde`)
      })
  }

  async function deleteUsuario(id) {
    api.destroy(`/user/destroy/${id}/`)
      .then((response) => {
        if (response.status === 204) {
          toast.success('Usuário deletado', { position: toast.POSITION.TOP_RIGHT })
          getCurrentPage(page)
          closeConfirmDelModal()
        } else { toast.error('Ocorreu um erro ao deletar usuário', { position: toast.POSITION.TOP_RIGHT }) }
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao deletar usuário', { position: toast.POSITION.TOP_RIGHT })
      })
  }

  function dateFormat(date) {
    if (date != null) {
      var arrDate = date.split("-");
      return arrDate[2] + "/" + arrDate[1] + "/" + arrDate[0];
    }
  }

  return (
    <>
      <ControlsContainer>
        <Link to="/users/create">
          <SafeButton variant="primary">Cadastrar Novo Usuário</SafeButton>
        </Link>
      </ControlsContainer>
      {width > 700 && (
        <>
          <Container>
            <TableContent>
              <table>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Email</th>
                    <th>CPF</th>
                    <th>Nascimento</th>
                    <th>Cliente</th>
                    <th>Saldo</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan={6}>
                        <div className="content-loader">
                          <FadeLoader color="#60159a" />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    users?.map((user, index) => (
                      <tr key={user.id} onClick={() => redirect(user?.id)}>
                        <td datanome="Nome:">{user?.get_full_name}</td>
                        <td datanome="Email:">{user?.email}</td>
                        <td datanome="CPF:">{user?.cpf}</td>
                        <td datanome="Nascimento:">
                          {dateFormat(user?.age)}
                        </td>
                        <td datanome="Cliente:">{user?.company_info !== null ? user?.company_info.social_reason : ''}</td>
                        <td datanome="Saldo:">{user?.user_balance                                                            !== null ? user?.user_balance.balance : ''}</td>
                        <td datanome="Ações:">
                          <RiDeleteBinLine
                            size={20}
                            onClick={(e) => {
                              e.stopPropagation(true);
                              delUserModal(user);
                            }}
                          />
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </TableContent>
          </Container>
          <div style={{ marginTop: '10px' }} className="pageCount">
            <Pagination count={Math.ceil(response / 10)} variant="outlined" shape="rounded" onChange={(e, value) => { getCurrentPage(value); setPage(value) }} />
          </div>
        </>
      )}

      {width < 700 && (
        <>
          {isLoading ? (
            <div className="content-loader">
              <FadeLoader color={`${colors.primary}`} />
            </div>
          ) : (
            <>
              {users.map((user) => (
                <CardContent>
                  <CollapseContent
                    onClick={() =>
                      setCollapse((collapse) =>
                        collapse === user.id ? "" : user.id
                      )
                    }
                  >
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
                      <div>
                        <h5>Nome</h5>
                        <p>{user.get_full_name}</p>
                      </div>
                      <IoMdArrowDropup size={20} color={colors.primary} style = {{transform: `rotate(${collapse === user.id ? 0 : 180}deg)` }} />
                    </div>
                  </CollapseContent>
                  <Collapse isOpened={user.id === collapse}>
                    <div onClick={() => redirect(user?.id)}>
                      <ListComponent users={user} />
                      <CollapseContent>
                        <p style={{ textAlign: "center" }}>
                          <RiDeleteBinLine
                            size={20}
                            onClick={(e) => {
                              e.stopPropagation(true);
                              delUserModal(user);
                            }}
                          />
                        </p>
                      </CollapseContent>
                    </div>
                  </Collapse>
                </CardContent>
              ))}
            </>
          )}
          <div style={{ marginTop: '10px' }} className="pageCount">
            <Pagination count={Math.ceil(response / 10)} variant="outlined" shape="rounded" onChange={(e, value) => { getCurrentPage(value); setPage(value) }} />
          </div>
        </>
      )}

      <Modal
        open={confirmDelModal}
        onClose={closeConfirmDelModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent>
          <p>
            Essa ação irá <em>deletar</em> o usuário {currentUser?.name}. <br />{" "}
            Deseja realmente fazer isso?
          </p>
          <div className="buttonsContainer">
            <SafeButton variant="secondary" onClick={closeConfirmDelModal}>
              <MdClose color="#60159a" size={20} />
            </SafeButton>
            <SafeButton variant="primary" onClick={() => deleteUsuario(currentUser.id)}>
              <FiCheck color="#fff" size={20} />
            </SafeButton>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Users;
