/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import SignatoryContext from "./context";
import * as api from "../../services/api";
import { ImBlocked } from "react-icons/im";
import { VscFilePdf } from "react-icons/vsc";
import { colors } from "../../styles/colors";
import { useDropzone } from "react-dropzone";
import { useAuth } from "../../hooks/useAuth";
import EditPDF from "../../components/EditPDF";
import { toMegaBytes } from "../../utils/INTL";
import { useDash } from "./../../hooks/useDash";
import pdfImg from "../../assets/images/drop.jpg";
import { HiOutlineEmojiSad } from "react-icons/hi";
import Signatory from "../../components/Signatory";
import React, { useState, useEffect, useRef } from "react";
import SafeButton from "../../components/SafeButton";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Toggle, SelectPicker, Notification } from "rsuite";
import { emailValidation } from "../../utils/regexValidation";
import { Container, DropArea, SignatariosArea, SignatariosList, SignatarioPlaceholder, ProcessoArea, NoDropArea, ModalContainer } from "./styles";

export default function Dashboard() {

  const [fileName, setFileName] = useState();
  const [orderly, setOrderly] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingLine, setLoadingLine] = useState({
    percent: 0,
    oppacity: 0,
    status: "active",
  });
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [showModal, setShowModal] = useState(false);
  const [listVaultEnd, setListVaultEnd] = useState([]);
  const [mailSignatory, setMailSignatory] = useState("");
  const [listVaultFirst, setListVaultFirst] = useState([]);
  const [signatoriesSelect, setSignatoriesSelect] = useState([]);
  const [stepNotification, setStepNotification] = useState(null);
  const [maxNotifications, setMaxNotifications] = useState(null);
  const [test, setTest] = useState(false)
  const [waitingListVault, setWaitingListVault] = useState([
    { label: "Carregando...", value: null },
  ]);

  const {
    setPage,
    isDropped,
    setIsDropped,
    files,
    setFiles,
    click,
    setClick,
    signatories,
    setSignatories,
    processName,
    setProcessName,
    vault,
    setVault,
    getProcessCount,
    allProccess
  } = useDash()
  const { user } = useAuth()
  const { getRootProps, getInputProps, isDragReject, isDragActive, open } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
    maxSize: user.max_file_size * 1024,
    multiple: false,
    noClick: click,
    noKeyboard: click,
    onDropAccepted: (acceptedFiles) => {
      setFileName(acceptedFiles[0].name)
      setFiles(acceptedFiles)
      setIsDropped(true)
      setClick(true)
    },
    onDropRejected: () => {
      Notification.warning({
        title: "Tamanho Excedido:",
        description: `Seu plano permite apenas arquivos de no máximo ${
          user.max_file_size / 1024
        } MB.`,
      })
    },
  })

  async function getVaults() {
    await api
      .get(`/vault/list/unique/`)
      .then((response) => {
        let obj = response.data.detail
        let teste = Object.values(obj)
        setListVaultFirst(teste)
      })
      .catch((error) => {})
  }

  useEffect(() => {
    if (isDropped === true) {
      navigator.geolocation.getCurrentPosition((position) => {
        let lat = position.coords.latitude
        let long = position.coords.longitude
        setLatitude(lat)
        setLongitude(long)
      })
    }
  }, [isDropped])

  function getSignatories(){
    api.get('/signatories/list/')
      .then((response) => {
        let data = []
        response.data.detail.forEach((item) => {
          data = [...data, {label:item.email, value:item.email}]
        })
        setSignatoriesSelect(data)
      })
  }

  useEffect(() => {
    getProcessCount()
    getSignatories()
    getVaults()
  }, [])

  useEffect(() => {
    let teste = []
    listVaultFirst.map((item) => {
      teste = [...teste, { label: item.name, value: item.id }]
    })
    setListVaultEnd(teste)
  }, [listVaultFirst])

  function selectPaper(index, paper) {
    const newList = signatories;
    const newSignatory = { email: newList[index].email, type: paper };
    newList.splice(index, 1, newSignatory);
    setSignatories(newList);
  }

  function addSignatory() {
    let alreadyAdded = false;

    if (!emailValidation(mailSignatory)) {
      Notification.warning({
        title: "Email Inválido",
        description: "Por favor utilize apenas emails válidos.",
      });
      return;
    }

    for (let i = 0; i < signatories.length; i++) {
      if (signatories[i].email === mailSignatory) {
        Notification.warning({
          title: "Cuidado",
          description: "Você já adicionou este signatário!",
        });
        alreadyAdded = true;
        return;
      }
    }

    if (!alreadyAdded) {
      setSignatories([...signatories, { email: mailSignatory, type: 1 }]);
      setMailSignatory("")
    }
  }

  function moveSignatory(from, to) {
    const newList = [...signatories];
    const dragged = newList[from];

    newList.splice(from, 1);
    newList.splice(to, 0, dragged);
    setSignatories(newList);
  }

  function removeSignatory(position) {
    const newList = [...signatories];
    newList.splice(position, 1);

    setSignatories(newList);
  }

  async function submit() {
    setShowModal(false);
    setLoading(true);

    const form = new FormData();
    form.append("vault", vault);
    form.append("name", processName);
    form.append("latitude", latitude);
    form.append("longitude", longitude);
    form.append("orderly", JSON.stringify(orderly));
    form.append("signatories", JSON.stringify(  signatories));
    form.append("fileName", fileName)
    form.append("documents", files[0]);

    await api
      .post(
        "/process/create/",
        form,
        {
          content_type:
            "multipart/form-data; boundary=---011000010111000001101001",
        },
        {
          onUploadProgress: (progressEvent) => {
            const percent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setLoadingLine({ percent, opacity: 1, status: "active" });
          },
        }
      )
      .then((response) => {
        if (response.status === 201) {
          toast.success(
            "Processo criado, logo os signatários receberam um e-mail",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          setIsDropped(false);
          setProcessName(undefined);
          setSignatories([]);
          setFiles();
          setFileName(undefined);
          setLoading(false);
          setClick(false);
          getSignatories()
          getProcessCount()
        } else {
          toast.error(
            "Ocorreu um erro ao tentar criar o processo, verifique os campos e tente novamente mais tarde",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      })
      .catch((error) => {
        setLoadingLine({
          percent: loadingLine.percent,
          oppacity: 1,
          status: "fail",
        });
        Notification.error({
          title: "Ops...",
          description: "Ocorreu um erro de conexão durante o envio do arquivo.",
        });
      });
  }

  function editDoc() {
    if (!processName || processName.length === 0) {
      Notification.warning({ title: "O processo precisa de um nome." });
      return;
    }

    if (!signatories || signatories.length === 0) {
      Notification.warning({ title: "Pelo menos 1 signátario é necessário." });
      return;
    }

    if (!vault) {
      Notification.warning({ title: "Selecione o cofre." });
      return;
    }

    setShowModal(true);
  }

  const Teste = <></>

  return (
    <>
      <Container>
        {!allProccess.detail?.balance || allProccess.detail?.balance === 0 ? (
          <div
            // to="/plan"
            style={{ width: "100%", textDecoration: "none" }}
          >
            <NoDropArea>
              <HiOutlineEmojiSad />
              <p>
                Você está sem saldo e por isso não pode iniciar novos processos,
                entre em contato com o suporte para adquirir mais saldo.
              </p>
            </NoDropArea>
          </div>
        ) : (
          <DropArea
            {...getRootProps({ isDragActive, isDragReject, isDropped })}
          >
            <input {...getInputProps({})} />
            {isDragActive ? (
              isDragReject ? (
                <>
                  <ImBlocked size={50} />
                  <p>Apenas arquivos PDF são permitidos.</p>
                </>
              ) : (
                <>
                  <IoIosCheckmarkCircle size={50} />
                  <p>Arquivo permitido, pode soltar</p>
                </>
              )
            ) : isDropped ? (
              <>
                {setPage(true)}
                <ProcessoArea id="first">
                  <SelectPicker
                    preventOverflow
                    style={{ width: 300 }}
                    placeholder="Selecione o Cofre"
                    placement="bottomStart"
                    onSelect={(value) => setVault(value)}
                    data={listVaultEnd ? listVaultEnd : waitingListVault}
                    onClean={() => setVault(null)}
                  />
                  <input
                    placeholder="Insira um nome para o processo"
                    onChange={(event) => setProcessName(event.target.value)}
                    value={processName}
                  />
                </ProcessoArea>
                <SignatoryContext.Provider
                  value={{ signatories, moveSignatory, removeSignatory }}
                >
                  <SignatariosArea id="second">
                    <div className="label">
                      <strong>Signatários:</strong>
                    </div>
                    <div>
                      <div style={{width:'100%', display:"inline-grid"}} onClick={() => {setTest(true)}}>
                      <SelectPicker
                        preventOverflow
                        className="selectSig"
                        placeholder={
                          mailSignatory.length > 0
                            ? mailSignatory
                            : "Signatários"
                        }
                        placement="bottomStart"
                        data={signatoriesSelect}
                        onEnter={
                          () => {
                            document.querySelector('.rs-picker-search-bar-input')
                              .setAttribute('placeholder', 'Adicionar Signatário')
                        }}
                        onOpen={() => setMailSignatory("")}
                        onSearch={(value) => {
                          if (value.length > 0) setMailSignatory(value);
                        }}
                        onSelect={(value) => {
                          if (value.length > 0) setMailSignatory(value);
                        }}
                        onClose={() => {
                          if(!mailSignatory.includes('@') || !mailSignatory.includes('.')){
                            setMailSignatory('')
                          }
                        }}
                        onClean={() => setMailSignatory("")}
                        defaultValue={mailSignatory}
                        value={mailSignatory}
                        toggleAs={<Teste/>}
                      />
                      </div>
                      <SafeButton variant="primary" onClick={addSignatory}>
                        Adicionar
                      </SafeButton>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          gap: 20,
                          flex: 0,
                        }}
                      >
                        {
                          <>
                            <div>
                              <strong
                                style={{ color: `${colors.primary}` }}
                                htmlFor="ordenado"
                              >
                                Ordenado:{" "}
                              </strong>
                            </div>
                            <Toggle
                              id="ordenado"
                              unCheckedChildren="Não"
                              checkedChildren="Sim"
                              defaultChecked={orderly}
                              onChange={(checked) => setOrderly(checked)}
                            />
                          </>
                        }
                      </div>
                    </div>
                    <SignatariosList>
                      <SignatarioPlaceholder>
                        Insira pelo menos um signatário.
                      </SignatarioPlaceholder>

                      {signatories.map((signatory, index) => (
                        <Signatory
                          key={"signatory_" + index}
                          position={index}
                          selectPaper={selectPaper}
                        >
                          {signatory.email}
                        </Signatory>
                      ))}
                    </SignatariosList>
                    {/* <div className="label">
                      <strong>Notificações:</strong>
                    </div>
                    <ProcessoArea id="first">
                      <SelectPicker
                        placeholder="Intervalo nova notificação"
                        placement="bottomStart"
                        data={step_notifications_mock}
                        onSelect={(value) => setStepNotification(value)}
                        onClean={() => setStepNotification(null)}
                      />
                      <SelectPicker
                        placeholder="Máximo de notificações"
                        placement="bottomStart"
                        data={max_notifications_mock}
                        onSelect={(value) => setMaxNotifications(value)}
                        onClean={() => setMaxNotifications(null)}
                      />
                    </ProcessoArea> */}
                    {files.map((file, index) => (
                      <div className="docs" key={file.path}>
                        <VscFilePdf size={30} />
                        <input
                          type="text"
                          value={fileName || ""}
                          onChange={(event) => setFileName(event.target.value)}
                        />
                        <p>{toMegaBytes(file.size)} MB</p>
                      </div>
                    ))}
                    <div
                      style={{
                        paddingTop: "21px",
                        display: "flex",
                        justifyContent: "center",
                        gap: "15px",
                      }}
                    >
                      <SafeButton variant="secondary" onClick={open}>
                        Alterar o documento
                      </SafeButton>
                      <SafeButton
                        variant="primary"
                        loading={loading || false}
                        onClick={editDoc}
                      >
                        Prosseguir
                      </SafeButton>
                    </div>
                  </SignatariosArea>
                </SignatoryContext.Provider>
              </>
            ) : (
              <>
                <img style={{ width: "65px" }} src={pdfImg} alt="pdf icon" />
                <p>Adicione um documento para iniciar um processo.</p>
              </>
            )}
          </DropArea>
        )}
      </Container>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContainer>
          <EditPDF
            documentArray={files}
            signatoriesProps={signatories}
            submit={submit}
          />
        </ModalContainer>
      </Modal>
      </>
  );
}
