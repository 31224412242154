import styled from 'styled-components'
import { colors } from '../../styles/colors'

export const Header = styled.header`
  width: 100%;
  background: ${colors.white};
  border-bottom: 10px solid ${colors.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  margin: 0;

  .columLogo {
    width: 35%;
    height: 80px;
    display: flex;
    align-items: center;

    img {
      width: 300px;
      margin-left: 6%;
      margin-top: 3%;
    }
  }

  .columLogoMobile {
    width: 40%;
    height: 80px;
    display: flex;
    align-items: center;

    img {
      width: 300px;
    }
  }

  .colum {
    width: 65%;
    display: flex;
    align-items: center;
    height: 80px;
    justify-content: end;
    padding-right: 25px;

    p {
      margin-right: 40px;
      color: ${colors.dark};
    }

    svg {
      font-size: 3rem;
      color: ${colors.primary};
    }

    &:hover {
      cursor: pointer;
    }
  }

  .columMobile {
    width: 50%;
    display: flex;
    align-items: center;
    height: 80px;
    justify-content: end;

    p {
      margin-right: 15px;
      color: ${colors.dark};

      @media (max-width: 400px) {
        font-size: 12px;
      }
    }

    svg {
      font-size: 3rem;
      color: ${colors.primary};
      margin-right: 10px;
    }
  }
`

export const DrawerHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${colors.white};
  background: ${colors.secondary};
  padding: 1rem;

  & > h3 {
    font-size: 2.4rem;
  }

  & > h4 {
    text-decoration: underline;
    font-size: 1.9rem;
  }
`

export const DrawerBody = styled.div`
  width: 100%;
  margin: 1rem 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 4rem;
  color: ${colors.red};

  & > h3 {
    width: 100%;
    border-bottom: 1px solid ${colors.red};
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  & > a {
    padding-right: 1rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    border-right: 5px solid ${colors.red};
    transition: 0.5s;
    text-decoration: none;
  }

  & > a:hover {
    border-right: 80px solid ${colors.red};
    font-size: 2.5rem;
  }
`

export const AdministrativoContainer = styled.div`
  width: 100%;
  margin: 1rem 0;
  display: ${props => (props.show ? 'flex' : 'none')};
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  color: ${colors.red};

  & > h3 {
    width: 100%;
    border-bottom: 1px solid ${colors.red};
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  & > a {
    padding-right: 1rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    border-right: 5px solid ${colors.red};
    transition: 0.5s;
    text-decoration: none;
  }

  & > a:hover {
    border-right: 80px solid ${colors.red};
    font-size: 2.5rem;
  }
`

export const SideMenu = styled.div`
  position: absolute;
  background-color: ${colors.gray300};
  top: 0;
  right: 0;
  min-width: 270px;
  height: 100%;
  border-radius: 10px;
  transform: translate(-50% -50%);

  .container {
    width: 100%;
    height: 100vh;
    margin: auto;

    .logout {
      display: flex;
      width: 92%;
      height: 50px;
      align-items: center;
      background-color: ${colors.gray300};
      margin-top: 12rem;
      border-radius: 8px;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
      font-size: 1.8rem;
      font-weight: 900;
      color: ${colors.darker};

      @media screen and (height > 719px < 800px) {
          margin-top: 14rem;
        }

      @media screen and (height > 800px < 850px) {
          margin-top: 6rem 
        }
        
      @media screen and (height > 850px) {
        margin-top: 6rem;
      }

      svg {
        font-size: 4rem;
        color: ${colors.primary};
        padding-left: 10px;
      }
    }
  }
`

export const MyAccount = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  min-width: 270px;
  height: 350px;
  border-radius: 10px;
  transform: translate(-50% -50%);

  .container {
    width: 100%;
    height: 400px;
    background-color: ${colors.gray300};
    border-radius: 0 0 10px 10px;
  }

  .header {
    height: 90px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 90px;
    }
  }

  .user {
    height: 60px;
    width: 100%;
    display: block;
    text-align: center;
    color: ${colors.primary};
  }

  .options {
    width: 100%;
    margin-top: 10px;

    a {
      text-decoration: none;
    }

    .buttons {
      display: flex;
      width: 90%;
      height: 50px;
      align-items: center;
      background-color: ${colors.gray200};
      margin-bottom: 6px;
      border-radius: 8px;
      margin-left: auto;
      margin-right: auto;
      /* cursor: pointer; */

      svg {
        font-size: 3rem;
        color: ${colors.primary};
        padding-left: 10px;
      }

      p {
        font-size: 1.8rem;
        font-weight: 900;
        color: ${colors.darker};
      }

      &:hover {
        background-color: ${colors.white};
      }
    }
  }
`