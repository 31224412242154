import AppRoutes from './routes'
import { DndProvider } from 'react-dnd'
import GlobalStyles from './styles/global'
import { BrowserRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.min.css'
import { AuthProvider } from './contexts/authContext'
import { HTML5Backend } from 'react-dnd-html5-backend'

function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <BrowserRouter>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </BrowserRouter>
      <GlobalStyles />
    </DndProvider>
  )
}

export default App