import styled from 'styled-components'
import { colors } from '../../styles/colors'

export const Container = styled.div`
  display:flex;
  background-color: ${colors.gray300};
  margin-top: 15px!important;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  transform: translate(-50% -50%);
  padding-left: 10px;
  padding-right: 10px;
  gap: 5px;

  .buttonsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    gap: 15px;
  }

  .typeUser{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }

  #plano_ilimitado_usuarios, #plano_ilimitado_documentos{
    /* display: none; */
  }

  input[type="radio"], input[type="radio"] + label  {
    display: none;
    width: 100%;
    text-align: center;
  }
`

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

`
export const Title = styled.ul`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: ${colors.gray200};
  padding-left: 10px;
  padding-right: 10px;

  a{
    text-decoration: none;
  }

  p{
    font-size: 1.8rem;
    font-weight: 900;
    color: ${colors.primary};

    span{
    color: ${colors.darker};
    }

    @media (max-width:450px){
      font-size: 18px;
    }
  }
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
  margin-top: 15px;

  .small-container {
    width: 210px;
  }

  .half-container {
    width: 320px !important;
  }

  .terciary-container {
    width: 435px !important;
  }
`

export const InputContainer = styled.div`
  display: ${props => (props.hide ? 'none' : 'block')};
  text-align: start;
  width: 660px;

  &:not(:first-child){
    width: 210px;
  }

  Input {
  background: #ececec;
  border-radius: 8px;
  height: 40px;
  padding: 0 10px;
  border: none;
  width: 100%;
  }
`
