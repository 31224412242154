import styled from 'styled-components'
import {colors} from '../../styles/colors'

export const Container = styled.div`
  border: 1px solid ${colors.gray300};
  border-radius: 8px;
  background-color: ${colors.gray200};
  width: 100%;
  overflow-x: auto;

  &::-webkit-scrollbar {
        width: 7px;
        position: absolute;
        border-radius: 80px;
        height: 7px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${colors.gray300};
        border-radius: 80px;
      }

  @media print {
    margin-top: -64px;
    position: absolute;
    z-index: 10;
    border: 0px;
  }
`

export const CardContent = styled.div`
  @media(max-width: 700px){
    border: 1px solid ${colors.gray300};
    border-radius: 8px;
    background-color: ${colors.gray200};
    width: 100%;
    overflow-x: auto;
    padding: 0 16px;
    border-left: 5px solid ${colors.primary};

    & + & {
      margin-top: 16px
    }
  }
`

export const CollapseContent = styled.div`
  &:not(:last-child), &:not(:first-child){
    border-bottom: 1px solid ${colors.gray300}; ;
  }
  padding: 16px 0;
  
  word-break: break-all;

  
  h5 {
    color: ${colors.primary};
    font-weight: 500;
  }
`

export const TableContent = styled.div`

  @media print {
    overflow-x: hidden;
  }

  &::-webkit-scrollbar {
    width: 10px;
    position: absolute;
    border-radius: 80px;
    height: 4px;
    @media print {
      border-radius: 0px !important;
      height: 0px !important;
      width: 0px !important;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.gray300};
    border-radius: 80px;

    @media print {
      display: none;
      border-radius: none;
      height: 0;
      width: 0;
    }
  }

  &::-webkit-scrollbar-track-piece {
    height: 4px;
    @media print {
      height: 0;
    }
  }

  &.spacing {
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  table {
    caption-side: top;
    width: 100%;
    min-width: 1029px;

    @media (max-width: 953px){

    }

    .table-header {
      display: flex;
      align-items: center;
    }

    .align-right {
      text-align: right;
    }

    .center {
      text-align: center;
    }

    caption {
      color: #101840;

      h3 {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 16px;
      }

      p {
        font-size: 12px;
        line-height: 14px;
      }
    }

    thead {
      background-color: ${colors.primary};
      -webkit-border-top-left-radius: 8px;
      -webkit-border-top-right-radius: 8px;
      -moz-border-radius-topleft: 8px;
      -moz-border-radius-topright: 8px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    tbody {
      overflow-y: auto;
      max-height: max-content;
      display: block;
      background-color: ${colors.gray200};
      td {
          /* border-right: solid 1px ${colors.gray300}; */
          text-align: center;
          cursor:pointer;
        }

      &::-webkit-scrollbar {
        width: 7px;
        position: absolute;
        border-radius: 80px;
        height: 4px;
        @media print {
          border-radius: 0px !important;
          height: 0px !important;
          width: 0px !important;
        }
      }

      &::-webkit-scrollbar-thumb {
        background: ${colors.gray300};
        border-radius: 80px;

        @media print {
          display: none;
          border-radius: none;
          height: 0;
          width: 0;
        }
      }

      &::-webkit-scrollbar-track-piece {
        height: 4px;
        @media print {
          height: 0;
        }
      }

      &.spacing {
        &::-webkit-scrollbar {
          display: none;
        }
        &::-webkit-scrollbar-thumb {
          display: none;
        }
      }
    }

    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    th {
      display: ${(props) => props.hasNoBorder && 'none'};
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
      padding: 26px 18px !important;
    }

    td {
      border-top: ${(props) => !props.hasNoBorder && `1px solid ${colors.gray300}`};
      padding: 16px 24px !important;
      text-align: left;
      font-size: 12px;
      color: ${colors.darker};
      

      button[type='button'] {
        background-color: transparent;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: flex-start;
        padding: 0px !important;
        border: none;

        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #474d66;
      }

      h5 {
        font-size: 16px;
        font-weight: 600;
        color: #181c32;
        margin-bottom: 0;
      }
    }
  }

  .content-loader {
    display: flex;
    justify-content: center;
  } 
`;


export const DelModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  & > button {
    margin-top: 1.5rem;
  }
`

export const ControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 50px;
  gap: 10px;
  margin-bottom: 10px;

  a{
    text-decoration: none;
  }

  @media(max-width: 600px){
    justify-content: center;
  }
`

export const ModalContent = styled.div`
  display:flex;
  flex-direction: column;
  background-color: ${colors.gray300};
  border-radius: 10px;
  padding: 16px;
  gap: 5px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 400px;

  p {
    text-align: center;
  }

  .buttonsContainer {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    gap: 16px;
  }
`