import SignRoutes from './SignRoutes'
import { useAuth } from '../hooks/useAuth'
import DefaultRoutes from './DefaultRoutes'

export default function AppRoutes() {
  const { signed } = useAuth()

  return signed
    ? <DefaultRoutes />
    : <SignRoutes />
}