import styled from 'styled-components'
import { colors } from '../../styles/colors'

export const Container = styled.div`
    /* background-color: var(--gray300); */
    margin: auto;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 10px;
    transform: translate(-50% -50%);
    padding: 16px;
    gap: 5px;

    h4 {
      font-weight: 600;
      color: ${colors.primary}
    }

    .container-content {
      display: flex;
      gap: 16px;
      width: 100%;
      align-items: center;

      strong {
        font-size: 16px;
      }

      & > div {
      flex: 1;
    }

    @media (max-width: 800px){
      flex-wrap: wrap;
    }

    }

    .button {
      width: 100%;
      margin-top: 16px;
      text-decoration: none;
    }
   
`

export const PlanoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  min-width: 23.5rem;
  margin: 1rem;
  color: ${colors.primary};
  box-shadow: 0 0 0 2px ${colors.primary};
  border-radius: 16px;

  h2 {
    text-decoration: none;
    font-size: 3rem;
    text-align: center;
  }

  ul {
    margin: 2rem 0;
  }

  li {
    font-size: 1.8rem;
    padding: 0.5rem 0;
    display: flex;
    justify-content: left;
    align-items: center;
  }

  p {
    text-align: center;
    margin: 1rem 0;
    font-size: 1.8rem;
  }

  h3 {
    margin: 1.5rem 0;
    font-weight: 900;
    font-size: 3rem;
  }

  button {
    width: 130px;
    border: none;
    font-size: 2rem;
    height: 40px;
    color: #fff;
    margin-top: 2rem;
    border-radius: 8px;
  }

  .title{
    background-color: ${colors.primary};
    width: 100%;
    border-bottom: 3px solid ${colors.primary};
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: #fff;
  }
`

export const ModalContainer = styled.div`
  display:flex;
  flex-direction: column;
  background-color: ${colors.gray300};
  border-radius: 10px;
  padding: 16px;
  gap: 5px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 548px;

  .buttonsContainer {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    gap: 16px;
  }
`

export const LabelContainer = styled.div`
    text-align: start;

  input {
  background: #ececec;
  border-radius: 8px;
  height: 40px;
  border: none;
  width: 100%;
  }
`

export const SignModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  & > button {
    margin-top: 1rem;
  }
`

export const Font = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: ${colors.gray200};
  padding: 0.5rem;
  margin: 0.5rem 0;
  flex-wrap: wrap;
  height: auto;
  font-family: ${props => props.font};

  & > h4 {
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 8px solid ${colors.primary};
    height: 10vh;
    min-height: 65px;
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid ${colors.primary};
  }
`

export const Title = styled.ul`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: ${colors.gray200};
  padding-left: 10px;
  padding-right: 10px;

  a{
    text-decoration: none;
  }

  p{
    font-size: 1.8rem;
    font-weight: 900;
    color: ${colors.primary};

    span{
    color: ${colors.darker};
    }

    @media (max-width:450px){
      font-size: 18px;
    }
  }
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 15px;

  & > div:first-child {
    width: 100%;
    max-width: 258px;
  }
`