import { Modal } from "@mui/material";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import * as api from "../../services/api";
import { Collapse } from "react-collapse";
import { HiPencil } from "react-icons/hi";
import { Pagination } from "@mui/material";
import Input from "../../components/Input";
import { FadeLoader } from "react-spinners";
import { dateFormat } from "../../utils/INTL";
import { colors } from "./../../styles/colors";
import { useNavigate } from 'react-router-dom';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoMdArrowDropup } from "react-icons/io";
import SafeButton from "../../components/SafeButton";
import useWindowSize from "../../hooks/useWindowSize";
import React, { useState, useEffect, memo } from "react";
import { Container, ModalContainer, ModalTitle, ModalInputContainer, ModalTextContainer, ControlsContainer, TableContent, CollapseContent, CardContent } from "./styles";
import { ModalContent } from "../Users/styles";
import { MdClose } from "react-icons/md";
import { FiCheck } from "react-icons/fi";

const ListComponent = memo(({ cliente }) => {

  const navigate = useNavigate()

  let header = [
    { label: 'Responsável', value: cliente?.responsible === null ? "Sem Usuário" : cliente.responsible },
    { label: 'CPNJ', value: cliente?.cnpj },
    { label: 'Domínio', value: cliente?.domain },
    { label: 'Data de Criação', value: dateFormat(cliente.created_at) }
  ]

  return (
    header.map(item => {
      return (
        <CollapseContent onClick={() => navigate(`/customers/edit/${cliente?.id}`)}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              <h5>{item?.label}</h5>
              <p>{item?.value}</p>
            </div>
            <HiPencil size={20} color={colors.primary} />
          </div>
        </CollapseContent>
      )
    })
  )
})

function ListClient() {
  const [response, setResponse] = useState()
  const [APIModal, setAPIModal] = useState({
    show: false,
    razaoSocial: "",
    clienteResponsavel: "",
    dominio: "",
    cliente: "",
  });
  const [page, setPage] = useState(1)
  const [collapse, setCollapse] = useState("");
  const [APIEmail, setAPIEmail] = useState("");
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentClient, setCurrentClient] = useState(null)
  const [confirmDelModal, setConfirmDelModal] = useState(false);

  const navigate = useNavigate()
  const { width } = useWindowSize();

  useEffect(() => {
    getClientes();
  }, []);

  function getClientes() {
    setIsLoading(true);
    api
      .get("/company/list/")
      .then((response) => {
        if (response.status === 200) {
          setCustomers(response.data.detail.results);
          setResponse(response.data.detail.count)
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error(
          "Ocorreu um erro ao tentar requerir a listagem de clientes, favor tente novamente mais tarde"
        );
      });
  }

  // function openModal(index) {
  //   setAPIModal({
  //     show: true,
  //     razaoSocial: customers[index].razao_social,
  //     clienteResponsavel: customers[index].cliente_responsavel
  //       ? customers[index].cliente_responsavel
  //       : "Sem Usuário",
  //     dominio: customers[index].dominio_email,
  //     cliente: customers[index].id,
  //   });
  // }

  function getCurrentPage(item) {
    let yes = false
    setIsLoading(true)
    api.get(`/company/list/?page=${item}`)
      .then((response) => {
        if (response.status === 200) {
          setCustomers(response.data.detail.results)
          yes = true
          if (yes === true) {
            setIsLoading(false)
          }
        }
      })
      .catch((error) => {
        toast.error(`Ocorreu um erro ao tentar requerir os processos da pagina ${item.target.innerText}, tente novamente mais tarde`, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  function closeModal() {
    setAPIModal({
      show: false,
      razaoSocial: "",
      clienteResponsavel: "",
      dominio: "",
      cliente: "",
    });

    setAPIEmail("");
  }

  async function deleteClient(id) {
    api.destroy(`/company/destroy/${id}/`)
      .then((response) => {
        if (response.status === 204) {
          toast.success('cliente deletado', { position: toast.POSITION.TOP_RIGHT })
          getCurrentPage(page)
          setConfirmDelModal(false)
        } else { toast.error('Ocorreu um erro ao deletar o cliente', { position: toast.POSITION.TOP_RIGHT }) }
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao deletar o cliente', { position: toast.POSITION.TOP_RIGHT })
      })
  }

  function generateAPIEmail(event) {
    const value = event.target.value;

    const name = value.split(" ");
    setAPIEmail(
      name[0].toLowerCase() +
      "_" +
      name[name.length - 1].toLowerCase() +
      "@" +
      APIModal.dominio
    );
  }

  return (
    <>
      <ControlsContainer>
        <Link to="/customers/create">
          <SafeButton variant="primary">Cadastrar Novo Cliente</SafeButton>
        </Link>
      </ControlsContainer>
      {width > 700 && (
        <>
          <Container>
            <TableContent>
              <table>
                <thead>
                  <tr>
                    <th>Razão Social</th>
                    <th>Email do Responsável</th>
                    <th>CNPJ</th>
                    <th>Domínio</th>
                    {/* <th>Data de Cadastro</th> */}
                    <th>Saldo</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan={5} className="content-loader">
                        <div>
                          <FadeLoader color={colors.primary} />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    customers.map((cliente, index) => (
                      <tr
                        key={cliente.id}
                        onClick={() => {
                          navigate(`/customers/edit/${cliente.id}`)
                        }
                        }
                      >
                        <td
                          dataname="Razão Social:"
                        >
                          {cliente.social_reason}
                        </td>
                        <td
                          dataname="Responsável:"
                        >
                          {cliente.cliente_responsavel === null
                            ? "Sem Usuário"
                            : cliente.responsible}
                        </td>
                        <td
                          dataname="CPNJ:"
                        >
                          {cliente.cnpj}
                        </td>
                        <td
                          dataname="Domínio:"
                        >
                          {cliente.domain}
                        </td>
                        <td dataname="Saldo:">
                          {cliente?.company_balance?.balance}
                        </td>
                        <td datanome="Ações:">
                          <RiDeleteBinLine
                            size={20}
                            onClick={(e) => {
                              e.stopPropagation(true);
                              setCurrentClient(cliente)
                              setConfirmDelModal(true);
                            }}
                          />
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </TableContent>
          </Container>
          <div style={{ marginTop: '10px' }} className="pageCount">
            <Pagination count={Math.ceil(response / 10)} variant="outlined" shape="rounded" onChange={(e, value) => {getCurrentPage(value); setPage(value)}} />
          </div>
        </>
      )}

      {width < 700 && (
        <>
          {isLoading ? (
            <div className="content-loader">
              <FadeLoader color={colors.primary} />
            </div>
          ) : (
            <>
              {customers.map((cliente, index) => (
                <CardContent>
                  <CollapseContent
                    onClick={() =>
                      setCollapse((collapse) =>
                        collapse === cliente?.id ? "" : cliente?.id
                      )
                    }
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                      <div>
                        <h5>Razão Social</h5>
                        <p>{cliente?.social_reason}</p>
                      </div>
                      <IoMdArrowDropup size={20} color={colors.primary} style={{ transform: `rotate(${collapse === cliente.id ? 0 : 180}deg)` }} />
                    </div>
                  </CollapseContent>
                  <Collapse isOpened={cliente?.id === collapse}>
                    <div >
                      <ListComponent cliente={cliente} />
                      <CollapseContent>
                        <p style={{ textAlign: "center" }}>
                          <RiDeleteBinLine
                            size={20}
                            onClick={(e) => {
                              e.stopPropagation(true);
                              setCurrentClient(cliente);
                              setConfirmDelModal(true)
                            }}
                          />
                        </p>
                      </CollapseContent>
                    </div>
                  </Collapse>
                </CardContent>
              ))}
            </>
          )}
          <div style={{ marginTop: '10px' }} className="pageCount">
            <Pagination count={Math.ceil(response / 10)} variant="outlined" shape="rounded" onChange={(e, value) => {getCurrentPage(value); setPage(value)}} />
          </div>
        </>
      )}

      <Modal
        open={confirmDelModal}
        onClose={() => {
          setConfirmDelModal(false)
          setCurrentClient(null)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent>
          <p>
            Essa ação irá <em>deletar</em> o cliente {currentClient?.social_reason}. <br />{" "}
            Deseja realmente fazer isso?
          </p>
          <div className="buttonsContainer">
            <SafeButton variant="secondary" onClick={() => {
              setConfirmDelModal(false)
              setCurrentClient(null)
            }}>
              <MdClose color="#60159a" size={20} />
            </SafeButton>
            <SafeButton variant="primary" onClick={() => deleteClient(currentClient.id)}>
              <FiCheck color="#fff" size={20} />
            </SafeButton>
          </div>
        </ModalContent>
      </Modal>

      <Modal show={APIModal.show} size="xs">
        <ModalContainer>
          <ModalTitle>Criar Usuário API</ModalTitle>
          <ModalTextContainer>
            <p>Razão Social: {APIModal.razaoSocial}</p>
            <p>Responsável: {APIModal.clienteResponsavel}</p>
            <p>Domínio: {APIModal.dominio}</p>
          </ModalTextContainer>

          <Input
            name="nome"
            placeholder="Nome"
            // ref={register}
            onChange={generateAPIEmail}
          />
          <Input
            name="email"
            placeholder="Email"
            // ref={register}
            onChange={(event) => setAPIEmail(event.target.value)}
            value={APIEmail}
          />

          <ModalInputContainer>
            <Input
              name="password"
              type="password"
              placeholder="Senha"
            // ref={register}
            />
            <Input
              name="confirmPassword"
              type="password"
              placeholder="Confirme a senha"
            // ref={register}
            />
          </ModalInputContainer>

          <ModalInputContainer>
            <SafeButton variant="secondary" onClick={closeModal}>
              Cancelar
            </SafeButton>
            <SafeButton variant="primary" loading={isLoading}>
              Criar
            </SafeButton>
          </ModalInputContainer>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default ListClient;
