export function getFontByType(type) {
    switch (type) {
        case '1':
            return 'Caveat';
        // case '2':
        //     return 'Indie Flower';
        case '3':
            return 'Cedarville Cursive';
        case '4':
            return 'Fasthand';
        case '5':
            return 'Arizonia';
        case '6':
            return 'Dancing Script';
        case '7':
            return 'Great Vibes';
        case '8':
            return 'Handlee';
        default:
            return 'Cedarville Cursive';
    }
}

export const Fonts = [
    { value: '1', label: 'Caveat', name: 'Caveat' },
    // { value: '2', label: 'Indie Flower', name: 'Indie Flower' },
    { value: '3', label: 'Cedarville Cursive', name: 'Cedarville Cursive' },
    { value: '4', label: 'Fasthand', name: 'Fasthand' },
    { value: '6', label: 'Dancing Script', name: 'Dancing Script' },
    { value: '7', label: 'Great Vibes', name: 'Great Vibes' },
    { value: '8', label: 'Handlee', name: 'Handlee' }
]
