import React from 'react'
import { MdEmail, MdCancel } from 'react-icons/md'
import { AiFillEye } from 'react-icons/ai'
import { RiQuillPenFill } from 'react-icons/ri'
import { IoMdTrash } from 'react-icons/io'
import { HiDocumentAdd } from 'react-icons/hi'

export function getTipoPapel(papel) {
  const codigo = Number(papel)
  switch (codigo) {
    case 0:
      return 'carregando...'
    case 1:
      return 'Signatário'
    case 2:
      return 'Parte'
    case 3:
      return 'Contraparte'
    case 4:
      return 'Testemunha'
    case 5:
      return 'Avalista'
    case 6:
      return 'Interveniente'
    case 7:
      return 'Endossante'
    case 8:
      return 'Fiador'
    case 9:
      return 'Solidário'
    case 10:
      return 'Fiel depositário'
    default:
      return 'Indefinido'
  }
}

export function getEventIcon(eventType) {
  const code = Number(eventType)

  switch (code) {
    case 1:
      return <HiDocumentAdd />
    case 2:
      return <MdEmail />
    case 3:
      return <AiFillEye />
    case 4:
      return <RiQuillPenFill />
    case 5:
      return <MdCancel />
    case 6:
      return <IoMdTrash />
    default:
      return ''
  }
}

export function getEventMessage(eventType, createdBy, latitude, longitude, ip) {
  const code = Number(eventType)
  
  switch (code) {
    case 1:
      return (
        <>
          <p style={{wordBreak: 'break-all'}}>{createdBy} Criou o processo.</p>
        </>
      )
    case 2:
      return (
        <>
          <p style={{wordBreak: 'break-all'}}>E-mail enviado para os signatários.</p>
        </>
      )
    case 3:
      return (
        <>
          <p style={{wordBreak: 'break-all'}}>{createdBy} Acessou o processo</p>
          <p style={{wordBreak: 'break-all'}}>Latitude: {latitude !== null && latitude !== undefined && latitude !== '' && latitude !== 'undefined' ? latitude : 'Não informado'}, Longitude: {longitude !== null && longitude !== undefined && longitude !== '' && longitude !== 'undefined' ? longitude : 'Não informado'} e Ip: {ip}</p>
        </>
      )
    case 4:
      return (
        <>
          <p style={{wordBreak: 'break-all'}}>{createdBy} Assinou o documento</p>
          <p style={{wordBreak: 'break-all'}}>Latitude: {latitude !== null && latitude !== undefined && latitude !== '' && latitude !== 'undefined' ? latitude : 'Não informado'}, Longitude: {longitude !== null && longitude !== undefined && longitude !== '' && longitude !== 'undefined' ? longitude : 'Não informado'} e Ip: {ip}</p>
        </>
      )
    case 5:
      return 'Cancelou o processo.'
    case 6:
      return 'Excluiu o processo.'
    default:
      return ''
  }
}
