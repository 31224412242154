/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import './styles.css'
import { Modal } from 'rsuite'
import { toast } from 'react-toastify'
import * as api from '../../services/api'
import { useParams } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import CertificadoPDF from './CertificadoPDF'
import { colors } from './../../styles/colors'
import { getFontByType } from '../../utils/getFont'
import { getTipoPapel } from '../../utils/getMessage'
import { PDFDownloadLink } from '@react-pdf/renderer'
import useWindowSize2 from '../../hooks/useWindowSize2'
import React, { useRef, useEffect, useState } from 'react'
import Logo from '../../assets/images/SAFYSIGN-LOGO-PNG.png'
import Brasao from '../../assets/images/SELO_SAFYSIGN-PNG.png'
import { getEventIcon, getEventMessage } from './../../utils/getMessage';
import SafeButton from '../../components/SafeButton'

function Certificate() {

    const certificadoRef = useRef()
    const { id, hash } = useParams()

    const [eventsData, setEventsData] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [processData, setProcessData] = useState()
    const [certificateData, setCertificateData] = useState()
    const [modalContent, setModalContent] = useState({
        show: true,
        size: 'sm',
        content: (
            <>
                <BounceLoader size={30} color="#60159a" />
                <p>Aguarde enquanto preparamos o certificado.</p>
                <BounceLoader size={30} color="#60159a" />
            </>
        )
    })
    const [base64, setBase64] = useState([])

    const width = useWindowSize2()

    function dateFormat(date, mode = 1) {
        if (date != null && mode === 1) {
            let splicedArray = date.split('T')[0].split('-')
            let arrDate = date.split("T");

            return (splicedArray[2] + "/" + splicedArray[1] + "/" + splicedArray[0] + ' ' + arrDate[1].split('.')[0])
        } else {
            let splicedArray = date.split('-')

            return splicedArray[2] + "/" + splicedArray[1] + "/" + splicedArray[0]
        }
    }

    useEffect(() => {
        async function getCertificadoData() {
            api
                .get(`/certification/retrieve/${id}/${hash}/`)
                .then(response => {
                    if (response?.status === 200) {
                        setCertificateData(response?.data.detail.certificate)
                        setEventsData(response?.data.detail.events)
                        setProcessData(response?.data.detail.process)
                        setIsLoading(false)
                        response?.data?.detail?.certificate?.signatures?.forEach(item => {
                            return fetch(item?.signature)
                                .then((response) => response.blob())
                                .then((blob) => {
                                    const reader = new FileReader();
                                    reader.readAsDataURL(blob);

                                    reader.onload = () => {
                                        setBase64([...base64, { base64: reader.result, email: item.email_signature }])
                                    };
                                });
                        })
                        setModalContent({ ...modalContent, show: false })
                    } else {
                        toast.error('Ocorreu um erro ao gerar o certificado, tente novamente mais tarde', {
                            position: toast.POSITION.TOP_RIGHT
                        })
                    }
                })
                .catch((error) => {
                    toast.error('Ocorreu um erro ao gerar o certificado, tente novamente mais tarde', {
                        position: toast.POSITION.TOP_RIGHT
                    })
                })
        }
        getCertificadoData()
    }, [])

    function formataCPF(cpf) {

        cpf = cpf.replace(/[^\d]/g, "");

        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    }

    return (
        <div className="certificado-screen" style={{ overflowY: 'hidden' }}>
            <div className="certificado" ref={certificadoRef} style={{ width: width[0] > 1000 ? '67%' : '100%' }}>
                <header >
                    <img src={Logo} alt="SafySign" style={{ width: width[0] <= 425 ? '60%' : '40%' }} />
                    <img src={Brasao} alt="Certificado" style={{ width: '140px' }} />
                </header>
                <div className="content-container">
                    {!isLoading && (
                        <SafeButton 
                            style={{marginTop: '20px'}} 
                            onClick={() => {
                                const newWindow = window.open(processData?.documents[0]?.final_file);
                                if (newWindow) newWindow.opener = null;
                            }} 
                        >
                            Acessar Documento
                        </SafeButton>
                    )}
                    <h2 style={width[0] >= 425 ? { color: '#60159a' } : { color: '#60159a', fontSize: '26px' }} >Certificado de Conclusão</h2>
                    {!isLoading && (
                        <>
                            <div className="info-container" style={{ width: '90%' }}>
                                <div className="processo-info-container">
                                    <p>
                                        <span className="strong">
                                            Nome do Processo:{' '}
                                        </span>
                                        {processData?.name}
                                    </p>
                                    <p>
                                        <span className="strong">
                                            Identificação do Processo: {' '}
                                        </span>
                                        {processData?.id}
                                    </p>
                                    <p>
                                        <span className="strong">
                                            Quantidade de Documentos:{' '}
                                        </span>
                                        {processData?.documents?.length}
                                    </p>
                                    <p>
                                        <span className="strong">
                                            Criado por:{' '}
                                        </span>
                                        {processData?.created_by?.name} (
                                        {processData?.created_by?.email})

                                    </p>
                                    {
                                        processData?.company_info !== null
                                            ? <p>
                                                <span className="strong">Instituição:{' '}</span>
                                                {processData?.company_info === null ? '' : processData?.company_info.social_reason}
                                            </p>
                                            : ''
                                    }
                                    {
                                        processData?.company_info !== null
                                            ? <p>
                                                <span className="strong">CNPJ:{' '}</span>
                                                {processData?.company_info === null ? '' : processData?.company_info.cnpj}
                                            </p>
                                            : ''
                                    }
                                    <p>
                                        <span className="strong">
                                            Data de Criação:{' '}
                                        </span>
                                        {dateFormat(processData?.created_at)}
                                    </p>
                                    <p>
                                        <span className="strong">
                                            Data de Finalização:{' '}
                                        </span>
                                        {dateFormat(processData?.updated_at)}
                                    </p>
                                    <p>
                                        <span className="strong">
                                            Hash256:{' '}
                                        </span>
                                        {processData?.documents[0]?.hash}
                                    </p>
                                </div>
                                <div className="assinaturas-container">
                                    <h3>Assinaturas</h3>
                                    <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                                        {certificateData.signatures.map((assinaturaObj, index) => {
                                            return (
                                                <div key={index} className="assinatura-container">
                                                    <div style={{ width: '100%' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", paddingBottom: '15px', paddingTop: '15px', borderRadius: '15px', border: `3px dashed #8b8b8b40` }}>
                                                            {
                                                                assinaturaObj?.signature.constructor().toString().length !== 15
                                                                    ? <img src={assinaturaObj?.signature} style={{ width: width[0] > 359 ? '25%' : '25%', marginRight: '5%', borderBottom: `2px solid ${colors.darker}` }} alt="" />
                                                                    : <p
                                                                        style={{ fontFamily: getFontByType(assinaturaObj?.signature?.font), fontSize: width[0] >= 1024 ? `${width[0] / 40}px` : `${width[0] / 24}px`, width: width[0] > 359 ? '25%' : '25%', marginRight: '5%', borderBottom: `2px solid ${colors.darker}` }}
                                                                    >
                                                                        {assinaturaObj?.signature?.text}
                                                                    </p>
                                                            }
                                                            <div style={{ marginLeft: '5px' }}>
                                                                {
                                                                    assinaturaObj?.signature.constructor().toString().length !== 15 ?
                                                                        <div style={{ width: 'max-content' }}>
                                                                            <p style={{ marginTop: '2px' }}>
                                                                                <span style={{ fontWeight: 900 }}>
                                                                                    Tipo: {' '}
                                                                                </span>
                                                                                {
                                                                                    processData?.signatories.map((item) => {
                                                                                        if (item?.email === assinaturaObj?.email_signature) {
                                                                                            return (getTipoPapel(parseInt(item?.type)))
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </p>
                                                                            <p style={{ marginTop: '2px' }}><span style={{ fontWeight: 900 }}>CPF: </span>{assinaturaObj?.cpf}</p>
                                                                            <p style={{ marginTop: '2px' }}><span style={{ fontWeight: 900 }}>Nome: {' '}</span>{assinaturaObj?.name}</p>
                                                                        </div>
                                                                        :
                                                                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                                                            <div style={{ marginLeft: '5px' }}>
                                                                                <div style={{ width: 'max-content' }}>
                                                                                    <p style={{ marginTop: '2px' }}>
                                                                                        <span style={{ fontWeight: 900 }}>
                                                                                            Tipo: {' '}
                                                                                        </span>
                                                                                        {
                                                                                            processData?.signatories.map((item) => {
                                                                                                if (item?.email === assinaturaObj?.email_signature) {
                                                                                                    return (getTipoPapel(parseInt(item?.type)))
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    </p>
                                                                                    <p style={{ marginTop: '2px' }}><span style={{ fontWeight: 900 }}>CPF: </span>{assinaturaObj?.cpf}</p>
                                                                                    <p style={{ marginTop: '2px' }}><span style={{ fontWeight: 900 }}>Nome: {' '}</span>{assinaturaObj?.name}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="documentos-info-container">
                                    <h3>Documentos</h3>
                                    <ul>
                                        {processData.documents.map(document => (
                                            <li key={document.id}>
                                                <p>
                                                    <span className="strong">Documento:{' '}</span>
                                                    {document.name}
                                                </p>
                                                <p>
                                                    <span className="strong">Identificador: </span>{document.id}
                                                </p>
                                                <p>
                                                    <span className="strong">
                                                        Data de envio ao sistema:{' '}
                                                    </span>

                                                    {dateFormat(document.created_at)}

                                                </p>
                                                <p style={{ wordBreak: 'break-all' }}>
                                                    <span className="strong" style={{ fontSize: '14px' }}>
                                                        Hash SHA256:{' '}
                                                    </span>
                                                    {document?.hash}
                                                </p>
                                                <p style={{ wordBreak: 'break-all' }}>
                                                    <span
                                                        className="strong"
                                                        style={{ fontSize: '14px', wordBreak: 'break-all' }}
                                                    >
                                                        Hash SHA512:{' '}
                                                    </span>
                                                    {certificateData?.hash}
                                                </p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="signatarios-info-container">
                                    <h3>Signatários</h3>
                                    <ul>
                                        {processData?.signatories?.map(signatory => (
                                            <li key={signatory?.token}>
                                                <h4>
                                                    {certificateData?.signatures?.map((item) => {
                                                        if (item?.email_signature === signatory?.email) {
                                                            return item?.name
                                                        }
                                                    })} ({getTipoPapel(signatory?.type)})
                                                </h4>
                                                <p>
                                                    <span className="strong">CPF: </span>{certificateData?.signatures?.map((item) => {
                                                        if (item?.email_signature === signatory?.email) {
                                                            return formataCPF(item?.cpf)
                                                        }
                                                    })}
                                                </p>
                                                <p>
                                                    <span className="strong">Data de nascimento:{' '}</span>
                                                    {
                                                        certificateData?.signatures?.map((item) => {
                                                            if (item?.email_signature === signatory?.email) {
                                                                return dateFormat(item?.age, 2).includes('undefined/') ? dateFormat(item?.age, 2).replaceAll('undefined/', '') : dateFormat(item?.age, 2)
                                                            }
                                                        })
                                                    }

                                                </p>
                                                <p>
                                                    Assinou o documento{' '}
                                                    o documento em <span className="strong">{
                                                        certificateData?.signatures?.map((item) => {
                                                            if (item?.email_signature === signatory?.email) {
                                                                return dateFormat(item?.created_at)
                                                            }
                                                        })
                                                    }</span> com o
                                                    IP:<span className="strong">
                                                        {
                                                            certificateData?.signatures?.map((item) => {
                                                                if (item?.email_signature === signatory?.email) {
                                                                    return item?.ip
                                                                }
                                                            })
                                                        }</span> na localização Latitude: <span className="strong">{
                                                            certificateData?.signatures?.map((item) => {
                                                                if (item?.email_signature === signatory?.email) {
                                                                    return item?.latitude
                                                                }
                                                            })
                                                        }</span>{', Logitude:'}<span className="strong">
                                                        {
                                                            certificateData?.signatures?.map((item) => {
                                                                if (item?.email_signature === signatory?.email) {
                                                                    return item?.longitude
                                                                }
                                                            })
                                                        }</span>
                                                </p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="eventos-container" style={{ width: '90%' }}>
                                <h3 style={{ marginBottom: '20px' }}>Histórico</h3>
                                {eventsData.map(event => (
                                    <div className="evento" key={event.id}>
                                        <div className="evento-data-container" style={{ width: width[0] >= 768 ? '16%' : width[0] >= 346 ? '29%' : '36%', paddingLeft: width[0] >= 425 ? '10px' : '0', display: 'flex', alignItems: 'center' }}>
                                            <span className="strong" style={{ fontSize: width[0] >= 346 ? '14px' : '13px' }}>
                                                {
                                                    dateFormat(event?.created_at).split(' ')[0]
                                                }
                                            </span>
                                            <span>
                                                {
                                                    dateFormat(event?.created_at).split(' ')[1]
                                                }
                                            </span>
                                        </div>
                                        <div className="evento-icon-container" style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                            {getEventIcon(event?.event)}
                                        </div>
                                        <div style={{ width: width[0] >= 768 ? '75%' : width[0] >= 346 ? '71%' : '64%' }}>
                                            {
                                                getEventMessage(event?.event, event?.created_by, event?.latitude, event?.longitude, event?.ip)
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
                <footer>
                    <a href="http://www.safysign.com.br/">www.safysign.com.br</a>
                    <p>Todos os direitos reservados a marca SafySign.</p>
                </footer>
            </div>
            {!isLoading && base64 !== [] && (
                <PDFDownloadLink
                    style={{ position: 'relative', marginTop: '3rem' }}
                    document={
                        <CertificadoPDF certificateData={certificateData} eventData={eventsData} processData={processData} />
                    }
                    fileName={`certificado - ${processData?.documents[0]?.name}.pdf`}
                    className="download-button"
                >
                    {({ loading }) => (loading ? 'Carregando Documento...' : 'Salvar como PDF')}
                </PDFDownloadLink>
            )
            }
            <Modal show={modalContent.show} size={modalContent.size}>
                <div className="modal-container">{modalContent.content}</div>
            </Modal>
        </div >
    )
}

export default Certificate
