import Input from "../../components/Input";
import { Link, useNavigate } from "react-router-dom";
import SafeButton from "../../components/SafeButton";
import iconPassword from "../../assets/images/icons/senha.png";

export default function SignPages(props) {

  const navigate = useNavigate()

  return (
    <>
      <div className="container-input">
        <img src={props.firstIcon} alt={props.altFirstInput} />
        <Input
          type={props.firstType}
          placeholder={props.firstPlace}
          name={props.firstName}
          required
          onChange={props.handleChange}
          value={props.firstInput}
        />
      </div>
      <div className="container-input">
        <img src={iconPassword} alt={props.altSecondInput} />
        <Input
          type={props.secondType}
          placeholder={props.secondPlace}
          name={props.secondName}
          required
          onChange={props.handleChange}
          value={props.secondInput}
        />
      </div>
      {props.isLogin ? (
        <span className="forgot-password">
          Esqueceu sua senha? <Link to={props.loading ? '/' : '/forgot'}>Clique aqui</Link>
        </span>
      ) : (
        <></>
      )}
      <div className="container-button">
        {
          props.isLogin 
            ? <SafeButton
                disabled={props.disabled}
                type="button"
                variant="secondary"
                onClick={
                  () => navigate(props.link)
                }
              >
                {props.childLink}
              </SafeButton>
            : <></>
        }
        <SafeButton
          onClick={props?.onClick ? () => props?.onClick() : () => {}}
          disabled={props.disabled}
          loading={props.loading}
          variant="primary"
          type="submit"
        >
          {props.text}
        </SafeButton>
      </div>
    </>
  );
}
