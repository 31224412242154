/* eslint-disable react-hooks/exhaustive-deps */
import { FiLogOut } from 'react-icons/fi'
import { VscTrash } from 'react-icons/vsc'
import { BsSafe2Fill } from 'react-icons/bs'
import { MdAttachMoney } from 'react-icons/md'
import { FaRegBuilding } from 'react-icons/fa'
import { TiBusinessCard } from 'react-icons/ti'
import { SiGithubactions } from 'react-icons/si'
import { useAuth } from '../../../hooks/useAuth'
import React, { useState, useEffect } from 'react'
import { useDash } from './../../../hooks/useDash'
import { Link, useLocation } from 'react-router-dom'
import { Container, FixedContainer, AdminContainer } from './styles'


export default function Navigation({mobile=false}) {

  const listPathname = [
    {name:"/plans",code:1},
    {name:"/cupons",code:2},
    {name:"/customers",code:3},
    {name:"/users",code:4},
    {name:"/",code:5},
    {name:"/vaults",code:6},
    {name:"/vault",code:6},
    {name:"/process",code:7},
    {name:"/trash",code:8}
  ]

  const [isOpen,] = useState(true)
  const {pathname} = useLocation()
  const [showText,] = useState(true)
  const [showIcon,] = useState(false)
  const [linkClass, setLinkClass] = useState()
  
  const { user, logout } = useAuth()
  const location = useLocation()
  const { page, setIsDropped, setFiles, setClick, setSignatories, setProcessName, setVault, setOpen } = useDash()
  
  const logoImg = require('../../../../src/assets/images/icone.png')

  useEffect(() => {
    setLinkClass(null)
      listPathname.forEach((item) => {
        if(item.name === location.pathname){
          setLinkClass(item.code)
        }else{
          if (location.pathname.includes(item.name) && item.name !== '/') setLinkClass(item.code)
        }
      })
  }, [pathname])

  return (
    <Container>
      <FixedContainer>
        <div>
          { user.profile === '3' && (
            <AdminContainer
              id="admin-test"
              isOpen={isOpen}
              showText={showText}
              showIcon={showIcon}
              isAdmin={user.profile === '3'}
            >
              <Link
                to="/plans"
                onClick={() => {
                  setOpen(false)
                  setLinkClass(1)
                }}
              >
                <div className={linkClass === 1 ? 'clicked' : ''}>
                  <MdAttachMoney size={32} />
                  <p>&nbsp; Planos</p>
                </div>
              </Link>
              {/* <Link
                to="/dashboard/cupons"
                onClick={() => setLinkClass(2)}
              >
                <div className={linkClass === 2 ? 'clicked' : ''}>
                  <GiTicket />
                  <p>&nbsp; Cupons</p>
                </div>
              </Link> */}
              <Link
                to="/customers"
                onClick={() => {
                  setOpen(false)
                  setLinkClass(3)
                }}
              >
                <div className={linkClass === 3 ? 'clicked' : ''}>
                  <FaRegBuilding />
                  <p>&nbsp; Clientes</p>
                </div>
              </Link>
              <Link
                to="/users"
                onClick={() => {
                  setOpen(false)
                  setLinkClass(4)
                }}
              >
                <div className={linkClass === 4 ? 'clicked' : ''}>
                  <TiBusinessCard />
                  <p>&nbsp; Usuários</p>
                </div>
              </Link>
            </AdminContainer>
          )}

          <Link
            to="/"
            onClick={() => {
              setOpen(false)
              setLinkClass(5)
              if (page === true) {
                setIsDropped(false)
                setFiles(false)
                setClick(false)
                setSignatories([])
                setProcessName()
                setVault(null)
              }
            }}
          >
            <div className={linkClass === 5 ? 'clicked' : ''}>
              <img src={logoImg} alt="dashboard icon" />
              <p>&nbsp; Novo Processo</p>
            </div>
          </Link>
          <Link
            to="/vaults"
            onClick={() => {
              setOpen(false)
              setLinkClass(6)
            }}
          >
            <div className={linkClass === 6 ? 'clicked' : ''}>
              <BsSafe2Fill />
              <p>&nbsp; Cofres</p>
            </div>
          </Link>
          <Link
            to="/process/list/all"
            onClick={() => {
              setOpen(false)
              setLinkClass(7)
            }}
          >
            <div className={linkClass === 7 ? 'clicked' : ''}>
              < SiGithubactions />
              <p>&nbsp; Processos</p>
            </div>
          </Link>
          <Link
            to="/trash"
            onClick={() => {
              setOpen(false)
              setLinkClass(8)
            }}
          >
            <div className={linkClass === 8 ? 'clicked' : ''}>
              <VscTrash />
              <p>&nbsp; Lixeira</p>
            </div>
          </Link>
        </div>
        {
          mobile === true 
            ?
            <Link
              to='/'
              onClick={() => {
                logout()
              }}
            >
              <div className={linkClass === 8 ? 'clicked' : ''}>
              <FiLogOut />
                <p>&nbsp; Sair</p>
              </div>
            </Link>
            : ''
        }
      </FixedContainer>
    </Container>
  )
}
