import styled from "styled-components"
import { colors } from "../../styles/colors"

export const Container = styled.div`
  width: 100%;
  background: ${colors.gray300};
  height: 75px;
  display: flex;
  align-items: end;
  justify-content: space-evenly;
  gap: 10px;
  padding-left: 10px;
  padding-right: 10px;

  .clicked {
    background-color: ${colors.primary};
    color: ${colors.white};
  }

  @media (max-width: 970px) {
    height: auto;
    flex-wrap: wrap;
    gap: 5px;

    & > a {
      width: 100%;
      padding: 0.5rem;

      & > div#saldo-container {
        width: 100%;
        padding: 1rem;
      }
    }
  }

  & > a {
    text-decoration: none;

    & > div {
      color: ${colors.primary};
      border: 2px solid ${colors.primary};
      border-radius: 8px;
      padding: 2rem;
      display: flex;
      align-items: center;
      height: 6vh;
      background: transparent;
      transition: 0.5s;

      &:hover {
        background-color: ${colors.primary};
        color: ${colors.white};
      }

      p {
        font-size: 1.8rem;
        font-weight: 900;
      }
    }

    & > #saldo-container {
      width: 100%;
      justify-content: flex-start;
      background-color: #616161;
      color: ${colors.white};
      border: none;


      &:hover {
        background-color: ${colors.secondary};
        border: none;
        color: ${colors.white};
      }

      & > p {
        font-weight: normal;
        
        display: flex;
        justify-content: flex;
        @media screen and (max-width: 970px) {
          width: 12%;
        }
        @media screen and (max-width: 768px) {
          width: 14%;
        }
        @media screen and (max-width: 645px) {
          width: 17%;
        }
        @media screen and (max-width: 566px) {
          width: 19%;
        }
        @media screen and (max-width: 494px) {
          width: 25%;
        }
        @media screen and (max-width: 375px) {
          width: 30%;
        }
        @media screen and (max-width: 320px) {
          width: 35%;
        }
      }

      .containerButton {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 12.8rem;

        @media (max-width: 970px) {
          padding: 1rem;
        }
      }
    }
  }
`